import { useEffect } from "react";
import { Typography } from "@mui/material";
import ModalDialog from "../../../components/shared/Modal/Modal";
import { useDeleteProjectMutation } from "../../../store/services/sgl/project";
import { confirmAlert } from "react-confirm-alert";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../store/slices/dtms/loading";

const DeleteProject = (props) => {
  const {
    open = false,
    onClose = () => {},
    projectId,
    projectName,
    refreshProjects,
  } = props;
  const [deleteProject, deleteProjectResult] = useDeleteProjectMutation();
  const dispatch = useDispatch();

  const deleteProjectModalOptions = {
    title: "Delete project",
    option: {
      open,
      maxWidth: "sm",
      actionButtonName: "Yes",
      twoActionButtonRequired: true,
      actionSecondaryButtonName: "No",
    },
  };

  useEffect(() => {
    const { isLoading, isFetching, isSuccess, data, isError, error } =
      deleteProjectResult;
    if (isSuccess) {
      confirmAlert({
        title: "Deleted",
        message: "Project deleted successfully",
        buttons: [
          {
            label: "Ok",
            onClick: () => {
              refreshProjects("OPEN");
            },
          },
        ],
      });
    } else if (isError) {
      confirmAlert({
        title: "Error",
        message: "Error deleting project!",
        buttons: [
          {
            label: "Ok",
          },
        ],
      });
    }
    dispatch(setLoading(isLoading));
  }, [deleteProjectResult]);

  const onDelete = async () => {
    await deleteProject({ projectId, projectName });
    onCloseModal();
  };

  const onCloseModal = () => {
    onClose();
  };

  return (
    <ModalDialog
      {...deleteProjectModalOptions}
      onSave={onDelete}
      onClose={onClose}
    >
      <Typography>
        Are you sure you want to delete this project{" "}
        <strong>{projectName}</strong>?
      </Typography>
    </ModalDialog>
  );
};

export default DeleteProject;
