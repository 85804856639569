// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dtms-header.ap-header.solid {
  border-bottom: 1px solid var(--color-background-border);
  z-index: 100;
}
.dtms-header.ap-header.solid .dtms-menu-dropdown .module-menu .Appkit4-icon {
  transition: transform 0.3s;
}
.dtms-header.ap-header.solid .dtms-menu-dropdown .module-menu .Appkit4-icon.rotate {
  transform: rotate(-180deg);
}
.dtms-header.ap-header.solid .dtms-menu-dropdown .ap-option-item.active {
  pointer-events: none;
}`, "",{"version":3,"sources":["webpack://./src/components/new-header/NewHeader.scss"],"names":[],"mappings":"AAAA;EACE,uDAAA;EACA,YAAA;AACF;AAEM;EACE,0BAAA;AAAR;AACQ;EACE,0BAAA;AACV;AAGI;EACE,oBAAA;AADN","sourcesContent":[".dtms-header.ap-header.solid {\n  border-bottom: 1px solid var(--color-background-border);\n  z-index: 100;\n  .dtms-menu-dropdown {\n    .module-menu {\n      .Appkit4-icon {\n        transition: transform 0.3s;\n        &.rotate {\n          transform: rotate(-180deg);\n        }\n      }\n    }\n    .ap-option-item.active {\n      pointer-events: none;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
