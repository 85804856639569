import { List, ListItem, Search } from "@appkit4/react-components";
import { useEffect, useMemo, useRef, useState } from "react";
import "./SuggestionTextBox.scss";

export const SuggestionTextBox = ({
  searchValue,
  onSearch,
  onSearchValueChange,
  dataList,
  valueField,
  validationOnNoResult,
  errorTextOnNoResult,
  showError,
}) => {
  const [focused, setFocused] = useState(false);
  const containerRef = useRef(null);
  const inputRef = useRef(null);
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setFocused(false);
        document.removeEventListener("mousedown", handleClickOutside);
      }
    }
    if (focused) document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [focused]);
  const filteredList = useMemo(
    () =>
      dataList.filter((value) =>
        value[valueField]
          ?.toLocaleLowerCase()
          ?.includes(searchValue?.toLocaleLowerCase())
      ),
    [dataList, searchValue, valueField]
  );
  const isError =
    (filteredList.length === 0 && !validationOnNoResult?.(searchValue)) ||
    showError;
  return (
    <div ref={containerRef} className="suggestion-box-container">
      <Search
        ref={inputRef}
        className={`suggetion-box-input ${isError ? "error-box" : ""} ${
          searchValue ? "show-enter-icon" : ""
        }`}
        hideTitleOnInput
        placeholder="Add User"
        searchValue={searchValue}
        onSearch={(value) => {
          if (!isError) {
            setFocused(false);
            onSearch(value);
          }
        }}
        onFocus={() => setFocused(true)}
        onChange={onSearchValueChange}
        onClear={() => onSearchValueChange("")}
      />
      {isError && (
        <div className="ap-field-email-validation-error">
          {errorTextOnNoResult}
        </div>
      )}
      {focused && filteredList.length > 0 && (
        <List
          className="user-dropdown-list"
          style={{
            position: "absolute",
            width: "100%",
            maxHeight: "10rem",
            overflow: "auto",
            zIndex: 1,
          }}
          bordered
          data={filteredList}
          renderItem={(user) => (
            <ListItem
              key={user[valueField]}
              className="user-list-item"
              onClick={() => {
                onSearchValueChange(user[valueField]);
                setFocused(false);
                inputRef.current?.focus();
              }}
            >
              {user[valueField]}
            </ListItem>
          )}
        />
      )}
    </div>
  );
};
