// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.questions-list-section-questions-container {
  padding-top: var(--spacing-5);
}
.questions-list-section-questions-container .sub-lever-question .sub-lever-question-text {
  font-weight: var(--font-weight-2);
  display: flex;
  gap: var(--spacing-3);
}
.questions-list-section-questions-container .sub-lever-question .sub-lever-question-options {
  padding: var(--spacing-5);
}
.questions-list-section-questions-container .sub-lever-question .sub-lever-question-textarea {
  padding: 0 var(--spacing-5) var(--spacing-7) var(--spacing-5);
}`, "",{"version":3,"sources":["webpack://./src/modules/dma/assessment/components/question/Question.scss"],"names":[],"mappings":"AAAA;EACE,6BAAA;AACF;AACI;EACE,iCAAA;EACA,aAAA;EACA,qBAAA;AACN;AACI;EACE,yBAAA;AACN;AACI;EACE,6DAAA;AACN","sourcesContent":[".questions-list-section-questions-container {\n  padding-top: var(--spacing-5);\n  .sub-lever-question {\n    .sub-lever-question-text {\n      font-weight: var(--font-weight-2);\n      display: flex;\n      gap: var(--spacing-3);\n    }\n    .sub-lever-question-options {\n      padding: var(--spacing-5);\n    }\n    .sub-lever-question-textarea {\n      padding: 0 var(--spacing-5) var(--spacing-7) var(--spacing-5);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
