import * as React from "react";
import { useDispatch } from "react-redux";
import { Viewer, Worker, SpecialZoomLevel } from "@react-pdf-viewer/core";
import { zoomPlugin } from "@react-pdf-viewer/zoom";
import "@react-pdf-viewer/zoom/lib/styles/index.css";
import "@react-pdf-viewer/highlight/lib/styles/index.css";
import "@react-pdf-viewer/toolbar/lib/styles/index.css";
import { IconButton, Tooltip } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import useOpenLinksPlugin from "./useOpenLinksPlugin";
import { setLoading } from "../../../../store/slices/dtms/loading";

const PDFViewerWHyperlink = ({
  fileUrl,
  fileName,
  onClickHyperlink,
  scale = null,
  onCloseClick = null,
  zoomToScale = null,
  changeZoom = null,
}) => {
  const dispatch = useDispatch();
  const openLinksPluginInstance = useOpenLinksPlugin(onClickHyperlink);
  const zoomPluginInstance = zoomPlugin();
  const { ZoomInButton, ZoomOutButton, ZoomPopover, zoomTo } =
    zoomPluginInstance;

  React.useEffect(() => {
    if (zoomToScale && typeof zoomToScale == "number") {
      zoomTo(zoomToScale);
    } else if (zoomToScale && typeof zoomToScale == "string") {
      zoomTo(SpecialZoomLevel[zoomToScale]);
    }
  }, [zoomToScale]);

  React.useEffect(() => {
    zoomTo(SpecialZoomLevel.PageWidth);
  }, [changeZoom]);

  const onDocumentLoad = (e) => {
    dispatch(setLoading(false));
  };

  const renderError = (error) => {
    let message = "";
    switch (error.name) {
      case "InvalidPDFException":
        message = "The document is invalid or corrupted";
        break;
      case "MissingPDFException":
        message = "The document is missing";
        break;
      case "UnexpectedResponseException":
        message = "Unexpected server response";
        break;
      case "FormatError":
        message = "Format not correct";
        break;
      case "UnknownErrorException":
        message = "Unexpected server response";
        break;
      default:
        message = "Cannot load the document";
        break;
    }
    return (
      <div
        style={{
          alignItems: "center",
          display: "flex",
          height: "100%",
          justifyContent: "center",
        }}
      >
        <div className="pdf-error-message">{message}</div>
      </div>
    );
  };
  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js">
      <div
        className="rpv-core__viewer"
        style={{
          border: "1px solid rgba(0, 0, 0, 0.3)",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          position: "relative",
        }}
      >
        <div
          style={{
            alignItems: "center",
            backgroundColor: "#eeeeee",
            display: "flex",
            justifyContent: "space-between",
            position: "sticky",
            top: 0,
            zIndex: 1,
            opacity: 0.9,
            padding: "0px 8px",
          }}
        >
          <div
            style={{
              width: "35%",
              fontSize: "12px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {fileName}
          </div>
          <div
            style={{ display: "flex", width: "30%", justifyContent: "center" }}
          >
            <div style={{ padding: "0px 2px" }}>
              <ZoomOutButton />
            </div>
            <div style={{ padding: "0px 2px" }}>
              <ZoomPopover />
            </div>
            <div style={{ padding: "0px 2px" }}>
              <ZoomInButton />
            </div>
          </div>
          <div
            style={{
              width: "35%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            {onCloseClick && (
              <Tooltip title="Close" placement="bottom" arrow>
                <IconButton aria-label="close" onClick={onCloseClick}>
                  <CancelIcon />
                </IconButton>
              </Tooltip>
            )}
          </div>
        </div>
        <div
          style={{
            flex: 1,
            overflow: "hidden",
          }}
        >
          {fileUrl && (
            <Viewer
              fileUrl={fileUrl}
              renderError={renderError}
              renderLoader={() => {
                dispatch(setLoading(true));
              }}
              plugins={[openLinksPluginInstance, zoomPluginInstance]}
              onDocumentLoad={onDocumentLoad}
              defaultScale={scale ?? SpecialZoomLevel.PageWidth}
            />
          )}
        </div>
      </div>
    </Worker>
  );
};

export default PDFViewerWHyperlink;
