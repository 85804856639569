import { Search } from "@appkit4/react-components";
import { useEffect, useMemo, useState } from "react";
import { NewProjectCards } from "../../../components/new-projectcards/NewProjectCards";
import "./CompletedAssessment.scss";
import NoProjectImg from "../../../assets/images/no-project.svg";
//import { completedAssessmentResponse } from "../mockdata/mockdata";
import moment from "moment";
import { NewNoProject } from "../../../components/new-noproject/NewNoProject";
import { useGetDmaCmptdAsmtMutation } from "../../../store/services/dmt/assessment";
import { setLoading } from "../../../store/slices/dtms/loading";
import { useDispatch } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import { useNavigate } from "react-router-dom";

const getProjectCardDetails = (assessment) => ({
  "Industry Sector": assessment.sector,
  "Completed At": moment(assessment.updatedDate).format("Do MMM YYYY LTS"),
});

export const CompletedAssessment = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [getDMACmptdAsmt, getDMACmptdAsmtResult] = useGetDmaCmptdAsmtMutation();
  const [searchValue, setSearchValue] = useState("");
  const [completedAssessmentResponse, setCompletedAssessmentResponse] =
    useState([]);

  useEffect(() => {
    getDMACmptdAsmt();
  }, []);

  useEffect(() => {
    const { data, isSuccess, error, isError, isLoading } =
      getDMACmptdAsmtResult;
    dispatch(setLoading(isLoading));
    if (isSuccess) {
      setCompletedAssessmentResponse(data);
    }
    if (isError) {
      console.log("Oops! ", error);
      dispatch(setLoading(isLoading));
      confirmAlert({
        title: "Error",
        message: "Unable to fetch module roles details",
        buttons: [
          {
            label: "Ok",
          },
        ],
      });
    }
  }, [dispatch, getDMACmptdAsmtResult]);

  const filteredAssessments = useMemo(() => {
    return completedAssessmentResponse.filter((assessment) =>
      assessment.tempName
        ?.toLocaleLowerCase()
        ?.includes(searchValue?.toLocaleLowerCase())
    ).sort((a, b) => (a.updatedDate > b.updatedDate) ? -1 : 1);
  }, [searchValue, completedAssessmentResponse]);

  const showAssessment = (asmtId) => {
    navigate(`/dma/completedassessment/${asmtId}`);
  };

  return (
    <div className="dma-ca-section">
      <div className="dma-ca-search-container">
        <Search
          searchType="secondary"
          onChange={setSearchValue}
          value={searchValue}
        />
      </div>
      <div className="dma-ca-content">
       {getDMACmptdAsmtResult.isSuccess && <> {filteredAssessments.length ? (
          filteredAssessments.map((assessment) => (
            <NewProjectCards
              key={assessment.id}
              otherDetails={assessment.id}
              title={assessment.tempName}
              details={getProjectCardDetails(assessment)}
              onCardClick={showAssessment}
            />
          ))
        ) : (
          <NewNoProject
            imageUrl={NoProjectImg}
            showCreateButton={false}
            title={"Sorry, no results found."}
            className="dma-ca-noresults"
          />
        )}</>}
      </div>
    </div>
  );
};
