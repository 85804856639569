import { Breadcrumb, BreadcrumbItem } from "@appkit4/react-components";
import React from "react";
import { Link } from "react-router-dom";
import "./NewBreadcrumb.scss";
export const NewBreadcrumb = ({ breadcrumbs = [] }) => {
  return (
    <Breadcrumb className="dtms-breadcrumb">
      {breadcrumbs.map((breadcrumb) => (
        <BreadcrumbItem
          className="dtms-breadcrumb-item"
          key={`${breadcrumb.name}${breadcrumb.prefix}${breadcrumb.path}`}
        >
          {breadcrumb.path ? (
            <Link
              to={
                parseInt(breadcrumb.path) < 0
                  ? window.location.pathname
                      .split("/")
                      .slice(0, parseInt(breadcrumb.path))
                      .join("/")
                  : breadcrumb.path
              }
              className="dtms-breadcrumb-link"
            >
              {breadcrumb.prefix && (
                <span
                  className={`Appkit4-icon icon-${breadcrumb.prefix}-outline`}
                ></span>
              )}
              {breadcrumb.name}
            </Link>
          ) : (
            breadcrumb.name
          )}
        </BreadcrumbItem>
      ))}
    </Breadcrumb>
  );
};
