import { createSlice } from "@reduxjs/toolkit";

const sglMasterSlice = createSlice({
  name: "sglMaster",
  initialState: { sglProjectRoles: [], sglModuleRoles: [] },
  reducers: {
    setSglProjectRoles(state, action) {
      state.sglProjectRoles = action.payload;
    },
    setSglModuleRoles(state, action) {
      state.sglModuleRoles = action.payload;
    },
  },
});

export const { setSglModuleRoles, setSglProjectRoles } = sglMasterSlice.actions;
export default sglMasterSlice.reducer;
