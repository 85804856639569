// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dtms-project-cards-container {
  width: 32%;
}
.dtms-project-cards-container.card-clickable {
  cursor: pointer;
}
.dtms-project-cards-container .ap-panel:hover,
.dtms-project-cards-container .ap-panel:focus,
.dtms-project-cards-container .ap-panel:active {
  box-shadow: var(--elevation-shadow-high);
}
.dtms-project-cards-container .ap-panel .panel-title-container {
  padding: var(--spacing-6) var(--spacing-6) 0 0;
}
.dtms-project-cards-container .ap-panel .panel-title-container .panel-title-text {
  font-size: var(--spacing-6);
  font-weight: var(--font-weight-2);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dtms-project-cards-container .ap-panel .ap-panel-head {
  padding-left: 0;
}
.dtms-project-cards-container .ap-panel .ap-panel-head .ap-panel-head-title-text .title-text {
  text-overflow: ellipsis;
}
.dtms-project-cards-container .ap-panel .project-card-field-row:first-child {
  margin-top: 0;
}
.dtms-project-cards-container .ap-panel .project-card-field-row {
  margin-top: var(--spacing-5);
}
.dtms-project-cards-container .ap-panel .project-card-field-row .project-card-field-text {
  font-weight: var(--font-weight-3);
  font-size: var(--spacing-5);
}
.dtms-project-cards-container .ap-panel .project-card-field-row .project-card-value-text {
  font-weight: var(--font-weight-1);
  font-size: var(--spacing-5);
}

@media screen and (min-width: 1400px) {
  .dtms-project-cards-container {
    width: 23.5%;
  }
}
@media screen and (max-width: 1000px) {
  .dtms-project-cards-container {
    width: 49%;
  }
}
@media screen and (max-width: 700px) {
  .dtms-project-cards-container {
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/new-projectcards/NewProjectCards.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;AACF;AAAE;EACE,eAAA;AAEJ;AAAE;;;EAGE,wCAAA;AAEJ;AAEI;EACE,8CAAA;AAAN;AACM;EACE,2BAAA;EACA,iCAAA;EACA,mBAAA;EACA,gBAAA;EACA,uBAAA;AACR;AAEI;EACE,eAAA;AAAN;AAEQ;EACE,uBAAA;AAAV;AAII;EACE,aAAA;AAFN;AAII;EACE,4BAAA;AAFN;AAGM;EACE,iCAAA;EACA,2BAAA;AADR;AAGM;EACE,iCAAA;EACA,2BAAA;AADR;;AAOA;EACE;IACE,YAAA;EAJF;AACF;AAOA;EACE;IACE,UAAA;EALF;AACF;AAQA;EACE;IACE,WAAA;EANF;AACF","sourcesContent":[".dtms-project-cards-container {\n  width: 32%;\n  &.card-clickable {\n    cursor: pointer;\n  }\n  .ap-panel:hover,\n  .ap-panel:focus,\n  .ap-panel:active {\n    box-shadow: var(--elevation-shadow-high);\n  }\n\n  .ap-panel {\n    .panel-title-container {\n      padding: var(--spacing-6) var(--spacing-6) 0 0;\n      .panel-title-text {\n        font-size: var(--spacing-6);\n        font-weight: var(--font-weight-2);\n        white-space: nowrap;\n        overflow: hidden;\n        text-overflow: ellipsis;\n      }\n    }\n    .ap-panel-head {\n      padding-left: 0;\n      .ap-panel-head-title-text {\n        .title-text {\n          text-overflow: ellipsis;\n        }\n      }\n    }\n    .project-card-field-row:first-child {\n      margin-top: 0;\n    }\n    .project-card-field-row {\n      margin-top: var(--spacing-5);\n      .project-card-field-text {\n        font-weight: var(--font-weight-3);\n        font-size: var(--spacing-5);\n      }\n      .project-card-value-text {\n        font-weight: var(--font-weight-1);\n        font-size: var(--spacing-5);\n      }\n    }\n  }\n}\n\n@media screen and (min-width: 1400px) {\n  .dtms-project-cards-container {\n    width: 23.5%;\n  }\n}\n\n@media screen and (max-width: 1000px) {\n  .dtms-project-cards-container {\n    width: 49%;\n  }\n}\n\n@media screen and (max-width: 700px) {\n  .dtms-project-cards-container {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
