import { useState, useEffect, forwardRef, useMemo } from "react";
import { Modal } from "@appkit4/react-components/modal";
import { Button } from "@appkit4/react-components/button";
import { Input } from "@appkit4/react-components/field";
import { Table, Column } from "@appkit4/react-components/table";
import { Select } from "@appkit4/react-components";
import "./SglDashboard.scss";
import _ from "lodash";
import { _getClientID, _getLoggedInUserDetails } from "../../../envUrl";
import { useGetAllSglUsersMutation } from "../../../store/services/sgl/users";
import { confirmAlert } from "react-confirm-alert";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../store/slices/dtms/loading";
import { formatUserListForUpdate } from "../sglUtils";
import AddEditUserModal from "./AddEditUserModal";
import {
  useAddProjectMutation,
  useUpdateProjectMutation,
} from "../../../store/services/sgl/project";
import { useSelector } from "react-redux";
import { moduleNames } from "../../../utils/constants";
import { SuggestionTextBox } from "../../../components/shared/SuggestionTextBox/SuggestionTextBox";

const AddProject = ({
  open = false,
  handleClose = () => {},
  refreshProjects,
  isEdit,
  projectDetails = null,
}) => {
  const dispatch = useDispatch();
  const loggedInUserDetails = _getLoggedInUserDetails();
  const [formFields, setFormFields] = useState({
    projectName: "",
    sharepointHomeUrl: "",
    folderPath: "",
    searchUser: "",
  });
  const [users, setUsers] = useState([]);
  const [selectedUsersList, setSelectedUsersList] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [table, setTable] = useState();
  const [addEditUserModal, setAddEditUserModal] = useState({
    open: false,
    userDetails: null,
  });
  const [isChange, setIsChange] = useState(false);

  const [getAllUsers, getAllUsersResult] = useGetAllSglUsersMutation();
  const [createProject, createProjectResult] = useAddProjectMutation();
  const [updateProject, updateProjectResult] = useUpdateProjectMutation();

  const sglUsers = useSelector((state) => state.sglUsers.sglUsers);

  useEffect(() => {
    getAllUsers();
  }, []);

  useEffect(() => {
    if (isEdit && projectDetails) {
      setFormFields({
        projectName: projectDetails.projectName,
        sharepointHomeUrl: projectDetails.sharepointHomeUrl,
        folderPath: projectDetails.folderPath,
        searchUser: "",
      });
      const clonedRes = _.cloneDeep(projectDetails.inviteUserRequestList);
      setSelectedUsersList(clonedRes);
    }
  }, [projectDetails, isEdit]);

  useEffect(() => {
    if (open && !isEdit) {
      const userDts = _.cloneDeep(_getLoggedInUserDetails());
      setSelectedUsersList([
        {
          ...userDts,
          modules: [
            {
              name: "Stage Gate Letter",
              role: { name: "ADMIN", isActive: "Y" },
            },
          ],
          isCreator: true,
          action: "add",
        },
      ]);
    }
  }, [open]);

  useEffect(() => {
    const { isLoading, isFetching, isError, isSuccess, data, error } =
      getAllUsersResult;
    if (isSuccess) {
      const clonedRes = _.cloneDeep(data.result);
      setUsers(clonedRes);
    } else if (isError) {
      console.log("Oops!", error);
    }
  }, [getAllUsersResult]);

  useEffect(() => {
    const { isLoading, isError, isSuccess, data, error } = createProjectResult;

    if (isSuccess) {
      confirmAlert({
        title: "Project Created",
        message: `${formFields.projectName} is created successfully`,
        overlayClassName: "custom-overlay-modal",
        buttons: [
          {
            label: "Ok",
            onClick: () => {
              handleCloseModal();
              refreshProjects("OPEN");
            },
          },
        ],
      });
    } else if (isError) {
      confirmAlert({
        title: "Error",
        overlayClassName: "custom-overlay-modal",
        message:
          error?.data?.error && error?.data?.error?.message
            ? error.data.error.message
            : "Unable to create project. Please try again later.",
        buttons: [
          {
            label: "Ok",
          },
        ],
      });
    }
    dispatch(setLoading(isLoading));
  }, [createProjectResult]);

  useEffect(() => {
    const { isLoading, isError, isSuccess, data, error } = updateProjectResult;

    if (isSuccess) {
      confirmAlert({
        title: "Project Updated",
        overlayClassName: "custom-overlay-modal",
        message: `${formFields.projectName} is updated successfully`,
        buttons: [
          {
            label: "Ok",
            onClick: () => {
              handleCloseModal();
              refreshProjects("OPEN");
            },
          },
        ],
      });
    } else if (isError) {
      confirmAlert({
        title: "Error",
        overlayClassName: "custom-overlay-modal",
        message:
          error?.data?.error && error?.data?.error?.message
            ? error.data.error.message
            : "Unable to update project. Please try again later.",
        buttons: [
          {
            label: "Ok",
          },
        ],
      });
    }
    dispatch(setLoading(isLoading));
  }, [updateProjectResult]);

  useEffect(() => {
    setTable({
      title: "",
      columns: [
        { title: "Email", field: "email", style: { width: "30%" } },
        {
          title: "Role",
          render: (data) => data.modules[0].role.name,
          style: { width: "20%" },
        },
      ],
      actions: [
        (rowData) => ({
          icon: forwardRef((props, ref) => (
            <span
              className="Appkit4-icon icon-pencil-fill ap-font-16"
              {...props}
              ref={ref}
            />
          )),
          tooltip: "Edit",
          disabled: rowData.isCreator,
          onClick: (event, data) => {
            setAddEditUserModal({
              ...addEditUserModal,
              open: true,
              userDetails: data,
            });
          },
          cellStyle: {
            textAlign: "center",
            justifyContent: "center",
          },
        }),
        (rowData) => ({
          icon: forwardRef((props, ref) => (
            <span
              className="Appkit4-icon icon-delete-fill ap-font-16"
              {...props}
              ref={ref}
            />
          )),
          tooltip: "Delete",
          disabled: rowData.isCreator,
          onClick: (event, data) => {
            var clonedSelectUserList = _.cloneDeep(selectedUsersList);
            clonedSelectUserList.splice(data.tableData.id, 1);
            setSelectedUsersList(clonedSelectUserList);
          },
          cellStyle: {
            textAlign: "center",
            justifyContent: "center",
          },
        }),
      ],
      options: {
        paging: false,
        toolbar: false,
        showTitle: false,
      },
    });
  }, [selectedUsersList]);

  const projectModalOptions = {
    title: isEdit ? "Edit Project" : "Create Project",
    visible: open,
    maxWidth: "sm",
    actionButtonName: isEdit ? "Save" : "Create",
  };

  const handleFormChange = (id, value) => {
    setIsChange(true);
    setFormErrors({});
    setFormFields({
      ...formFields,
      [id]: value,
    });
  };

  const validate = () => {
    let errors = {};
    let valid = true;

    if (!formFields.projectName) {
      errors.projectName = "Project name is required";
      valid = false;
    }

    if (!formFields.sharepointHomeUrl) {
      errors.sharepointHomeUrl = "Sharepoint url  is required";
      valid = false;
    } else if (formFields.sharepointHomeUrl.length > 250) {
      errors.sharepointHomeUrl =
        "Sharepoint Home Url should be less than 250 characters.";
      valid = false;
    }

    if (!formFields.folderPath) {
      errors.folderPath = "Folder path is required";
      valid = false;
    }

    setFormErrors(errors);
    return valid;
  };

  const handleFormSubmit = async () => {
    if (validate()) {
      const payload = {
        ...formFields,
        inviteUserRequestList: selectedUsersList,
      };
      if (isEdit) {
        if (!isChange) {
          confirmAlert({
            title: "No Changes",
            message: "Please update any value to save.",
            overlayClassName: "custom-overlay-modal",
            buttons: [
              {
                label: "Ok",
              },
            ],
          });
          return;
        }
        payload.projectId = projectDetails.projectId;
        payload.inviteUserRequestList = formatUserListForUpdate(
          projectDetails.inviteUserRequestList,
          selectedUsersList,
          moduleNames.sgl
        );

        updateProject(payload);
      } else {
        createProject(payload);
      }
    }
  };

  const handleCloseModal = () => {
    handleClose();
    setFormFields({
      projectName: "",
      sharepointHomeUrl: "",
      folderPath: "",
      searchUser: "",
    });
    setIsChange(false);
    setSelectedUsersList([]);
    setFormErrors({});
  };

  const addEditUserModalClose = () => {
    setAddEditUserModal({
      ...addEditUserModal,
      open: false,
      userDetails: null,
    });
  };

  const addNewUsers = (email) => {
    setIsChange(true);
    let errors = {};
    let valid = true;
    if (!formFields.searchUser) {
      errors.searchUser = "User mail is required";
      valid = false;
    } else if (
      !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
        formFields.searchUser
      )
    ) {
      errors.searchUser = "Please enter a valid email";
      valid = false;
    } else if (
      selectedUsersList?.find((value) => value.email === formFields.searchUser)
    ) {
      errors.searchUser = "This user has already been added";
      valid = false;
    }

    if (valid) {
      let firstName = "";
      let lastName = "";
      let role = "USER";
      const user = sglUsers.find((user) => user.email === email);
      if (user) {
        firstName = user.firstName;
        lastName = user.lastName;
        role =
          user.modules?.find((i) => i.name == moduleNames.sgl)?.role?.name ||
          "USER";
      }
      const newUser = {
        email,
        firstName,
        lastName,
        modules: [
          {
            name: moduleNames.sgl,
            role: { name: role, isActive: "Y" },
          },
        ],
        action: "add",
        orgId: loggedInUserDetails.orgId,
        requestingUserEmail: loggedInUserDetails.email,
        product: _getClientID(),
        isCreator: false,
      };
      const clonedSelectedUsers = _.cloneDeep(selectedUsersList);
      setSelectedUsersList([...clonedSelectedUsers, newUser]);
      setFormErrors({});
      setFormFields({
        ...formFields,
        searchUser: "",
      });
    }
    setFormErrors(errors);
  };

  const deleteUser = (row) => {
    setIsChange(true);
    const users = _.cloneDeep(selectedUsersList);
    const newUsers = users.filter((user) => user.email !== row.email);
    setSelectedUsersList(newUsers);
  };

  const editUserRole = (editedUser) => {
    const clonedSelectedUsers = _.cloneDeep(selectedUsersList);
    clonedSelectedUsers.forEach((user) => {
      if (user.email === editedUser.email) {
        user.modules[0].role.name = editedUser.modules[0].role.name;
      }
    });
    setSelectedUsersList(clonedSelectedUsers);
  };

  const renderUserActionCell = (row, field) => {
    return (
      <Button
        kind="secondary"
        add
        icon="icon-delete-fill"
        compact
        disabled={row.email == loggedInUserDetails.email}
        onClick={() => {
          deleteUser(row);
        }}
      ></Button>
    );
  };

  const renderUserRoleCell = (row, field) =>
    row?.modules.find((i) => i.name == moduleNames.sgl)?.role?.name || "";

  const filteredUserList = useMemo(
    () =>
      sglUsers.filter(
        (value) => !selectedUsersList.some((user) => user.email === value.email)
      ),
    [sglUsers, selectedUsersList]
  );

  return (
    <>
      <Modal
        visible={projectModalOptions.visible}
        title={isEdit ? "Edit Project" : "Create Project"}
        ariaLabel={"Create Project"}
        onCancel={handleCloseModal}
        maskBodyPadding={false}
        maskCloseable={false}
        modalStyle={{ width: "35rem" }}
        initialFocus={false}
        footerStyle={{
          paddingTop: "8px",
          marginTop: "-8px",
          minHeight: "60px",
        }}
        icons={""}
        footer={
          <>
            <Button onClick={handleFormSubmit}>
              {isEdit ? "Save" : "Create"}
            </Button>
          </>
        }
        bodyStyle={{ maxHeight: "68vh" }}
      >
        <div className="">
          <Input
            type={"text"}
            title={"Project Name"}
            name={"projectName"}
            className={"form-input"}
            allowClear={true}
            required={true}
            error={formErrors?.projectName?.length > 0}
            errorNode={
              <div
                id="errormessage"
                aria-live="polite"
                className="ap-field-email-validation-error"
              >
                {formErrors?.projectName || ""}
              </div>
            }
            value={formFields.projectName}
            onChange={(val) => handleFormChange("projectName", val)}
          ></Input>
          <Input
            type={"text"}
            title={"Sharepoint Home Url"}
            name={"sharepointHomeUrl"}
            className={"form-input"}
            allowClear={true}
            required={true}
            disabled={isEdit}
            error={formErrors?.sharepointHomeUrl?.length > 0}
            errorNode={
              <div
                id="errormessage"
                aria-live="polite"
                className="ap-field-email-validation-error"
              >
                {formErrors?.sharepointHomeUrl || ""}
              </div>
            }
            value={formFields.sharepointHomeUrl}
            onChange={(val) => handleFormChange("sharepointHomeUrl", val)}
          ></Input>
          <Input
            type={"text"}
            title={"Folder Path"}
            name={"folderPath"}
            className={"form-input"}
            allowClear={true}
            required={true}
            disabled={isEdit}
            error={formErrors?.folderPath?.length > 0}
            errorNode={
              <div
                id="errormessage"
                aria-live="polite"
                className="ap-field-email-validation-error"
              >
                {formErrors?.folderPath || ""}
              </div>
            }
            value={formFields.folderPath}
            onChange={(val) => handleFormChange("folderPath", val)}
          ></Input>
        </div>
        <h6 className="ap-typography-heading-s">User Management</h6>
        <div className="add-users-section">
          <SuggestionTextBox
            dataList={filteredUserList}
            onSearch={addNewUsers}
            onSearchValueChange={(val) => handleFormChange("searchUser", val)}
            searchValue={formFields.searchUser}
            valueField="email"
            errorTextOnNoResult={
              formErrors?.searchUser || "Enter a valid email"
            }
            showError={formErrors?.searchUser?.length > 0}
            validationOnNoResult={(value) =>
              /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)
            }
          />
        </div>
        <div className="add-project-userlist">
          <Table originalData={selectedUsersList} hasTitle condensed>
            <Column field="email">Added Users</Column>
            <Column field="email" renderCell={renderUserActionCell}>
              Action
            </Column>
          </Table>
        </div>
      </Modal>
      {!_.isEmpty(users) && users.length > 0 && (
        <AddEditUserModal
          open={addEditUserModal.open}
          users={users}
          closeModal={addEditUserModalClose}
          addNewUsers={addNewUsers}
          editUserRole={editUserRole}
          userDetails={addEditUserModal.userDetails}
        />
      )}
    </>
  );
};

export default AddProject;
