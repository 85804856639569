import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import "./Report.scss";
import { useState } from "react";
export const Report = ({ isCompletedAssessment }) => {
  const [report, setReport] = useState(null);
  const [sampleReportConfig, setReportConfig] = useState({
    type: "report",
    embedUrl: "https://app.powerbi.com/reportEmbed",
    tokenType: models.TokenType.Embed,
    id: "8df8f9be-380b-45d0-ae12-bcc2bb589a6a",
    accessToken: "auto",
    settings: undefined,
  });
  const [eventHandlersMap, setEventHandlersMap] = useState(
    new Map([
      ["loaded", () => console.log("Report has loaded")],
      ["rendered", () => console.log("Report has rendered")],
      [
        "error",
        (event) => {
          if (event) {
            console.error(event.detail);
          }
        },
      ],
      ["visualClicked", () => console.log("visual clicked")],
      ["pageChanged", (event) => console.log(event)],
    ])
  );
  return (
    <div className="dma-report-container">
      {/* <PowerBIEmbed
			embedConfig = { sampleReportConfig }
			eventHandlers = { eventHandlersMap }
			cssClassName = "dma-report-embed-container"
			getEmbeddedComponent = { (embedObject) => {
				console.log(`Embedded object of type "${ embedObject.embedtype }" received`);
				setReport(embedObject);
			} }
		/> */}
      <iframe
        className={`dma-report-iframe ${
          isCompletedAssessment ? "dma-report-iframe-completed" : ''
        }`}
        title="Digital_maturity_dashboard"
        src="
    https://app.powerbi.com/reportEmbed?reportId=8df8f9be-380b-45d0-ae12-bcc2bb589a6a&autoAuth=true&ctid=513294a0-3e20-41b2-a970-6d30bf1546fa"
      ></iframe>
    </div>
  );
};
