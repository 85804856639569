import React from "react";
import { Button, Panel } from "@appkit4/react-components";
import "./NewNoProject.scss";

export const NewNoProject = ({
  handleCreateClick,
  showCreateButton,
  imageUrl,
  title,
  className = "",
}) => {
  return (
    <div className={`no-project-container ${className}`}>
      {imageUrl && (
        <img src={imageUrl} alt="No Project Img" className="no-project-image" />
      )}
      <Panel
        bordered={false}
        title={title}
        footer={
          showCreateButton && (
            <Button
              kind="primary"
              onClick={handleCreateClick}
              className="create-btn"
            >
              <span className="Appkit4-icon icon-circle-plus-outline"></span>
              Create
            </Button>
          )
        }
      ></Panel>
    </div>
  );
};
