// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dma-profile-info-container .dma-profile-info-title.ap-panel-head {
  padding: 0;
  background-color: var(--color-background-default);
}
.dma-profile-info-container .dma-profile-info-title.ap-panel-head .ap-panel-head-title {
  white-space: normal;
  height: auto;
  font-size: var(--spacing-6);
}
.dma-profile-info-container .dma-profile-info-body {
  padding-top: var(--spacing-5);
}
.dma-profile-info-container .dma-profile-info-body .dma-profile-info {
  margin-top: var(--spacing-6);
}
.dma-profile-info-container .dma-profile-info-body .dma-profile-info .dma-profile-info-field {
  font-weight: var(--font-weight-3);
}
.dma-profile-info-container .dma-profile-info-body .dma-profile-info .dma-profile-info-value {
  padding-left: var(--spacing-5);
  padding-top: var(--spacing-3);
}
.dma-profile-info-container .dma-profile-info-body .dma-profile-info .dma-profile-info-value .dma-profile-type-dropdown {
  max-width: calc(var(--spacing-5) * 25);
  margin-top: var(--spacing-3);
}
.dma-profile-info-container .dma-profile-info-body .dma-profile-info:first-of-type {
  margin-top: 0;
}`, "",{"version":3,"sources":["webpack://./src/modules/dma/profile/ProfileInfo.scss"],"names":[],"mappings":"AACE;EACE,UAAA;EAMA,iDAAA;AALJ;AAAI;EACE,mBAAA;EACA,YAAA;EACA,2BAAA;AAEN;AAEE;EACE,6BAAA;AAAJ;AACI;EACE,4BAAA;AACN;AAAM;EACE,iCAAA;AAER;AAAM;EACE,8BAAA;EACA,6BAAA;AAER;AADQ;EACE,sCAAA;EACA,4BAAA;AAGV;AACI;EACE,aAAA;AACN","sourcesContent":[".dma-profile-info-container {\n  .dma-profile-info-title.ap-panel-head {\n    padding: 0;\n    .ap-panel-head-title {\n      white-space: normal;\n      height: auto;\n      font-size: var(--spacing-6);\n    }\n    background-color: var(--color-background-default);\n  }\n  .dma-profile-info-body {\n    padding-top: var(--spacing-5);\n    .dma-profile-info {\n      margin-top: var(--spacing-6);\n      .dma-profile-info-field {\n        font-weight: var(--font-weight-3);\n      }\n      .dma-profile-info-value {\n        padding-left: var(--spacing-5);\n        padding-top: var(--spacing-3);\n        .dma-profile-type-dropdown {\n          max-width: calc(var(--spacing-5) * 25);\n          margin-top: var(--spacing-3);\n        }\n      }\n    }\n    .dma-profile-info:first-of-type {\n      margin-top: 0;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
