import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import Footer from "./components/footer/Footer";
import Header from "./components/new-header/NewHeader";
import { ErrorBoundary } from "react-error-boundary";
import fallbackRender from "./components/error/Error";
import LandingPage from "./components/auth/LandingPage";
import Register from "./components/auth/Register";
import "./App.scss";
import { useEffect, useState } from "react";
import Loader from "./components/shared/Loader/Loader";
import App from "./App";
import { _getBaseUrl, _getLoginUrl } from "./envUrl";
import _ from "lodash";
import Logout from "./components/logout/Logout";
import { defaultDevUser } from "./utils/constants";
import { useDispatch } from "react-redux";
import { setLoading } from "./store/slices/dtms/loading";
import SessionActive from "./components/auth/SessionActive";
import Unauthorised from "./components/auth/Unauthorised";
import { setModuleNotification } from "./store/slices/dtms/notification";

const Auth = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [redirectTo, setRedirectTo] = useState();
  const [renderDefaultPath, setRenderDefaultPath] = useState(false);
  const [dtmsClass, setDtmsClass] = useState("");

  useEffect(() => {
    dispatch(setLoading(true));
    const code = sessionStorage.getItem("pIAM");
    if (code !== null) {
      setRenderDefaultPath(false);
    } else {
      setRenderDefaultPath(true);
    }

    if (window.location.href.includes("code=")) {
      if (window.location.href.includes("stage-gate-letter")) {
        if (!sessionStorage.getItem("spCode")) {
          dispatch(setLoading(false));
          dispatch(
            setModuleNotification({
              show: false,
            })
          );
          navigateToMbz();
        }
      } else {
        convertSuccessfulLogin();
      }
    }
    const env = ""; //process.env.REACT_APP_STAGE;
    if (env !== "local" && env !== "dev_r2") {
      if (window.location.pathname !== "/logout") {
        let loggedIn = checkAuthenticationWithPIAM();
        if (loggedIn) {
          setRedirectTo(<App />);
        } else {
          setRedirectTo(null);
          dispatch(setLoading(false));
        }
      } else {
        setRedirectTo(null);
        navigate("/logout");
      }
    } else {
      sessionStorage.setItem("pIAM", "Dev-r2");
      // window.location.href = "/home";
      let userDts = defaultDevUser;
      sessionStorage.setItem("UserDts", JSON.stringify(userDts));
      if (
        window.location.pathname !== "/" &&
        window.location.pathname !== "/register" &&
        window.location.pathname !== "/logout"
      ) {
        setRedirectTo(<App />);
      }
    }
  }, []);

  useEffect(() => {
    if (location.key === "default") {
      setDtmsClass("dtms-main-full");
    } else {
      setDtmsClass("dtms-main");
    }
  }, []);

  const checkAuthenticationWithPIAM = () => {
    dispatch(setLoading(true));
    if (window.location.href.includes("code=")) {
      convertSuccessfulLogin();
      return false;
    } else {
      const productIAM = sessionStorage.getItem("pIAM");
      if (productIAM === undefined || productIAM === null) {
        //performOpenAMLogin();
        return false;
      }
      sessionStorage.setItem("initCall", true);
    }
    return true;
  };

  const performProductIAMLogin = () => {
    const env = ""; //process.env.REACT_APP_STAGE;
    if (env !== "local" && env !== "dev_r2") {
      dispatch(setLoading(true));
      if (window.location.pathname !== "/") {
        //console.log("initial path---");
        localStorage.setItem("initialPath", window.location.pathname);
      }
      const url = _getLoginUrl();

      window.location.href = url;
    } else {
      console.log("Path: ", window.location.pathname);
      sessionStorage.setItem("pIAM", "Dev-r2");
      // window.location.href = "/home";
      if (window.location.pathname !== "/") {
        //console.log("initial path---");
        localStorage.setItem("initialPath", window.location.pathname);
      }
      let userDts = defaultDevUser;
      sessionStorage.setItem("UserDts", JSON.stringify(userDts));

      setRedirectTo(<App />);
    }
  };

  const convertSuccessfulLogin = () => {
    dispatch(setLoading(true));
    const code = window.location.href
      .split("?")[1]
      .split("&")[0]
      .split("code=")[1];
    sessionStorage.setItem("pIAM", code);
    sessionStorage.setItem("initCall", false);
    var base_url = window.location.href.split("?")[0];
    var initialPath = localStorage.getItem("initialPath");
    // console.log("path value: ", initialPath);
    if (_.isEmpty(initialPath)) {
      initialPath = "";
    }
    setRedirectTo(<App />);
    navigate("/");

    // window.location.href = base_url.substring(0, base_url) + initialPath;
    // localStorage.removeItem("initialPath");
  };

  const navigateToMbz = () => {
    setRedirectTo(<App />);
    dispatch(setLoading(true));

    const code = window.location.href
      .split("?")[1]
      .split("&")[0]
      .split("code=")[1];
    sessionStorage.setItem("spCode", code);
    const state = window.location.href
      .split("?")[1]
      .split("&")[1]
      .split("state=")[1];

    var base_url = window.location.href.split("?")[0];

    window.location.href =
      base_url.substring(0, base_url.length) + state + "?isSPAuth=true";
  };

  return (
    <>
      <Loader />
      {redirectTo === null ? (
        <div className="app">
          {(location.pathname === "/register" ||
            location.pathname === "/logout" ||
            location.pathname === "/sessionactive" ||
            location.pathname === "/unauthorised") && (
            <Header showUserAvatar={false} hideMenu/>
          )}
          <div className={dtmsClass}>
            <Routes>
              <Route
                path=""
                element={
                  <ErrorBoundary fallbackRender={fallbackRender}>
                    <LandingPage userLogin={performProductIAMLogin} />
                  </ErrorBoundary>
                }
              />
              <Route
                path="register"
                element={
                  <ErrorBoundary fallbackRender={fallbackRender}>
                    <Register />
                  </ErrorBoundary>
                }
              />
              <Route
                path="logout"
                element={
                  <ErrorBoundary fallbackRender={fallbackRender}>
                    <Logout />
                  </ErrorBoundary>
                }
              />
              <Route
                path="sessionactive"
                element={
                  <ErrorBoundary fallbackRender={fallbackRender}>
                    <SessionActive />
                  </ErrorBoundary>
                }
              />
              <Route
                path="unauthorised"
                element={
                  <ErrorBoundary fallbackRender={fallbackRender}>
                    <Unauthorised />
                  </ErrorBoundary>
                }
              />
              {renderDefaultPath && (
                <Route path="*" element={<Navigate to="/" />} />
              )}
            </Routes>
          </div>
        </div>
      ) : (
        redirectTo
      )}
    </>
  );
};

export default Auth;
