import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import api from "../../api";
import { trackPromise } from "react-promise-tracker";
import { _getAuthHeaders, _getDTMSProxy } from "../../../envUrl";
import { confirmAlert } from "react-confirm-alert";

const proxy = _getDTMSProxy();

export const downloadSglReport = createAsyncThunk(
  "downloadSglReport",
  async (payload, { rejectWithValue }) => {
    try {
      const authHeaders = _getAuthHeaders();
      const response = await trackPromise(
        axios.post(
          proxy + api.downloadSglReport,
          {
            sharePointHomeUrl: payload.spUrl,
            relativePath: payload.path,
            uiFlow: true,
            files: [
              {
                itemId: payload.fileId,
                formats: payload.formats,
              },
            ],
          },
          {
            params: {},
            headers: {
              ...authHeaders,
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
      ).then((res) => {
        const data = res.data.result[payload.fileId];
        const base64 = payload.formats.includes("PDF")
          ? data.pdfContent
          : data.fileContent;
        const link = document.createElement("a");
        link.download = payload.name;
        link.href = payload.formats.includes("PDF")
          ? `data:application/pdf;base64,${base64}`
          : `data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,${base64}`;
        link.setAttribute("download", payload.name);
        document.body.appendChild(link);
        link.click();
      });
      return response;
    } catch (err) {
      console.log("Error: ", err);
      return rejectWithValue(err);
    }
  }
);

const sglReportSlice = createSlice({
  name: "sglReport",
  initialState: { sglReportStatus: null },
  reducers: {
    setSglReportStatus(state, action) {
      state.sglReportStatus = action.payload;
    },
    resetSglReportStatus(state, action) {
      state.sglReportStatus = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(downloadSglReport.pending, (state, action) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(downloadSglReport.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        confirmAlert({
          title: "Download",
          message: "Report downloaded successfully!",
          buttons: [
            {
              label: "Ok",
            },
          ],
        });
      })
      .addCase(downloadSglReport.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        confirmAlert({
          title: "Error",
          message: "Unable to download report. Please try again.",
          buttons: [
            {
              label: "Ok",
            },
          ],
        });
      });
  },
});

export const { setSglReportStatus, resetSglReportStatus } =
  sglReportSlice.actions;
export default sglReportSlice.reducer;
