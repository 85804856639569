import "./Notification.scss";
import { Notification } from "@appkit4/react-components";

const ModuleNotification = (props) => {
  const { message, status, onClose } = props;

  return (
    <div
      className={`dtms-notify ap-notifications ap-notifications-static-topHeader ${status}`}
    >
      <Notification
        title=""
        message={message}
        closeable={true}
        status={status}
        onClose={onClose}
        className="module-note ap-notifications-static-topHeader"
      ></Notification>
    </div>
  );
};

export default ModuleNotification;
