import { createSlice } from "@reduxjs/toolkit";
import { _getLoggedInUserDetails } from "../../../envUrl";
import _ from "lodash";

const dmaClientsSlice = createSlice({
  name: "dmaClients",
  initialState: { clients: [] },
  reducers: {
    setDmaClients(state, action) {
      state.clients = action.payload;
    },
  },
});

export const { setDmaClients } = dmaClientsSlice.actions;
export default dmaClientsSlice.reducer;
