import {
  Checkbox,
  CheckboxGroup,
  Radio,
  RadioGroup,
} from "@appkit4/react-components";
import "./QuestionOptions.scss";
import { CustomSlider } from "../custom-slider/CustomSlider";
export const QuestionOptions = ({ options, type, onSelect, currentVal, disableEdit }) => {
  switch (type) {
    case "MC":
      return (
        <RadioGroup className="question-option-group" value={currentVal}  onChange={(value) => {!disableEdit && onSelect(options.find(val => val.id === value))}}>
          {options.map((option, ind) => (
            <Radio
              className="question-option-item"
              key={ind}
              value={option.id}
            >
              {option.label}
            </Radio>
          ))}
        </RadioGroup>
      );
    case "MS":
      return (
        <CheckboxGroup
          className="question-option-group"
          name="city"
          onChange={(value) => {!disableEdit && onSelect(options.filter(val => value.includes(val.id)))}}
          value={currentVal}
        >
          {options.map((option, ind) => (
            <Checkbox
              className="question-option-item"
              key={ind}
              value={option.id}
            >
              {option.label}
            </Checkbox>
          ))}
        </CheckboxGroup>
      );
    case "SQ":
      return (
        <CustomSlider
          options={options}
          onSelect={onSelect}
          onValueChange={(value) => {!disableEdit && onSelect(value)}}
          value={currentVal}
        />
      );
    default:
      return <div className="invalid-question-type">Invalid question type</div>;
  }
};
