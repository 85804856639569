import { Navigation } from "@appkit4/react-components";
import React, { useEffect, useMemo, useState } from "react";
import "./NewNavigation.scss";
import { useLocation, useNavigate, useResolvedPath } from "react-router-dom";
import useDebouncedResize from "../shared/CustomHooks/useDebouncedResize";
const navIconMap = {
  Home: {
    prefixIcon: "home",
    prefixCollapsedIcon: "home",
  },
  DTMS: {
    prefixIcon: "home",
    prefixCollapsedIcon: "home",
  },
  TPRLA: {
    prefixIcon: "community",
    prefixCollapsedIcon: "community",
  },
  "Stage Gate Letter": {
    prefixIcon: "document-file",
    prefixCollapsedIcon: "document-file",
  },
  "Role assignment": {
    prefixIcon: "audience",
    prefixCollapsedIcon: "audience",
  },
  DMA: {
    prefixIcon: "survey",
    prefixCollapsedIcon: "survey",
  },
  assessment: {
    prefixIcon: "survey",
    prefixCollapsedIcon: "survey",
  },
  report: {
    prefixIcon: "powerbi",
    prefixCollapsedIcon: "powerbi",
  },
  completedassessment: {
    prefixIcon: "accept",
    prefixCollapsedIcon: "accept",
  },
  profile: {
    prefixIcon: "person",
    prefixCollapsedIcon: "person",
  },
};
const NewNavigation = ({ menu = [], width }) => {
  const [selectedKey, setSelectKey] = useState("-1");
  const parentRoute = useResolvedPath(".");
  const navigate = useNavigate();
  const location = useLocation();
  const windowWidth = useDebouncedResize();
  const [isNavCollapsed, setIsNavCollapsed] = useState(
    window.innerWidth < 1100
  );

  const navMenu = useMemo(
    () =>
      menu.map((value) => ({
        name: value.description,
        modulePath: value.modulePath,
        ...navIconMap[value.name],
      })),
    [menu]
  );

  useEffect(() => {
    const currentIndex = navMenu.reduce(
      (prevVal, curVal, ind) =>
        !location.pathname.startsWith(
          `${parentRoute.pathname}/${curVal.modulePath}`
        ) || prevVal !== "-1"
          ? prevVal
          : `${ind}`,
      "-1"
    );
    if (selectedKey !== currentIndex) {
      setSelectKey(currentIndex);
    }
  }, [navMenu, location, selectedKey, parentRoute]);

  useEffect(() => {
    if (windowWidth < 1100) {
      setIsNavCollapsed(true);
    } else {
      setIsNavCollapsed(false);
    }
  }, [windowWidth]);

  return (
    <>
      <Navigation
        width={width || 250}
        className="dtms-side-navigation"
        navList={navMenu}
        showTooltip={true}
        hasHeader={false}
        selectedKey={selectedKey}
        defaultOpenKeys={[]}
        onItemClick={(event, item, key) => {
          navigate(item.modulePath);
        }}
        solid={true}
        hasFooter={windowWidth > 1100 || windowWidth === 1100}
        collapsed={isNavCollapsed}
        onClickCollapseEvent={() => setIsNavCollapsed((value) => !value)}
      />
    </>
  );
};

export default NewNavigation;
