import { Button, Modal } from "@appkit4/react-components";
import React, { useCallback, useEffect } from "react";

import "./NewDeleteProjectModal.scss";
import { useDeleteProjectMutation } from "../../../store/services/sgl/project";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../store/slices/dtms/loading";
import { confirmAlert } from "react-confirm-alert";
export const NewDeleteProjectModal = ({
  isVisible,
  onClose,
  projectName,
  projectId,
  refreshProjects,
}) => {
  const dispatch = useDispatch();
  const [deleteProject, { isLoading, isError, isSuccess }] =
    useDeleteProjectMutation();

  useEffect(() => {
    dispatch(setLoading(isLoading));
  }, [dispatch, isLoading]);

  useEffect(() => {
    if (isError) {
      confirmAlert({
        title: "Error",
        message: "Error deleting project!",
        buttons: [
          {
            label: "Ok",
          },
        ],
      });
    }
  }, [isError]);

  useEffect(() => {
    if (isSuccess) {
      confirmAlert({
        title: "Deleted",
        message: "Project deleted successfully",
        buttons: [
          {
            label: "Ok",
            onClick: () => {
              refreshProjects("OPEN");
            },
          },
        ],
      });
    }
  }, [isSuccess, refreshProjects]);

  const onDelete = useCallback(() => {
    deleteProject({ projectName, projectId }).then(onClose);
  }, [deleteProject, projectName, projectId, onClose]);
  return (
    <Modal
      className="delete-project-confirm-modal"
      visible={isVisible}
      title={"Delete Project"}
      ariaLabel={"Delete Project"}
      onCancel={onClose}
      maskBodyPadding={false}
      footer={
        <>
          <Button onClick={onClose} kind="secondary">
            No
          </Button>
          <Button onClick={onDelete}>Yes</Button>
        </>
      }
    >
      <p>
        Are you sure you want to delete this project{" "}
        <strong>{projectName}</strong>?
      </p>
    </Modal>
  );
};
