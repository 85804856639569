// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tpra-app {
  padding: calc(var(--spacing-5) * 2);
  padding-top: var(--spacing-5);
  background-color: #f3f3f3;
  min-height: 78vh;
}`, "",{"version":3,"sources":["webpack://./src/modules/tprla/TprlaApp.scss"],"names":[],"mappings":"AAAA;EACI,mCAAA;EACA,6BAAA;EACA,yBAAA;EACA,gBAAA;AACJ","sourcesContent":[".tpra-app {\n    padding: calc(var(--spacing-5) * 2);\n    padding-top: var(--spacing-5);\n    background-color: #f3f3f3;\n    min-height: 78vh;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
