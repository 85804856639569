import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import {
  _getAuthHeaders,
  _getDTMSProxy,
  _getLoggedInUserDetails,
} from "../../../envUrl";
import api from "../../api";

export const sglFilesApi = createApi({
  reducerPath: "sglFilesApi",
  baseQuery: fetchBaseQuery({ baseUrl: _getDTMSProxy() }),
  endpoints: (builder) => ({
    getAllSglFiles: builder.mutation({
      query: (details) => {
        const authHeaders = _getAuthHeaders();
        const user = _getLoggedInUserDetails();
        return {
          url: api.getAllSglFiles,
          method: "POST",
          params: { expand: true },
          body: {
            sharePointHomeUrl: details.sharePointHomeUrl,
            relativePath: details.relativePath,
            uiFlow: true,
          },
          headers: { ...authHeaders, email: user.email },
        };
      },
    }),
    saveSpAuthCode: builder.mutation({
      query: (payload) => {
        const authHeaders = _getAuthHeaders();
        const user = _getLoggedInUserDetails();
        return {
          url: api.saveSpAuthCode,
          method: "POST",
          body: {
            authCode: payload.spAuthCode,
            redirectURI: payload.redirectUri,
            uiFlow: true,
          },
          headers: { ...authHeaders, email: user.email },
        };
      },
    }),
    getFileDownload: builder.mutation({
      query: (details) => {
        const authHeaders = _getAuthHeaders();
        const user = _getLoggedInUserDetails();
        return {
          url: api.getFileDownload,
          method: "POST",
          params: { email: user.email },
          body: {
            sharePointHomeUrl: details.sharePointHomeUrl,
            relativePath: details.relativePath,
            uiFlow: true,
            files: [
              {
                itemId: details.itemId,
                formats: details.formats,
              },
            ],
          },
          headers: { ...authHeaders, email: user.email },
        };
      },
    }),
    getXlsxDownload: builder.mutation({
      query: (details) => {
        const authHeaders = _getAuthHeaders();
        const user = _getLoggedInUserDetails();
        return {
          url: api.downloadSglReport,
          method: "POST",
          params: { email: user.email },
          body: {
            sharePointHomeUrl: details.sharePointHomeUrl,
            relativePath: details.relativePath,
            uiFlow: true,
            files: [
              {
                itemId: details.itemId,
                formats: details.formats,
              },
            ],
          },
          headers: { ...authHeaders, email: user.email },
        };
      },
    }),
  }),
});

export const {
  useGetAllSglFilesMutation,
  useSaveSpAuthCodeMutation,
  useGetFileDownloadMutation,
  useGetXlsxDownloadMutation,
} = sglFilesApi;
