import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { scroller } from "react-scroll";
import CancelIcon from "@mui/icons-material/Cancel";
import { IconButton, Tooltip } from "@mui/material";

const TableWrapper = styled.div`
  overflow: auto;
  height: 120vh;
`;

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
`;

const Th = styled.th`
  border: 1px solid #ddd;
  padding: 8px;
  background-color: #f2f2f2;
`;

const Td = styled.td`
  border: 1px solid #ddd;
  padding: 8px;
  background-color: ${({ isHighlighted }) =>
    isHighlighted ? "#ffeb3b" : "white"};
  transition: background-color 0.3s;
`;

const TableComponent = ({
  highlightRow,
  highlightCol,
  zoomScale,
  zoomWidth,
  data,
}) => {
  const tableRef = useRef(null);

  useEffect(() => {
    if (highlightRow !== null && highlightCol !== null) {
      scroller.scrollTo(`cell-${highlightRow}-${highlightCol}`, {
        duration: 800,
        delay: 0,
        smooth: "easeInOutQuart",
        containerId: "table-container",
      });
    }
  }, [highlightRow, highlightCol]);

  return (
    <TableWrapper
      id="table-container"
      ref={tableRef}
      style={{
        transform: `scale(${zoomScale})`,
        transformOrigin: "top left",
        width: `${zoomWidth}%`,
      }}
    >
      <Table>
        <thead>
          <tr>
            {Object.keys(data[0]).map((key, index) => (
              <Th key={index}>{key}</Th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {row.map((cell, colIndex) => (
                <Td
                  key={colIndex}
                  id={`cell-${rowIndex}-${colIndex}`}
                  isHighlighted={
                    rowIndex === highlightRow && colIndex === highlightCol
                  }
                >
                  {cell}
                </Td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </TableWrapper>
  );
};

const PreviewExcel = ({
  data,
  highlightCell,
  fileName,
  onCloseClick = null,
}) => {
  const [highlightRow, setHighlightRow] = useState(null);
  const [highlightCol, setHighlightCol] = useState(null);
  const [zoomScale, setZoomScale] = useState(0.6);
  const [zoomWidth, setZoomWidth] = useState(166.67);

  const handleHighlight = (row, col) => {
    // For example, highlight cell at row 2 and column 3
    setHighlightRow(row);
    setHighlightCol(col);
  };

  useEffect(() => {
    if (highlightCell.row && highlightCell.col) {
      handleHighlight(highlightCell.row, highlightCell.col);
    }
  }, [highlightCell, data]);

  return (
    <div>
      <div
        style={{
          alignItems: "center",
          backgroundColor: "#eeeeee",
          minHeight: "28px",
          display: "flex",
          justifyContent: "space-between",
          position: "sticky",
          top: 0,
          zIndex: 1,
          opacity: 0.9,
          padding: "0px 8px",
        }}
      >
        <div
          style={{
            width: "35%",
            fontSize: "12px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {fileName}
        </div>
        {/* <div style={{ display: "flex" }}>
          <IconButton
            onClick={() => {
              setZoomWidth((1 + (1.1 - zoomScale) / (zoomScale - 0.1)) * 100);
              setZoomScale(zoomScale - 0.1);
            }}
            disabled
          >
            <span
              className="Appkit4-icon icon-zoom-out-outline ap-font-16"
              style={{ color: "black" }}
            />
          </IconButton>
          <div style={{ padding: "0px 2px" }}>{zoomScale}</div>
          <IconButton
            onClick={() => {
              setZoomScale(zoomScale + 0.1);
              setZoomWidth(1 - (0.1 / (zoomScale + 0.1)) * 100);
            }}
            disabled
          >
            <span
              className="Appkit4-icon icon-zoom-in-outline ap-font-16"
              style={{ color: "black" }}
            />
          </IconButton>
        </div> */}
        <div
          style={{
            width: "35%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          {onCloseClick && (
            <Tooltip title="Close" placement="bottom" arrow>
              <IconButton aria-label="close" onClick={onCloseClick}>
                <CancelIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </div>
      <TableComponent
        highlightRow={highlightRow}
        highlightCol={highlightCol}
        zoomScale={zoomScale}
        zoomWidth={zoomWidth}
        data={data}
      />
    </div>
  );
};

export default PreviewExcel;
