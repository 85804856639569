import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  _getAuthHeaders,
  _getAuthToken,
  _getClientID,
  _getClientSecret,
  _getDMATokenDetails,
  _getDMTProxy,
  _getIdToken,
  _getLoggedInUserDetails,
  _getRedirectUri,
} from "../../../envUrl";
import api from "../../api";

export const dmtTemplateApi = createApi({
  reducerPath: "dmtTemplateApi",
  baseQuery: fetchBaseQuery({
    baseUrl: _getDMTProxy(),
  }),
  endpoints: (builder) => ({
    getDmaTemplates: builder.mutation({
      query: () => {
        const authHeaders = _getAuthHeaders();
        const templid = _getDMATokenDetails("templid");

        const payload = {
          isget: true,
          id: templid,
        };

        return {
          url: api.dmtGetTemplate,
          method: "POST",
          body: payload,
          headers: authHeaders,
        };
      },
    }),
  }),
});

export const { useGetDmaTemplatesMutation } = dmtTemplateApi;
