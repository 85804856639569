// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mbz-report-container .preview-card-two {
  height: 300px !important;
  overflow: auto !important;
  opacity: 0.5;
}
.mbz-report-container .preview-card {
  height: 78vh !important;
  overflow: auto !important;
}
.mbz-report-container .icon-prefix-button.ap-button {
  padding-left: var(--spacing-5);
}
.mbz-report-container .preview-card-excel {
  height: 78vh !important;
}
.mbz-report-container .status-alert {
  flex-grow: 1;
  margin-right: 10px;
  align-items: center;
  width: fit-content;
}
.mbz-report-container .status-alert.MuiAlert-root {
  padding: 1px 16px !important;
}
.mbz-report-container .report-header {
  display: flex;
  align-items: center;
}
.mbz-report-container .customScrollbar {
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #a0a0a0 #e0e0e0; /* For Firefox */
}
.mbz-report-container .customScrollbar::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
.mbz-report-container .customScrollbar::-webkit-scrollbar-track {
  background: #e0e0e0;
  border-radius: 10px;
}
.mbz-report-container .customScrollbar::-webkit-scrollbar-thumb {
  background: #a0a0a0;
  border-radius: 10px;
  -webkit-transition: background 0.3s ease-in-out;
  transition: background 0.3s ease-in-out;
}
.mbz-report-container .customScrollbar::-webkit-scrollbar-thumb:hover {
  background: #888888;
}
.mbz-report-container .customScrollbar::-webkit-scrollbar-button {
  display: none; /* Hide the scrollbar arrows */
}`, "",{"version":3,"sources":["webpack://./src/modules/sgl/sgl-project/SglReport.scss"],"names":[],"mappings":"AACE;EACE,wBAAA;EACA,yBAAA;EAEA,YAAA;AADJ;AAIE;EACE,uBAAA;EACA,yBAAA;AAFJ;AAKE;EACE,8BAAA;AAHJ;AAME;EACE,uBAAA;AAJJ;AAOA;EACE,YAAA;EACA,kBAAA;EACA,mBAAA;EACA,kBAAA;AALF;AAME;EACE,4BAAA;AAJJ;AAQA;EACE,aAAA;EACA,mBAAA;AANF;AASE;EACE,qBAAA,EAAA,gBAAA;EACA,gCAAA,EAAA,gBAAA;AAPJ;AAUE;EACE,UAAA;EACA,WAAA;AARJ;AAWE;EACE,mBAAA;EACA,mBAAA;AATJ;AAYE;EACE,mBAAA;EACA,mBAAA;EACA,+CAAA;EAAA,uCAAA;AAVJ;AAaE;EACE,mBAAA;AAXJ;AAcE;EACE,aAAA,EAAA,8BAAA;AAZJ","sourcesContent":[".mbz-report-container {\n  .preview-card-two {\n    height: 300px !important;\n    overflow: auto !important;\n    // pointer-events: none;\n    opacity: 0.5;\n  }\n\n  .preview-card {\n    height: 78vh !important;\n    overflow: auto !important;\n  }\n\n  .icon-prefix-button.ap-button {\n    padding-left: var(--spacing-5);\n  }\n\n  .preview-card-excel {\n    height: 78vh !important;\n  }\n\n.status-alert {\n  flex-grow: 1;\n  margin-right: 10px;\n  align-items: center;\n  width: fit-content;\n  &.MuiAlert-root {\n    padding: 1px 16px !important;\n  }\n}\n\n.report-header {\n  display: flex;\n  align-items: center;\n}\n\n  .customScrollbar {\n    scrollbar-width: thin; /* For Firefox */\n    scrollbar-color: #a0a0a0 #e0e0e0; /* For Firefox */\n  }\n\n  .customScrollbar::-webkit-scrollbar {\n    width: 8px;\n    height: 8px;\n  }\n\n  .customScrollbar::-webkit-scrollbar-track {\n    background: #e0e0e0;\n    border-radius: 10px;\n  }\n\n  .customScrollbar::-webkit-scrollbar-thumb {\n    background: #a0a0a0;\n    border-radius: 10px;\n    transition: background 0.3s ease-in-out;\n  }\n\n  .customScrollbar::-webkit-scrollbar-thumb:hover {\n    background: #888888;\n  }\n\n  .customScrollbar::-webkit-scrollbar-button {\n    display: none; /* Hide the scrollbar arrows */\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
