// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dtms-home-container {
  padding: 2rem;
}
.dtms-home-container .dtms-home-content-container {
  display: flex;
  column-gap: 2%;
  flex-wrap: wrap;
  row-gap: var(--spacing-6);
}
.dtms-home-container .dtms-home-title-container {
  margin: 0 2rem 2rem 0;
  font-size: 1.5rem;
  font-weight: var(--font-weight-3);
}`, "",{"version":3,"sources":["webpack://./src/components/new-home/NewHome.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;AAAE;EACE,aAAA;EACA,cAAA;EACA,eAAA;EACA,yBAAA;AAEJ;AAAE;EACE,qBAAA;EACA,iBAAA;EACA,iCAAA;AAEJ","sourcesContent":[".dtms-home-container {\n  padding: 2rem;\n  .dtms-home-content-container {\n    display: flex;\n    column-gap: 2%;\n    flex-wrap: wrap;\n    row-gap: var(--spacing-6);\n  }\n  .dtms-home-title-container {\n    margin: 0 2rem 2rem 0;\n    font-size: 1.5rem;\n    font-weight: var(--font-weight-3);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
