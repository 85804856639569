import React, { useCallback, useEffect, useMemo, useState } from "react";
import "./NewHeader.scss";
import {
  Header,
  Avatar,
  DropdownButton,
  HeaderOptionItem,
} from "@appkit4/react-components";
import { useLocation, useNavigate } from "react-router-dom";
import { PreferenceModal } from "../new-preference-modal/PreferenceModal";
import { _getLoggedInUserDetails } from "../../envUrl";

const avatarMenu = [
  {
    label: "Preference",
    icon: "art-themes",
    value: "preference",
  },
  {
    label: "Feedback",
    icon: "marketing",
    value: "feedback",
    url: "https://docs.google.com/forms/d/e/1FAIpQLScBW6F2B15lhJs1In0HxhLLz739Pt9sKtpX_Fxf4EnLVu5-aQ/viewform",
  },
  {
    label: "Logout",
    icon: "lockclosed-locked",
    value: "logout",
  },
];

const menuIconMap = {
  Home: "home",
  DTMS: "home",
  TPRLA: "community",
  "Stage Gate Letter": "document-file",
  "Role assignment": "audience",
  DMA: "survey",
};

const NewHeader = ({
  logout = () => {},
  menu = [],
  isUserAdmin = true,
  setShowAdminMenu = () => {},
  showAdminMenu,
  hideMenu,
  showUserAvatar = true,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const userDetails = useMemo(_getLoggedInUserDetails, []);
  const [currentMenu, setCurrentMenu] = useState({});

  const onSelectAvatarMenu = (value, item) => {
    if (item.url) {
      window.open(item.url, "_blank");
      return;
    }
    switch (value) {
      // case "adminmenu":
      //   navigate(menu[0]?.modulePath);
      //   setShowAdminMenu(!showAdminMenu);
      //   break;
      case "logout":
        logout();
        break;
      case "preference":
        setPreferenceVisible(true);
        break;
      default:
        console.log("sorry no handlers for this option", value);
    }
  };

  useEffect(() => {
    let selectedMenu = null;
    menu.forEach((value) => {
      if (!selectedMenu && location.pathname.startsWith(value.modulePath)) {
        selectedMenu = value;
      }
    });
    setCurrentMenu(selectedMenu);
  }, [location.pathname, menu]);

  const onSelectoduleMenu = useCallback(
    (selectedValue) => {
      navigate(selectedValue);
    },
    [navigate]
  );

  const [isPreferenceVisible, setPreferenceVisible] = useState(false);
  const [isModuleMenuVisible, setIsModuleMenuVisible] = useState(false);

  let avatarLabel = "";
  if (userDetails?.firstName && userDetails?.lastName) {
    avatarLabel = `${userDetails.firstName[0]}${userDetails.lastName[0]}`;
  } else if (userDetails?.email) {
    const [localPart] = userDetails?.email?.split("@");
    avatarLabel =
      localPart.length > 1 ? localPart.slice(0, 2) : localPart.slice(0, 1);
  }
  const onLogoClick = useCallback(() => {
    navigate("/home");
  }, [navigate]);

  return (
    <>
      <Header
        className="dtms-header"
        type="solid"
        compact={false}
        onClickLogo={onLogoClick}
        titleTemplate={() =><div style={{cursor: 'pointer'}} onClick={onLogoClick}>
          {location.pathname.startsWith("/home")
            ? "Deals Technology Managed Services"
            : "DTMS"}
            </div>
        }
        subTitleTemplate={
          currentMenu ? () => currentMenu.description : undefined
        }
        optionsTemplate={() => (
          <>
            {!location.pathname.startsWith("/home") && !hideMenu && (
              <DropdownButton
                data={menu.map((value) => ({
                  label: value.description,
                  value: value.modulePath,
                  path: value.modulePath,
                  icon: menuIconMap[value.name],
                }))}
                className="dtms-menu-dropdown"
                onSelect={onSelectoduleMenu}
                customTriggerNode
                value={currentMenu?.modulePath}
                onVisibleChange={setIsModuleMenuVisible}
                prefixTemplate={(item) => (
                  <span className={`Appkit4-icon icon-${item.icon}-outline`} />
                )}
              >
                <HeaderOptionItem
                  iconSlot="end"
                  iconName={`down-chevron-outline ${
                    isModuleMenuVisible ? "rotate" : ""
                  }`}
                  className="module-menu"
                  label="Menu"
                ></HeaderOptionItem>
              </DropdownButton>
            )}
            <DropdownButton
              data={[
                {
                  label: "Issue Management",
                  value: "issuemanagement",
                  icon: "document-alert",
                  url: "https://docs.google.com/forms/d/e/1FAIpQLSczzI0-56ZD6-9tg4-9_s33waQYSqHO601EmLzyaZL7loYiBQ/viewform",
                },
                {
                  label: "Knowledge Site",
                  value: "knowledgesite",
                  icon: "book",
                  url: "https://pwcdeu.sharepoint.com/sites/GBL-ADV-DealsTechnologyManagedServices",
                },
              ]}
              onSelect={onSelectAvatarMenu}
              customTriggerNode
              value={""}
              prefixTemplate={(item) => (
                <span className={`Appkit4-icon icon-${item.icon}-outline`} />
              )}
            >
              <HeaderOptionItem
                iconName="help-question-outline"
                label="Support"
              ></HeaderOptionItem>
            </DropdownButton>
          </>
        )}
        userTemplate={() =>
          showUserAvatar ? (
            <DropdownButton
              data={avatarMenu}
              customTriggerNode
              value={""}
              prefixTemplate={(item) => (
                <span className={`Appkit4-icon icon-${item.icon}-outline`} />
              )}
              onSelect={onSelectAvatarMenu}
            >
              <Avatar
                label={avatarLabel}
                role="button"
                disabled={false}
              ></Avatar>
            </DropdownButton>
          ) : (
            <></>
          )
        }
      ></Header>
      <PreferenceModal
        isVisible={isPreferenceVisible}
        onClose={() => setPreferenceVisible(false)}
      />
    </>
  );
};

export default NewHeader;
