import { createSlice } from "@reduxjs/toolkit";

const notificationSlice = createSlice({
  name: "notification",
  initialState: {
    show: false,
    message: "",
    status: "icon-circle-warning-outline",
  },
  reducers: {
    setModuleNotification(state, action) {
      state.show = action.payload.show;
      state.message = action.payload.message;
      state.status = action.payload.status;
    },
    resetModuleNotification(state) {
      state.show = false;
      state.message = "";
      state.status = "icon-circle-warning-outline";
    },
  },
});

export const { setModuleNotification, resetModuleNotification } =
  notificationSlice.actions;
export default notificationSlice.reducer;
