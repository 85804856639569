// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dma-assessment-info-container .dma-assessment-info-title.ap-panel-head {
  padding-left: 0;
  background-color: var(--color-background-default);
}
.dma-assessment-info-container .dma-assessment-info-title.ap-panel-head .ap-panel-head-title {
  white-space: normal;
  height: auto;
  padding-bottom: var(--spacing-5);
  font-size: var(--spacing-6);
}
.dma-assessment-info-container .dma-assessment-info-body {
  padding-top: var(--spacing-5);
}
.dma-assessment-info-container .dma-assessment-info-body .dma-assessment-info {
  margin-top: var(--spacing-6);
}
.dma-assessment-info-container .dma-assessment-info-body .dma-assessment-info .dma-assessment-info-field {
  font-weight: var(--font-weight-3);
}
.dma-assessment-info-container .dma-assessment-info-body .dma-assessment-info .dma-assessment-info-value {
  padding-left: var(--spacing-5);
  padding-top: var(--spacing-3);
}
.dma-assessment-info-container .dma-assessment-info-body .dma-assessment-info .dma-assessment-info-value .dma-assessment-type-dropdown {
  max-width: calc(var(--spacing-5) * 25);
  margin-top: var(--spacing-3);
}
.dma-assessment-info-container .dma-assessment-info-body .dma-assessment-info:first-of-type {
  margin-top: 0;
}
.dma-assessment-info-container .dma-assessment-info-body .dma-assessment-info-footer {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  margin-top: 3rem;
  margin-left: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/modules/dma/assessment/components/assessment-info/AssessmentInfo.scss"],"names":[],"mappings":"AACE;EAEE,eAAA;EAOA,iDAAA;AAPJ;AACI;EACE,mBAAA;EACA,YAAA;EACA,gCAAA;EACA,2BAAA;AACN;AAGE;EACE,6BAAA;AADJ;AAGI;EACE,4BAAA;AADN;AAEM;EACE,iCAAA;AAAR;AAEM;EACE,8BAAA;EACA,6BAAA;AAAR;AACQ;EACE,sCAAA;EACA,4BAAA;AACV;AAGI;EACE,aAAA;AADN;AAGI;EACE,aAAA;EACA,SAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;AADN","sourcesContent":[".dma-assessment-info-container {\n  .dma-assessment-info-title.ap-panel-head {\n    // padding-left: calc(var(--spacing-5) * 2);\n    padding-left: 0;\n    .ap-panel-head-title {\n      white-space: normal;\n      height: auto;\n      padding-bottom: var(--spacing-5);\n      font-size: var(--spacing-6);\n    }\n    background-color: var(--color-background-default);\n  }\n  .dma-assessment-info-body {\n    padding-top: var(--spacing-5);\n    // margin: 0 calc(var(--spacing-5) * 2);\n    .dma-assessment-info {\n      margin-top: var(--spacing-6);\n      .dma-assessment-info-field {\n        font-weight: var(--font-weight-3);\n      }\n      .dma-assessment-info-value {\n        padding-left: var(--spacing-5);\n        padding-top: var(--spacing-3);\n        .dma-assessment-type-dropdown {\n          max-width: calc(var(--spacing-5) * 25);\n          margin-top: var(--spacing-3);\n        }\n      }\n    }\n    .dma-assessment-info:first-of-type {\n      margin-top: 0;\n    }\n    .dma-assessment-info-footer {\n      display: flex;\n      gap: 1rem;\n      flex-wrap: wrap;\n      margin-top: 3rem;\n      margin-left: 1rem;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
