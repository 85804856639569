import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  _getAuthHeaders,
  _getAuthToken,
  _getClientID,
  _getClientSecret,
  _getDMATokenDetails,
  _getDMTProxy,
  _getIdToken,
  _getLoggedInUserDetails,
  _getRedirectUri,
} from "../../../envUrl";
import api from "../../api";

export const dmtClientsApi = createApi({
  //   reducerPath: "dmtClientsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: _getDMTProxy(),
  }),
  endpoints: (builder) => ({
    getClients: builder.mutation({
      query: () => {
        const authHeaders = _getAuthHeaders();

        const payload = {
          isget: true,
        };

        return {
          url: api.dmtClients,
          method: "POST",
          body: payload,
          headers: authHeaders,
        };
      },
    }),
  }),
});

export const { useGetClientsMutation } = dmtClientsApi;
