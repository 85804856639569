import React, { useCallback, useEffect, useMemo, useState } from "react";
import NoProjectImg from "../../../assets/images/no-project.svg";
import { NewNoProject } from "../../../components/new-noproject/NewNoProject";
import "./NewSglDashboard.scss";
import { useGetAllSglProjectsMutation } from "../../../store/services/sgl/project";
import { confirmAlert } from "react-confirm-alert";
import { Button, Loading, Search } from "@appkit4/react-components";
import AddProject from "../sgl-dashboard/AddEditProject";
import ProjectCard from "../sgl-dashboard/ProjectCard";
import DeleteProject from "../sgl-dashboard/DeleteProject";
import { NewDeleteProjectModal } from "./NewDeleteProjectModal";
import { useDispatch } from "react-redux";
import { resetModuleNotification } from "../../../store/slices/dtms/notification";

const InlineLoader = () => (
  <div className="sgl-loading-container">
    <Loading loadingType="linear" indeterminate={true} compact={false} />
  </div>
);

const createAddEditModalObject = (
  visible = false,
  isEdit = false,
  projectDetails = null
) => ({
  visible,
  isEdit,
  projectDetails,
});

const createDeleteModalObject = (
  visible = false,
  projectId = null,
  projectName = ""
) => ({
  visible,
  projectId,
  projectName,
});
export const NewSglDashboard = ({ isAdmin }) => {
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const [getProjects, getProjectsResult] = useGetAllSglProjectsMutation();
  const [addEditProjectState, setAddEditProjectState] = useState(
    createAddEditModalObject()
  );
  const [deleteModalState, setDeleteModalState] = useState(
    createDeleteModalObject()
  );
  const {
    isLoading: isProjectListLoading,
    isSuccess: isProjectListSuccess,
    isError: isProjectListError,
    data: projectListData,
  } = getProjectsResult;
  useEffect(() => {
    getProjects("OPEN");
    dispatch(resetModuleNotification());
  }, [getProjects]);

  const onAddEditModalClose = useCallback(() => {
    setAddEditProjectState(createAddEditModalObject());
  }, []);

  const onDeleteClick = useCallback(({ projectId, projectName }) => {
    setDeleteModalState(createDeleteModalObject(true, projectId, projectName));
  }, []);

  const onEditClick = useCallback((projectDetails) => {
    setAddEditProjectState(
      createAddEditModalObject(true, true, projectDetails)
    );
  }, []);

  const onDeleteModalClose = useCallback(() => {
    setDeleteModalState(createDeleteModalObject());
  }, []);

  const refreshProjects = useCallback(
    (status) => {
      getProjects(status);
    },
    [getProjects]
  );

  const handleCreateClick = useCallback(() => {
    setAddEditProjectState(createAddEditModalObject(true));
  }, []);

  const onSearchTextChange = useCallback((value) => {
    setSearchText(value);
  }, []);

  const filteredProjects = useMemo(
    () =>
      projectListData?.result?.filter((value) =>
        value.projectName
          ?.toLocaleLowerCase()
          .includes(searchText?.toLocaleLowerCase())
      ) || [],
    [projectListData, searchText]
  );
  useEffect(() => {
    if (isProjectListError) {
      confirmAlert({
        title: "Error",
        message: "Unable to fetch projects!",
        buttons: [
          {
            label: "Ok",
          },
        ],
      });
    }
  }, [isProjectListError]);
  return (
    <>
      <div className="sgl-dashboard-container">
        {isProjectListLoading && InlineLoader()}
        {!isProjectListLoading && (
          <div className="search-filter-container">
            <Search
              searchType={"secondary"}
              onChange={onSearchTextChange}
              value={searchText}
              style={{ width: "300px" }}
            />
            {isProjectListSuccess && projectListData.result?.length > 0 && (
              <Button
                kind="text"
                className="top-create-btn"
                onClick={handleCreateClick}
              >
                <span className="Appkit4-icon icon-circle-plus-outline"></span>
                Create
              </Button>
            )}
          </div>
        )}
        {isProjectListSuccess &&
          projectListData.result?.length > 0 &&
          (filteredProjects.length > 0 ? (
            <div className="project-cards-list-container">
              {filteredProjects?.map((project) => (
                <div
                  className="project-cards-container"
                  key={project.projectId}
                >
                  <ProjectCard
                    projectDetails={project}
                    isAdmin={isAdmin}
                    onDelete={onDeleteClick}
                    onEdit={onEditClick}
                  />
                </div>
              ))}
            </div>
          ) : (
            <NewNoProject
              imageUrl={NoProjectImg}
              showCreateButton={false}
              title={"Sorry, no results found."}
              handleCreateClick={handleCreateClick}
            />
          ))}
        {!isProjectListLoading && !projectListData?.result?.length && (
          <NewNoProject
            imageUrl={NoProjectImg}
            showCreateButton={isAdmin}
            title={isAdmin ? "Create New Project" : "No projects to display"}
            handleCreateClick={handleCreateClick}
          />
        )}
      </div>
      <AddProject
        open={addEditProjectState.visible}
        handleClose={onAddEditModalClose}
        refreshProjects={refreshProjects}
        isEdit={addEditProjectState.isEdit}
        projectDetails={addEditProjectState.projectDetails}
      />
      <NewDeleteProjectModal
        isVisible={deleteModalState.visible}
        onClose={onDeleteModalClose}
        projectId={deleteModalState.projectId}
        projectName={deleteModalState.projectName}
        refreshProjects={refreshProjects}
      />
    </>
  );
};
