// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.no-project-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.no-project-container .no-project-image {
  width: 15rem;
}
.no-project-container .ap-panel {
  background-color: var(--color-background-default);
}
.no-project-container .ap-panel .ap-panel-head {
  background-color: var(--color-background-default);
}
.no-project-container .ap-panel button.create-btn {
  padding-left: var(--spacing-5);
}

@media screen and (max-width: 550px) {
  .no-project-container {
    flex-direction: column;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/new-noproject/NewNoProject.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;AACF;AAAE;EACE,YAAA;AAEJ;AAAE;EACE,iDAAA;AAEJ;AADI;EACE,iDAAA;AAGN;AADI;EACE,8BAAA;AAGN;;AAEA;EACE;IACE,sBAAA;EACF;AACF","sourcesContent":[".no-project-container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100%;\n  .no-project-image {\n    width: 15rem;\n  }\n  .ap-panel {\n    background-color: var(--color-background-default);\n    .ap-panel-head {\n      background-color: var(--color-background-default);\n    }\n    button.create-btn {\n      padding-left: var(--spacing-5);\n    }\n  }\n}\n\n@media screen and (max-width: 550px) {\n  .no-project-container {\n    flex-direction: column;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
