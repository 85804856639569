import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import "./DmaApp.scss";
import { CompletedAssessment } from "./completed-assessment/CompletedAssessment";
import NewNavigation from "../../components/new-navigation/NewNavigation";
import { NewFooter } from "../../components/new-footer/NewFooter";
import { Assessment } from "./assessment/Assessment";
import { usePostTokenMutation } from "../../store/services/dmt/auth";
import { setLoading } from "../../store/slices/dtms/loading";
import { useGetDmaTemplatesMutation } from "../../store/services/dmt/template";
import { setDmaTemplateDetails } from "../../store/slices/dmt/template";
import { confirmAlert } from "react-confirm-alert";
import { _getDMATokenDetails } from "../../envUrl";
import { useGetAssessmentMutation } from "../../store/services/dmt/assessment";
import {
  setDmaAssessmentDetails,
  setIsAssessmentActive,
} from "../../store/slices/dmt/assessment";
import { QuestionsList } from "./assessment/components/questions-list/QuestionsList";
import { Loading } from "@appkit4/react-components";
import { ProfileInfo } from "./profile/ProfileInfo";

const routesList = [
  {
    label: "Assessment",
    value: "Assessment",
    description: "Assessment",
    modulePath: "assessment",
    name: "assessment",
    element: <Assessment />,
    // exact: true,
  },
  {
    label: "Completed Assessments",
    value: "Completed Assessments",
    description: "Completed Assessments",
    modulePath: "completedassessment",
    name: "completedassessment",
    element: <CompletedAssessment />,
    exact: true,
  },
  {
    label: "Profile",
    value: "Profile",
    description: "Profile",
    modulePath: "profile",
    name: "profile",
    element: <ProfileInfo />,
    exact: true,
  },
];

export const DmaApp = () => {
  const dispatch = useDispatch();
  const [postDMAToken, postDMATokenResult] = usePostTokenMutation();
  const [userRole, setUserRole] = useState("");
  const [getDMATemplate, getDMATemplateResult] = useGetDmaTemplatesMutation();
  const [getAssessment, getAssessmentResult] = useGetAssessmentMutation();

  useEffect(() => {
    postDMAToken();
  }, [postDMAToken]);

  useEffect(
    () => () => {
      dispatch(setIsAssessmentActive(true));
    },
    [dispatch]
  );

  useEffect(() => {
    const { data, isSuccess, error, isError, isLoading } = postDMATokenResult;
    dispatch(setLoading(isLoading));
    if (isSuccess) {
      sessionStorage.setItem("dma_access_token", data.access_token);
      const { role } = _getDMATokenDetails();
      setUserRole(role);
      getDMATemplate();
      getAssessment();
    }
    if (isError) {
      console.log("Oops! ", error);
      dispatch(setLoading(isLoading));
      confirmAlert({
        title: "Error",
        message: "Unable to authenticate the user",
        buttons: [
          {
            label: "Ok",
          },
        ],
      });
    }
  }, [dispatch, getAssessment, getDMATemplate, postDMATokenResult]);

  useEffect(() => {
    const { data, isSuccess, error, isError, isLoading } = getDMATemplateResult;
    dispatch(setLoading(isLoading));
    if (isSuccess) {
      dispatch(setDmaTemplateDetails(data));
    }
    if (isError) {
      console.log("Oops! ", error);
      dispatch(setLoading(isLoading));
      confirmAlert({
        title: "Error",
        message: "Unable to template details",
        buttons: [
          {
            label: "Ok",
          },
        ],
      });
    }
  }, [dispatch, getDMATemplateResult]);

  useEffect(() => {
    const { data, isSuccess, error, isError, isLoading } = getAssessmentResult;
    dispatch(setLoading(isLoading));
    if (isSuccess) {
      dispatch(setDmaAssessmentDetails(data));
    }
    if (isError) {
      console.log("Oops! ", error);
      dispatch(setLoading(isLoading));
      confirmAlert({
        title: "Error",
        message: "Unable to fetch assessment details",
        buttons: [
          {
            label: "Ok",
          },
        ],
      });
    }
  }, [dispatch, getAssessmentResult]);
  return (
    <div className="dma-app-container">
      {postDMATokenResult.isSuccess && userRole.includes("user") && (
        <div className="dma-side-nav-container">
          <NewNavigation menu={routesList} />
        </div>
      )}
      <div className="dma-content-container">
        <div className="dma-route-container">
          <Routes>
            {postDMATokenResult.isSuccess && userRole.includes("user") ? (
              <>
                {routesList.map((value) => (
                  <Route
                    key={value.name}
                    path={
                      value.exact ? value.modulePath : `${value.modulePath}/*`
                    }
                    element={
                      value.element || (
                        <div className="under-construction">
                          under construction
                        </div>
                      )
                    }
                  ></Route>
                ))}
                <Route
                  path={"completedassessment/:asmtId"}
                  element={
                    <QuestionsList isCompletedAsmt={true} getDMATemplate />
                  }
                ></Route>
                <Route
                  path="*"
                  element={<Navigate to={routesList[0].modulePath} />}
                />
              </>
            ) : (
              <Route
                path="*"
                element={
                  <div className="dma-unauth-container">
                    {postDMATokenResult.isError &&
                      "Your are not authorized to view this page"}
                    {postDMATokenResult.isSuccess &&
                      !userRole?.includes("user") &&
                      userRole?.includes("administrator") &&
                      "Admin users does not have access user assessment"}
                    {postDMATokenResult.isLoading && <Loading />}
                  </div>
                }
              />
            )}
          </Routes>
        </div>
        <NewFooter />
      </div>
    </div>
  );
};
