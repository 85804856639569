import { Panel, Tooltip } from "@appkit4/react-components";
import "./NewProjectCards.scss";
import { useCallback } from "react";

export const NewProjectCards = ({
  title,
  details,
  description,
  className,
  onCardClick,
  otherDetails,
  isTabable
}) => {
  const handleCardClick = useCallback(() => {
    onCardClick(otherDetails);
  }, [onCardClick, otherDetails]);
  return (
    <div
      className={`dtms-project-cards-container ${className || ""} ${onCardClick && "card-clickable"}`}
      onClick={handleCardClick}
    >
      <Panel tabIndex={isTabable ? 0 : undefined}>
        <div className="panel-title-container">
          <Tooltip
            content={title}
            mouseEnterDelay={750}
            appendAfterTarget
            position="top-left"
            distance={10}
          >
            <div className="panel-title-text">{title}</div>
          </Tooltip>
        </div>
        {Object.keys(details || {}).map((key) => (
          <div className="project-card-field-row" key={key}>
            <div className="project-card-field-text">{key}</div>
            <div className="project-card-value-text">{details[key]}</div>
          </div>
        ))}
        {description && (
          <div className="project-card-description">{description}</div>
        )}
      </Panel>
    </div>
  );
};
