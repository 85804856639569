import { configureStore } from "@reduxjs/toolkit";
import { filesApi } from "./services/tprla/files";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import fileReducer from "./slices/tprla/fileSlice";
import { authApi } from "./services/dtms/auth";
import userReducer from "./slices/tprla/userSlice";
import uploadReducer from "./slices/tprla/uploadSlice";
import loadingReducer from "./slices/dtms/loading";
import { userApi } from "./services/tprla/users";
import { moduleApi } from "./services/dtms/module";
import { dtmsUserApi } from "./services/dtms/user";
import moduleReducer from "./slices/dtms/module";
import { tprlaMasterApi } from "./services/tprla/masterData";
import tprlaMasterReducer from "./slices/tprla/masterSlice";
import { projectApi } from "./services/tprla/project";
import { commentApi } from "./services/tprla/comment";
import { esraUserApi } from "./services/dtms/esraUser";
import { sglMasterApi } from "./services/sgl/masterData";
import { sglProjectsApi } from "./services/sgl/project";
import { sglUserApi } from "./services/sgl/users";
import sglMasterReducer from "./slices/sgl/masterSlice";
import sglUsersReducer from "./slices/sgl/userSlice";
import { sglFilesApi } from "./services/sgl/files";
import sglReportReducer from "./slices/sgl/reportSlice";
import { sglReportApi } from "./services/sgl/report";
import notificationReducer from "./slices/dtms/notification";
import { dmtTemplateApi } from "./services/dmt/template";
import dmaTemplateReducer from "./slices/dmt/template";
import dmaAssessmentReducer from "./slices/dmt/assessment";
import { dmtAssessmentApi } from "./services/dmt/assessment";
import { dmtClientsApi } from "./services/dmt/clients";
import dmaClientsReducer from "./slices/dmt/clients";

export const store = configureStore({
  reducer: {
    [filesApi.reducerPath]: filesApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [dtmsUserApi.reducerPath]: dtmsUserApi.reducer,
    [esraUserApi.reducerPath]: esraUserApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [dmtTemplateApi.reducerPath]: dmtTemplateApi.reducer,
    [dmtClientsApi.reducerPath]: dmtClientsApi.reducer,
    [dmtAssessmentApi.reducerPath]: dmtAssessmentApi.reducer,
    [moduleApi.reducerPath]: moduleApi.reducer,
    [tprlaMasterApi.reducerPath]: tprlaMasterApi.reducer,
    [projectApi.reducerPath]: projectApi.reducer,
    [commentApi.reducerPath]: commentApi.reducer,
    [sglMasterApi.reducerPath]: sglMasterApi.reducer,
    [sglProjectsApi.reducerPath]: sglProjectsApi.reducer,
    [sglUserApi.reducerPath]: sglUserApi.reducer,
    [sglFilesApi.reducerPath]: sglFilesApi.reducer,
    [sglReportApi.reducerPath]: sglReportApi.reducer,
    [dmtAssessmentApi.reducerPath]: dmtAssessmentApi.reducer,
    file: fileReducer,
    user: userReducer,
    upload: uploadReducer,
    loading: loadingReducer,
    module: moduleReducer,
    tprlaMaster: tprlaMasterReducer,
    sglMaster: sglMasterReducer,
    sglUsers: sglUsersReducer,
    sglReport: sglReportReducer,
    notification: notificationReducer,
    dmaTemplate: dmaTemplateReducer,
    dmaAssessment: dmaAssessmentReducer,
    dmaClients: dmaClientsReducer,
  },

  middleware: (getDefaultMiddleWare) =>
    getDefaultMiddleWare().concat(
      filesApi.middleware,
      userApi.middleware,
      dtmsUserApi.middleware,
      authApi.middleware,
      dmtTemplateApi.middleware,
      dmtAssessmentApi.middleware,
      moduleApi.middleware,
      tprlaMasterApi.middleware,
      projectApi.middleware,
      commentApi.middleware,
      esraUserApi.middleware,
      sglMasterApi.middleware,
      sglProjectsApi.middleware,
      sglUserApi.middleware,
      sglFilesApi.middleware,
      sglReportApi.middleware,
      dmtAssessmentApi.middleware,
      dmtClientsApi.middleware
    ),
});

setupListeners(store.dispatch);
