// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dma-privacy-modal-container.ap-modal {
  max-width: 50rem;
}
.dma-privacy-modal-container.ap-modal .ap-modal-body {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  max-height: calc(100vh - 8rem);
}
.dma-privacy-modal-container.ap-modal .ap-modal-body .dma-privacy-content-highlight {
  font-weight: var(--font-weight-2);
}
.dma-privacy-modal-container.ap-modal .ap-modal-footer {
  margin-top: 0;
  padding-bottom: var(--spacing-2);
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/modules/dma/assessment/components/privacy-modal/PrivacyModal.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;AAAE;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,8BAAA;AAEJ;AADI;EACE,iCAAA;AAGN;AAAE;EACE,aAAA;EACA,gCAAA;EACA,mBAAA;AAEJ","sourcesContent":[".dma-privacy-modal-container.ap-modal {\n  max-width: 50rem;\n  .ap-modal-body {\n    display: flex;\n    flex-direction: column;\n    gap: 2rem;\n    max-height: calc(100vh - 8rem);\n    .dma-privacy-content-highlight {\n      font-weight: var(--font-weight-2);\n    }\n  }\n  .ap-modal-footer {\n    margin-top: 0;\n    padding-bottom: var(--spacing-2);\n    align-items: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
