("use client");
import "./App.scss";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import fallbackRender from "./components/error/Error";
import React, { Suspense, useEffect, useMemo, useState } from "react";
import {
  useGetAccessCodeMutation,
  useGetRefreshCodeMutation,
  useRefreshSessionMutation,
} from "./store/services/dtms/auth";
import { useIdleTimer } from "react-idle-timer";
import { dtms } from "./utils/constants";
import Protected from "./components/shared/ProtectedRoute/Protected";
import _ from "lodash";
import {
  useGetAccessibleModulesMutation,
  useGetModuleRolesAdminMutation,
  useGetModuleRolesNonAdminMutation,
} from "./store/services/dtms/module";
import { confirmAlert } from "react-confirm-alert";
import { useDispatch } from "react-redux";
import {
  setMenuDetails,
  setModuleDetails,
  setUpdateAccessModules,
} from "./store/slices/dtms/module";
import useDidMountEffect from "./components/shared/CustomHooks/didMountEffect";
import { useSelector } from "react-redux";
import Education from "./modules/education/Education";
import Media from "./modules/media/Media";
import { setLoading } from "./store/slices/dtms/loading";
import { useSetPersistentTimeout } from "./components/shared/PersistentTimer/PersistentTimer";
import Unauthorised from "./components/auth/Unauthorised";
import TprlaApp from "./modules/tprla/TprlaApp";
import SglApp from "./modules/sgl/SglApp";
import { NewFooter } from "./components/new-footer/NewFooter";
import ModuleNotification from "./components/shared/Notification/ModuleNotification";
import { setModuleNotification } from "./store/slices/dtms/notification";
import { DmaApp } from "./modules/dma/DmaApp";
import { NewHome } from "./components/new-home/NewHome";
const ModalDialog = React.lazy(() => import("./components/shared/Modal/Modal"));
const RoleAssign = React.lazy(() =>
  import("./components/role-assign/RoleAssign")
);
const NewHeader = React.lazy(() => import("./components/new-header/NewHeader"));
const withFooter = (Comp, props = {}) => {
  return <>
    <Comp {...props}/>
    <NewFooter />
  </>
}
const App = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [getAccessCode, getAccessCodeResult] = useGetAccessCodeMutation();
  const [getRefreshCode, getRefreshCodeResult] = useGetRefreshCodeMutation();
  const [getModules, getModulesResult] = useGetAccessibleModulesMutation();
  const [getRolesAdmin, getRolesAdminResult] = useGetModuleRolesAdminMutation();
  const [getRolesNonAdmin, getRolesNonAdminResult] =
    useGetModuleRolesNonAdminMutation();
  const [refreshSession] = useRefreshSessionMutation();
  const [showAdminMenu, setShowAdminMenu] = useState(
    sessionStorage.getItem("showAdminMenu") === "true" ? true : false
  );
  const menus = useSelector((state) => state.module.menus);
  const updateAccessModules = useSelector(
    (state) => state.module.updateAccessModules
  );
  const notification = useSelector((state) => state.notification);

  const userDetails = JSON.parse(sessionStorage.getItem("UserDts"));

  const onIdle = () => {
    console.log("user is idle");
    console.log("last active", getLastActiveTime(), new Date());
    const env = ""; //process.env.REACT_APP_STAGE;
    if (env !== "local" && env !== "dev_r2") {
      userLogout();
    }
  };

  const onAction = () => {
    // console.log("user is active ", new Date());
  };

  const { start, getLastActiveTime } = useIdleTimer({
    onIdle,
    onAction,
    timeout: 1000 * 60 * 14,
    throttle: 500,
  });

  const getMenuItems = () => {
    getModules();
  };
  const [renderContent, setRenderContent] = useState(false);
  const [isUserAdmin, setIsUserAdmin] = useState(null);
  const [menu, setMenu] = useState([]);
  const [authorisedPaths, setAuthorisedPaths] = useState(null);
  const [sessionEndingAlert, setSessionEndingAlert] = useState({
    title: "Alert",
    option: {
      open: false,
      maxWidth: "sm",
      actionButtonName: "Ok",
    },
  });

  useEffect(() => {
    start();
    refreshSessionOnInterval();
    //logoutEvent();
    const env = ""; //process.env.REACT_APP_STAGE;
    if (env !== "local" && env !== "dev_r2") {
      const code = sessionStorage.getItem("pIAM");
      const token = sessionStorage.getItem("pIAM_id_token");
      if (token === null || token === undefined) {
        getAuthToken(code);
      } else {
        getMenuItems();
        //getMenus(menus);
        //this.setState({
        //   pIAMCode: code,
        // });
        fetchAllMasterData();
      }
    } else {
      getMenuItems();
      //getMenus(menus);
      fetchAllMasterData();
    }
  }, []);

  useEffect(() => {
    const { isLoading, isError, isSuccess, data, error } = getAccessCodeResult;
    if (isSuccess) {
      if (data && data.success) {
        sessionStorage.setItem("UserDts", JSON.stringify(data.result.user));
        sessionStorage.setItem(
          "pIAM_authorization_token",
          data.result.accessToken
        );
        sessionStorage.setItem(
          "pIAM_refreshtoken_token",
          data.result.refreshToken
        );
        sessionStorage.setItem("pIAM_id_token", data.result.idToken);
        sessionStorage.setItem("initCall", false);
        sessionStorage.setItem("time", Date.now());
        //setPIAMCode(code)
        getMenuItems();

        setTimeInterval();
        fetchAllMasterData();
      } else if (
        data.success === false &&
        data.error &&
        data.error.code === "EBU002"
      ) {
        sessionStorage.removeItem("pIAM_authorization_token");
        sessionStorage.removeItem("pIAM_refreshtoken_token");
        sessionStorage.removeItem("pIAM_id_token");
        sessionStorage.clear();
        sessionStorage.setItem("initCall", false);
        dispatch(setLoading(isLoading));
        navigate("/unauthorised");
      }
    }
    if (isError) {
      sessionStorage.removeItem("pIAM_authorization_token");
      sessionStorage.removeItem("pIAM_refreshtoken_token");
      sessionStorage.removeItem("pIAM_id_token");
      sessionStorage.clear();
      sessionStorage.setItem("initCall", false);
      dispatch(setLoading(isLoading));
      if (error?.data?.error?.code === "IE008") {
        window.location.href = "/sessionactive";
      } else {
        window.location.href = "/unauthorised";
      }
    }
  }, [getAccessCodeResult]);

  useEffect(() => {
    const { isLoading, isError, isSuccess, data, error } = getRefreshCodeResult;
    if (isSuccess) {
      if (data && data.success) {
        sessionStorage.setItem(
          "pIAM_authorization_token",
          data.result.accessToken
        );
        sessionStorage.setItem("pIAM_id_token", data.result.idToken);
        sessionStorage.setItem("refresh_called", "Yes");
        resetTokenExpire();
      } else if (
        data.success === false &&
        data.error &&
        data.error.code === "EBU004"
      ) {
        sessionStorage.removeItem("pIAM_authorization_token");
        sessionStorage.removeItem("pIAM_id_token");
        sessionStorage.clear();
        sessionStorage.setItem("initCall", false);
        dispatch(setLoading(isLoading));
        navigate("/unauthorised");
      }
    }
    if (isError) {
      console.log("Oops! ", error);
      sessionStorage.removeItem("pIAM_authorization_token");
      sessionStorage.removeItem("pIAM_refreshtoken_token");
      sessionStorage.removeItem("pIAM_id_token");
      sessionStorage.clear();
      sessionStorage.setItem("initCall", false);
      dispatch(setLoading(isLoading));
      window.location.href = "/";
    }
  }, [getRefreshCodeResult]);

  useEffect(() => {
    const { isLoading, isError, isSuccess, data, error } = getModulesResult;
    if (isSuccess) {
      const details = _.cloneDeep(data.result);
      sessionStorage.setItem("UserDts", JSON.stringify(data.result));
      const userAdmin = details.modules.findIndex((m) => m.name === dtms) > -1;
      setIsUserAdmin(userAdmin);
      if (!userAdmin) {
        dispatch(setMenuDetails(details.modules));
      }
    }
    if (isError) {
      console.log("Oops! ", error);
      dispatch(setLoading(isLoading));
      confirmAlert({
        title: "Error",
        message: "Unable to fetch module roles details",
        buttons: [
          {
            label: "Ok",
          },
        ],
      });
    }
  }, [getModulesResult]);

  useEffect(() => {
    const { isLoading, isError, isSuccess, data, error } = getRolesAdminResult;
    if (isSuccess) {
      const details = _.cloneDeep(data.result);
      dispatch(setModuleDetails(details));
      dispatch(setMenuDetails(details));
    }
    if (isError) {
      console.log("Oops! ", error);
      dispatch(setLoading(isLoading));
      confirmAlert({
        title: "Error",
        message: "Unable to fetch module roles details",
        buttons: [
          {
            label: "Ok",
          },
        ],
      });
    }
  }, [getRolesAdminResult]);

  useEffect(() => {
    const { isLoading, isError, isSuccess, data, error } =
      getRolesNonAdminResult;
    if (isSuccess) {
      const details = _.cloneDeep(data.result);

      dispatch(setUpdateAccessModules(details));
    }
    if (isError) {
      console.log("Oops! ", error);
      dispatch(setLoading(isLoading));
      confirmAlert({
        title: "Error",
        message: "Unable to fetch module roles details",
        buttons: [
          {
            label: "Ok",
          },
        ],
      });
    }
  }, [getRolesNonAdminResult]);

  useEffect(() => {
    if (isUserAdmin === true) {
      getRolesAdmin();
    } else if (isUserAdmin === false) {
      getRolesNonAdmin();
    }
  }, [isUserAdmin]);

  useDidMountEffect(() => {
    getMenus(menus);
  }, [menus]);

  const refreshSessionOnInterval = () => {
    setInterval(refreshSession, 1000 * 60 * 4);
  };

  useEffect(() => {
    if (userDetails) {
      if (_.isEmpty(userDetails.modules)) {
        navigate("/unauth");
      }
    }
  }, [userDetails]);

  // const logoutEvent = () => {
  //   // transfers sessionStorage from one tab to another

  //   // listen for changes to localStorage
  //   if (window.addEventListener) {
  //     window.addEventListener("storage", this.sessionStorageClear);
  //   } else {
  //     window.attachEvent("onstorage", this.sessionStorageClear);
  //   }
  // };

  const getAuthToken = (code) => {
    getAccessCode(code);
  };

  const fetchAllMasterData = () => {
    //call all master data services
  };

  const setTimeInterval = () => {
    startRefreshTimer();
  };

  const resetToken = () => {
    let refreshToken = sessionStorage.getItem("pIAM_refreshtoken_token");
    let code = sessionStorage.getItem("pIAM");
    if (refreshToken !== null) {
      getRefreshCode({ code, refreshToken });
    }
  };

  const [startRefreshTimer] = useSetPersistentTimeout(
    "refreshToken",
    resetToken,
    1000 * 60 * 50
  );

  const resetTokenExpire = () => {
    startSessionEndingAlertTimer();
  };

  const userLogout = () => {
    window.location.href = "/logout";
  };

  const openSessionEdingAlert = () => {
    setSessionEndingAlert({
      ...sessionEndingAlert,
      option: { ...sessionEndingAlert.option, open: true },
    });
  };

  const closeSessionEdingAlert = () => {
    setSessionEndingAlert({
      ...sessionEndingAlert,
      option: { ...sessionEndingAlert.option, open: false },
    });
  };

  const [startSessionEndingAlertTimer] = useSetPersistentTimeout(
    "sessionEndingAlert",
    () => {
      openSessionEdingAlert();
      startSessionClosingTimer();
    },
    1000 * 60 * (50 + 40)
  );
  const [startSessionClosingTimer] = useSetPersistentTimeout(
    "sessionClosing",
    () => {
      closeSessionEdingAlert();
      userLogout();
    },
    1000 * 60 * (50 + 50)
  );

  const getMenus = (initMenu) => {
    const menu = initMenu.filter((value) => value.name !== "DTMS");
    var allowedMenus = [];
    var allowedPaths = ['/home'];
    if (isUserAdmin) {
      const homeMenu = [
        {
          name: "Role assignment",
          description: "Role assignment",
          modulePath: "/userroles",
          menu_type: "admin",
          userRole: "",
          isRestricted: "Y",
        },
      ];
      allowedMenus = homeMenu.concat(menu);
      allowedMenus.forEach((m) => {
        allowedPaths.push(m.modulePath);
      });
    } else {
      
      allowedMenus = menu;
      allowedMenus.forEach((m) => {
        allowedPaths.push(m.modulePath);
      });
    }
    setMenu(allowedMenus);
    setAuthorisedPaths(allowedPaths);
    setRenderContent(true);
    dispatch(setLoading(false));
  };
  return (
    <>
      {renderContent && (
        <>
          <Suspense>
            <NewHeader
              menu={menu}
              logout={userLogout}
              setShowAdminMenu={setShowAdminMenu}
              showAdminMenu={showAdminMenu}
              updateModuleList={updateAccessModules}
              isUserAdmin={isUserAdmin}
            />
          </Suspense>
          <div className="dtms-body-container">
              {notification?.show && (
                <ModuleNotification
                  message={notification.message}
                  status={notification.status}
                  onClose={() =>
                    dispatch(
                      setModuleNotification({
                        show: false,
                      })
                    )
                  }
                />
              )}
              <div className="dtms-main">
                <Routes>
                  <Route
                path="home"
                element={
                  <ErrorBoundary fallbackRender={fallbackRender}>
                    <Protected
                      component={<NewHome menus={menu} />}
                      access={authorisedPaths}
                      isUserAdmin={isUserAdmin}
                    />
                  </ErrorBoundary>
                }
              />
                  <Route
                    path="userroles"
                    element={
                      <ErrorBoundary fallbackRender={fallbackRender}>
                        <Protected
                          component={
                            <Suspense>
                              {
                                withFooter(RoleAssign)
                              }
                            </Suspense>
                          }
                          isUserAdmin={isUserAdmin}
                          access={authorisedPaths}
                        />
                      </ErrorBoundary>
                    }
                  />
                  <Route
                    path="tprla/*"
                    element={
                      <ErrorBoundary fallbackRender={fallbackRender}>
                        <Protected
                          component={
                            <Suspense>
                              {
                                withFooter(TprlaApp, {isAdmin: isUserAdmin})
                              }
                            </Suspense>
                          }
                          isUserAdmin={isUserAdmin}
                          access={authorisedPaths}
                        />
                      </ErrorBoundary>
                    }
                  />
                  <Route
                    path="stage-gate-letter/*"
                    element={
                      <ErrorBoundary fallbackRender={fallbackRender}>
                        <Protected
                          component={
                            <Suspense>
                              {withFooter(SglApp, {isSuperAdmin: isUserAdmin})}
                            </Suspense>
                          }
                          isUserAdmin={isUserAdmin}
                          access={authorisedPaths}
                        />
                      </ErrorBoundary>
                    }
                  />
                  <Route
                    path="dma/*"
                    element={<ErrorBoundary fallbackRender={fallbackRender}>
                    <Protected
                      component={
                        <Suspense>
                          <DmaApp isSuperAdmin={isUserAdmin}/>
                        </Suspense>
                      }
                      isUserAdmin={isUserAdmin}
                      access={authorisedPaths}
                    />
                  </ErrorBoundary>}
                    />
                  <Route
                    path="education"
                    element={
                      <ErrorBoundary fallbackRender={fallbackRender}>
                        <Protected
                          component={withFooter(Education)}
                          isUserAdmin={isUserAdmin}
                          access={authorisedPaths}
                        />
                      </ErrorBoundary>
                    }
                  />
                  <Route
                    path="media"
                    element={
                      <ErrorBoundary fallbackRender={fallbackRender}>
                        <Protected
                          component={withFooter(Media)}
                          isUserAdmin={isUserAdmin}
                          access={authorisedPaths}
                        />
                      </ErrorBoundary>
                    }
                  />
                  <Route
                    path="unauth"
                    element={
                      <ErrorBoundary fallbackRender={fallbackRender}>
                        {withFooter(Unauthorised)}
                      </ErrorBoundary>
                    }
                  />
                  <Route
                    path="*"
                    element={<Navigate to='/home' />}
                  />
                </Routes>
              </div>
          </div>
        </>
      )}
      <Suspense>
        <ModalDialog
          {...sessionEndingAlert}
          onClose={closeSessionEdingAlert}
          onSave={closeSessionEdingAlert}
        >
          Your session is going to expire in 10 minutes. Please save your work.
        </ModalDialog>
      </Suspense>
    </>
  );
};

export default App;
