// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.question-option-group .question-option-item {
  padding-bottom: var(--spacing-5);
}
.question-option-group .ap-radio-disabled + .ap-radio-label {
  opacity: inherit;
}
.question-option-group .ap-checkbox-disabled + .ap-checkbox-label {
  opacity: inherit;
}`, "",{"version":3,"sources":["webpack://./src/modules/dma/assessment/components/question-options/QuestionOptions.scss"],"names":[],"mappings":"AACE;EACE,gCAAA;AAAJ;AAEE;EACE,gBAAA;AAAJ;AAEE;EACE,gBAAA;AAAJ","sourcesContent":[".question-option-group {\n  .question-option-item {\n    padding-bottom: var(--spacing-5);\n  }\n  .ap-radio-disabled + .ap-radio-label {\n    opacity: inherit;\n  }\n  .ap-checkbox-disabled + .ap-checkbox-label {\n    opacity: inherit;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
