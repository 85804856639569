import { Button, Checkbox, Modal } from "@appkit4/react-components";
import "./PrivacyModal.scss";
import { useState } from "react";

export const PrivacyModal = ({ onAcceptPrivacy, onCancelPrivacy }) => {
  const [isAcceptChecked, setIsAcceptChecked] = useState(false);
  return (
    <Modal
      visible
      title="Privacy Statement"
      className="dma-privacy-modal-container"
      closable
      onCancel={onCancelPrivacy}
      footer={
        isAcceptChecked && (
          <Button kind="primary" onClick={onAcceptPrivacy}>
            Proceed
          </Button>
        )
      }
    >
      <p className="dma-privacy-content-normal">
        This privacy statement explains the personal information collected
        through PwC’s identity and access management system (referred to in this
        privacy statement as “PwC Identity”) and provides information about
        individuals’ rights in relation to personal data. Personal data
        collected in connection with PwC Identity registration may be used for
        any of the purposes described in this privacy statement.The data
        controller of personal information collected in connection with PwC
        Identity is the PwC member firm that collected your personal information
        as part of the PwC Identity registration process and that is responsible
        for authenticating your access to PwC systems and applications as part
        of the services it provides to the organization you are connected with.
        This will be one of the PwC member firms listed here:
        https://www.pwc.com/gx/en/about/corporate-governance/legal-entities.html.
        In this privacy statement, “PwC,” “us,” and “we” refers to the PwC
        member firm that is the data controller of the personal information held
        for your PwC Identity registration. In this privacy statement, we refer
        to information about you or information that identifies you as “personal
        data” or “personal information.” We also sometimes collectively refer to
        handling, collecting, using, protecting or storing your personal
        information as “processing” such personal information.This privacy
        statement applies only to the personal information collected for PwC
        Identity. It does not apply to any other products, services or sites of
        PwC or any other party.
      </p>
      <p className="dma-privacy-content-highlight">
        By providing personal information for PwC Identity, you acknowledge you
        have read this privacy statement, and, to the extent your consent is
        necessary and valid under applicable law, you consent to the collection,
        use and disclosure of such personal information by PwC and third party
        recipients in accordance with this privacy statement.
      </p>
      <div className="dma-privacy-accepted-checkbox-container">
        <Checkbox value={isAcceptChecked} onChange={setIsAcceptChecked}>
          I Accept
        </Checkbox>
      </div>
    </Modal>
  );
};
