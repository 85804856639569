// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-container {
  width: 100%;
  text-align: center;
  font-size: calc(var(--spacing-4) + var(--spacing-1));
  color: var(--color-text-heading);
  padding: var(--spacing-5);
  background-color: var(--color-background-default);
}
.footer-container .footer-links-container {
  font-size: inherit;
}
.footer-container .footer-links-container .link-seperator {
  padding: 0 var(--spacing-4);
}
.footer-container .footer-links-container .footer-link {
  color: var(--color-text-link-primary);
  cursor: pointer;
  text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./src/components/new-footer/Footer.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,kBAAA;EACA,oDAAA;EACA,gCAAA;EACA,yBAAA;EACA,iDAAA;AACF;AAAE;EACE,kBAAA;AAEJ;AADI;EACE,2BAAA;AAGN;AADI;EACE,qCAAA;EACA,eAAA;EACA,0BAAA;AAGN","sourcesContent":[".footer-container {\n  width: 100%;\n  text-align: center;\n  font-size: calc(var(--spacing-4) + var(--spacing-1));\n  color: var(--color-text-heading);\n  padding: var(--spacing-5);\n  background-color: var(--color-background-default);\n  .footer-links-container {\n    font-size: inherit;\n    .link-seperator {\n      padding: 0 var(--spacing-4);\n    }\n    .footer-link {\n      color: var(--color-text-link-primary);\n      cursor: pointer;\n      text-decoration: underline;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
