import { useNavigate } from "react-router-dom";
import { NewProjectCards } from "../new-projectcards/NewProjectCards";
import "./NewHome.scss";
import { useCallback } from "react";

export const NewHome = ({ menus }) => {
  const navigate = useNavigate();
  const handleCardClick = useCallback(
    (menu) => {
      navigate(menu.modulePath);
    },
    [navigate]
  );
  return (
    <div className="dtms-home-container">
      <div className="dtms-home-title-container">Home</div>
      <div className="dtms-home-content-container">
        {menus.map((menu) => (
          <NewProjectCards
            otherDetails={menu}
            onCardClick={handleCardClick}
            key={menu.description}
            title={menu.description}
            description={menu.description}
            className="dtms-home-module"
          />
        ))}
      </div>
    </div>
  );
};
