// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ap-navigation-wrapper.dtms-side-navigation {
  height: calc(100vh - 4rem);
  flex-shrink: 0;
  border-radius: 0;
  border-right: 1px solid var(--color-background-border);
}
.ap-navigation-wrapper.dtms-side-navigation .ap-navigation-content {
  padding-top: var(--spacing-4);
}`, "",{"version":3,"sources":["webpack://./src/components/new-navigation/NewNavigation.scss"],"names":[],"mappings":"AAAA;EACE,0BAAA;EACA,cAAA;EACA,gBAAA;EACA,sDAAA;AACF;AAAE;EACE,6BAAA;AAEJ","sourcesContent":[".ap-navigation-wrapper.dtms-side-navigation {\n  height: calc(100vh - 4rem);\n  flex-shrink: 0;\n  border-radius: 0;\n  border-right: 1px solid var(--color-background-border);\n  .ap-navigation-content {\n    padding-top: var(--spacing-4);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
