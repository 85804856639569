import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  _getClientID,
  _getDTMSProxy,
  _getAuthHeaders,
  _getLoggedInUserDetails,
  _getEncoderKey,
} from "../../../envUrl";
import api from "../../api";

export const sglUserApi = createApi({
  reducerPath: "sglUserApi",
  baseQuery: fetchBaseQuery({
    baseUrl: _getDTMSProxy(),
  }),
  endpoints: (builder) => ({
    getAllSglUsers: builder.mutation({
      query: () => {
        const authHeaders = _getAuthHeaders();
        const user = _getLoggedInUserDetails();
        return {
          url: api.getSglUsers,
          method: "GET",
          headers: { ...authHeaders, email: user.email },
        };
      },
    }),
  }),
});

export const { useGetAllSglUsersMutation } = sglUserApi;
