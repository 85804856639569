// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.delete-project-confirm-modal.ap-modal .ap-modal-footer {
  padding-top: var(--spacing-2);
}

@media screen and (max-width: 500px) {
  .delete-project-confirm-modal {
    width: calc(var(--spacing-5) * 20);
  }
}
@media screen and (max-width: 1100px) and (min-width: 500px) {
  .delete-project-confirm-modal {
    width: calc(var(--spacing-5) * 30);
  }
}
@media screen and (min-width: 1100px) {
  .delete-project-confirm-modal {
    width: calc(var(--spacing-5) * 35);
  }
}`, "",{"version":3,"sources":["webpack://./src/modules/sgl/new-sgl-dashboard/NewDeleteProjectModal.scss"],"names":[],"mappings":"AACE;EACE,6BAAA;AAAJ;;AAIA;EACE;IACE,kCAAA;EADF;AACF;AAIA;EACE;IACE,kCAAA;EAFF;AACF;AAKA;EACE;IACE,kCAAA;EAHF;AACF","sourcesContent":[".delete-project-confirm-modal.ap-modal {\n  .ap-modal-footer {\n    padding-top: var(--spacing-2);\n  }\n}\n\n@media screen and (max-width: 500px) {\n  .delete-project-confirm-modal {\n    width: calc(var(--spacing-5) * 20);\n  }\n}\n\n@media screen and (max-width: 1100px) and (min-width: 500px) {\n  .delete-project-confirm-modal {\n    width: calc(var(--spacing-5) * 30);\n  }\n}\n\n@media screen and (min-width: 1100px) {\n  .delete-project-confirm-modal {\n    width: calc(var(--spacing-5) * 35);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
