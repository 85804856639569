import * as React from "react";
import { createStore } from "@react-pdf-viewer/core";

const useOpenLinksPlugin = (onClickHyperlink) => {
  const store = React.useMemo(() => createStore({}), []);

  const handleClick = (e) => {
    e.preventDefault();
    const href = e.target.href;
    store.update("clickedTarget", href);
    onClickHyperlink(href);
  };

  const findLinks = (e) => {
    e.container
      .querySelectorAll('a[data-target="external"]')
        .forEach((link) => {
            link.title = 'Click to locate';
        link.addEventListener("click", handleClick);
      });
  };

  return {
    onAnnotationLayerRender: findLinks,
  };
};

export default useOpenLinksPlugin;
