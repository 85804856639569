import React, { useEffect } from "react";
import { Viewer, Worker, SpecialZoomLevel } from "@react-pdf-viewer/core";
import { zoomPlugin } from "@react-pdf-viewer/zoom";
import { highlightPlugin, Trigger } from "@react-pdf-viewer/highlight";
import "@react-pdf-viewer/zoom/lib/styles/index.css";
import "@react-pdf-viewer/highlight/lib/styles/index.css";
import "@react-pdf-viewer/toolbar/lib/styles/index.css";
import { IconButton, Tooltip } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../../store/slices/dtms/loading";

const PreviewPDFViewer = ({
  fileUrl,
  fileName,
  scale = null,
  highlightArea = [],
  onCloseClick = null,
  zoomToScale = null,
}) => {
  const dispatch = useDispatch();
  const zoomPluginInstance = zoomPlugin();
  const { ZoomInButton, ZoomOutButton, ZoomPopover, zoomTo } =
    zoomPluginInstance;

  useEffect(() => {
    if (zoomToScale && typeof zoomToScale == "number") {
      zoomTo(zoomToScale);
    } else if (zoomToScale && typeof zoomToScale == "string") {
      zoomTo(SpecialZoomLevel[zoomToScale]);
    }
  }, [zoomToScale]);

  useEffect(() => {
    if (highlightArea.length > 0) {
      dispatch(setLoading(true));
      checkForHighlight();
    }
  }, [highlightArea]);

  const scrollToHighlight = () => {
    if (highlightArea.length > 0) {
      const a = document.querySelector(".highlight-area-for-preview");
      if (a) {
        dispatch(setLoading(false));
        const rect = a.getBoundingClientRect();
        const page = document.querySelector("#preview-pdf-viewer");
        page.scrollBy({
          top: rect.top - 280,
          left: 0,
          behavior: "smooth",
        });
      }
    }
  };

  const checkForHighlight = () => {
    const interval = setInterval(function () {
      if (document.querySelector(".highlight-area-for-preview")) {
        clearInterval(interval);
        scrollToHighlight();
      }
    }, 600);
  };

  const onDocLoad = () => {
    if (highlightArea.length > 0) {
      dispatch(setLoading(true));
      checkForHighlight();
    }
  };

  const renderHighlights = (props) => {
    return (
      <div>
        {highlightArea
          .filter((area) => area.pageIndex === props.pageIndex)
          .map((area, idx) => (
            <div
              key={idx}
              className="highlight-area-for-preview"
              style={Object.assign(
                {},
                {
                  background: "yellow",
                  opacity: 0.4,
                  zIndex: 1,
                },
                props.getCssProperties(area, props.rotation)
              )}
            />
          ))}
      </div>
    );
  };

  const highlightPluginInstance = highlightPlugin({
    renderHighlights,
    trigger: Trigger.None,
  });

  const renderError = (error) => {
    let message = "";
    switch (error.name) {
      case "InvalidPDFException":
        message = "The document is invalid or corrupted";
        break;
      case "MissingPDFException":
        message = "The document is missing";
        break;
      case "UnexpectedResponseException":
        message = "Unexpected server response";
        break;
      case "FormatError":
        message = "Format not correct";
        break;
      case "UnknownErrorException":
        message = "Unexpected server response";
        break;
      default:
        message = "Cannot load the document";
        break;
    }
    return (
      <div
        style={{
          alignItems: "center",
          display: "flex",
          height: "100%",
          justifyContent: "center",
        }}
      >
        <div className="pdf-error-message">{message}</div>
      </div>
    );
  };

  return (
    <div>
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js">
        <div
          className="rpv-core__viewer"
          style={{
            border: "1px solid rgba(0, 0, 0, 0.3)",
            display: "flex",
            flexDirection: "column",
            height: "100%",
            position: "relative",
          }}
        >
          <div
            style={{
              alignItems: "center",
              backgroundColor: "#eeeeee",
              display: "flex",
              justifyContent: "space-between",
              position: "sticky",
              top: 0,
              zIndex: 1,
              opacity: 0.9,
              padding: "0px 8px",
            }}
          >
            <div
              style={{
                width: "35%",
                fontSize: "12px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {fileName}
            </div>
            <div
              style={{
                display: "flex",
                width: "30%",
                justifyContent: "center",
              }}
            >
              <div style={{ padding: "0px 2px" }}>
                <ZoomOutButton />
              </div>
              <div style={{ padding: "0px 2px" }}>
                <ZoomPopover />
              </div>
              <div style={{ padding: "0px 2px" }}>
                <ZoomInButton />
              </div>
            </div>
            <div
              style={{
                width: "35%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              {onCloseClick && (
                <Tooltip title="Close" placement="bottom" arrow>
                  <IconButton aria-label="close" onClick={onCloseClick}>
                    <CancelIcon />
                  </IconButton>
                </Tooltip>
              )}
            </div>
          </div>
          <div
            style={{
              flex: 1,
              overflow: "hidden",
            }}
          >
            {fileUrl && (
              <Viewer
                fileUrl={fileUrl}
                renderError={renderError}
                renderLoader={() => {
                  dispatch(setLoading(true));
                }}
                plugins={[zoomPluginInstance, highlightPluginInstance]}
                defaultScale={scale ?? SpecialZoomLevel.PageWidth}
                onDocumentLoad={onDocLoad}
              />
            )}
          </div>
        </div>
      </Worker>
    </div>
  );
};

export default PreviewPDFViewer;
