import _ from "lodash";
import {
  fileTypesConstants,
  tpraDeleteConfirmMsg,
} from "../modules/tprla/tprlaUtils";
import moment from "moment";

const CryptoJS = require("crypto-js");

export const getRandomString = (length) => {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
};

export const b64toBlob = (
  b64Data,
  contentType = "application/pdf",
  sliceSize = 512
) => {
  const byteCharacters = window.atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const sortByName = (user, nextUser) => {
  if (user.firstName !== nextUser.firstName) {
    return user.firstName.toLowerCase() < nextUser.firstName.toLowerCase()
      ? -1
      : user.firstName.toLowerCase() > nextUser.firstName.toLowerCase()
      ? 1
      : 0;
  } else {
    return user.lastName.toLowerCase() < nextUser.lastName.toLowerCase()
      ? -1
      : user.lastName.toLowerCase() > nextUser.lastName.toLowerCase()
      ? 1
      : 0;
  }
};

export const convertToBytes = (memory) => {
  let memorySuffix = memory.substring(memory.length - 2, memory.length);
  switch (memorySuffix) {
    case "KB":
      return Math.ceil(1024 * parseFloat(memory));

    case "MB":
      return Math.ceil(1048576 * parseFloat(memory));

    case "GB":
      return Math.ceil(1073741824 * parseFloat(memory));

    default:
      return Math.ceil(parseFloat(memory));
  }
};

export const hmacEncode = (message, key) => {
  const encryptedText = CryptoJS.HmacSHA256(JSON.stringify(message), key);
  const hashInBase64 = CryptoJS.enc.Base64.stringify(encryptedText);
  return hashInBase64;
};

export function format(inputDate) {
  let date, month, year;

  date = inputDate.getDate();
  month = inputDate.getMonth() + 1;
  year = inputDate.getFullYear();

  date = date.toString().padStart(2, "0");

  month = month.toString().padStart(2, "0");

  return `${date}/${month}/${year}`;
}

export const getDeleteConfirmationMsg = (
  type,
  status = "",
  isShareWithTP = false,
  isSharedWithClient = false
) => {
  if (type === "REPORT") {
    if (!isShareWithTP && !isSharedWithClient) {
      return { message: tpraDeleteConfirmMsg.reportUnshared, reConfirm: false };
    } else if (!isShareWithTP && isSharedWithClient) {
      return {
        message: tpraDeleteConfirmMsg.reportSharedWithClient,
        reConfirm: true,
      };
    } else if (isShareWithTP && !isSharedWithClient) {
      return {
        message: tpraDeleteConfirmMsg.reportSharedWithTP,
        reConfirm: true,
      };
    } else if (isShareWithTP && isSharedWithClient) {
      return {
        message: tpraDeleteConfirmMsg.reportSharedWithBoth,
        reConfirm: true,
      };
    }
  } else {
    switch (status) {
      case "YET_TO_BE_SHARED":
        return {
          message: tpraDeleteConfirmMsg.releaseUnshared,
          reConfirm: false,
        };

      case "SHARED":
        return {
          message: tpraDeleteConfirmMsg.releaseShared,
          reConfirm: false,
        };

      case "SIGNED":
        return { message: tpraDeleteConfirmMsg.releaseSigned, reConfirm: true };

      default:
        break;
    }
  }
};

export const makeFileDataForTP = (files) => {
  const clonedFiles = _.cloneDeep(files);
  var tpfiles = clonedFiles.filter(
    (file) =>
      file.type === fileTypesConstants.release ||
      file.type === fileTypesConstants.report
  );
  tpfiles.forEach((file) => {
    var currentFile = {};
    file.copies = clonedFiles.filter(
      (f) =>
        f.fileName === file.fileName &&
        f.type !== fileTypesConstants.release &&
        f.type !== fileTypesConstants.report
    );
    file.copies.forEach((f) => {
      if (
        currentFile.createdTime ? currentFile.createdTime < f.createdTime : true
      ) {
        currentFile = f;
      }
    });
    file.latestCopyFileId = currentFile.id ? currentFile.id : file.id;
    file.status = currentFile.status ? currentFile.status : file.status;
    file.isSigned = currentFile.isSigned ? currentFile.isSigned : file.isSigned;
    file.signedBy = currentFile.signedBy ? currentFile.signedBy : file.signedBy;
  });
  console.log("TP File List: ", tpfiles);
  return tpfiles;
};

export const timestampToDate = (timestamp) => {
  var date = "";

  if (
    moment(Date()).format("DD MM YYYY") ===
    moment(timestamp).format("DD MM YYYY")
  ) {
    date = moment(timestamp).format("h:mm A");
  } else if (
    moment(Date()).subtract(1, "days").format("DD MM YYYY") ===
    moment(timestamp).format("DD MM YYYY")
  ) {
    date = "Yesterday " + moment(timestamp).format("h:mm A");
  } else if (
    moment(Date()).subtract(2, "days").format("DD MM YYYY") ===
      moment(timestamp).format("DD MM YYYY") ||
    moment(Date()).subtract(3, "days").format("DD MM YYYY") ===
      moment(timestamp).format("DD MM YYYY") ||
    moment(Date()).subtract(4, "days").format("DD MM YYYY") ===
      moment(timestamp).format("DD MM YYYY") ||
    moment(Date()).subtract(5, "days").format("DD MM YYYY") ===
      moment(timestamp).format("DD MM YYYY") ||
    moment(Date()).subtract(6, "days").format("DD MM YYYY") ===
      moment(timestamp).format("DD MM YYYY") ||
    moment(Date()).subtract(7, "days").format("DD MM YYYY") ===
      moment(timestamp).format("DD MM YYYY")
  ) {
    date = moment(timestamp).format("ddd h:mm A");
  } else {
    date = moment(timestamp).format("MMM D, h:mm A");
  }

  return date;
};

const digit = /(\((\d+(\.\d*)?)\))$/;
export const getRespValueFromMaturityValue = (maturityValue = "") => {
  if (digit.test(maturityValue)) {
    return parseFloat(maturityValue.match(digit)[2]);
  }
  return null;
};

export const getquestionOptions = (question, getResponseVal = false) => {
  const optionsList = [];
  const optionKeys = [
    "maturitylevel1",
    "maturitylevel2",
    "maturitylevel3",
    "maturitylevel4",
    "maturitylevel5",
  ];
  let selected = 0,
    total = 0;
  for (const optionKey in optionKeys) {
    const isSelected =
      question.responseValue?.current ||
      question.responseValue?.target ||
      optionKeys[optionKey] === question.responseValue ||
      question.responseValue?.includes?.(optionKeys[optionKey]);
    const id = question[optionKeys[optionKey]]
      ? optionKeys[optionKey]
      : question[`${optionKeys[optionKey]}Desc`]
      ? `${optionKeys[optionKey]}Desc`
      : null;
    if (id) {
      const maturityLabel = question[id];
      const optionVal = getRespValueFromMaturityValue(maturityLabel);
      const optionValue = optionVal || parseInt(optionKey) + 1;
      total += optionValue;
      if (getResponseVal && !isSelected) continue;
      if (getResponseVal && isSelected) {
        selected += optionValue;
      }
      optionsList.push({
        id: id,
        ind: optionsList.length,
        label: maturityLabel?.replace?.(digit, ""),
        maturityValue: parseInt(optionKey) + 1,
        optionValue: optionValue,
      });
    }
  }
  return getResponseVal ? { selected, total } : optionsList;
};

export const getQuestionResponseValue = (question) => {
  if (question.responseValue) {
    return question.responseValue;
  }
  let oldSelectedValue;
  switch (question.questionCategory) {
    case "MC":
      oldSelectedValue = question.responseOptions?.find(
        (val) => val.isChecked
      )?.maturityLevel;
      return (oldSelectedValue && `maturitylevel${oldSelectedValue}Desc`) || "";
    case "MS":
      oldSelectedValue = question.responseOptions
        ?.filter((val) => val.isChecked)
        .map((val) => `maturitylevel${val.maturityLevel}Desc`);
      return oldSelectedValue || [];
    case "SQ":
      return {
        current: question.response || 0,
        target: question.responsetarget || question.responseTarget || 0,
      };
    default:
      return "";
  }
};
const createNewQuestionObj = (question, dimention, subDimention, dbId) => ({
  other: "",
  ...question,
  dimention,
  subDimention,
  dbId,
  responseValue: getQuestionResponseValue(question),
  optionsList: getquestionOptions(question),
});

export const convertQuestionsJsonStringToTreeMap = (questionsString) => {
  let questionsArray = [];
  try {
    questionsArray = JSON.parse(questionsString);
  } catch (err) {
    console.log(err);
    throw new Error("Unable to parse the questions");
  }
  const dimentionTreeArray = [];

  questionsArray?.forEach?.((question) => {
    // if (!question.isChecked) return;
    const dimentionName = question.dimention || question.columndata?.Dimension;
    const subDimentionName =
      question.subDimention || question.columndata?.["Sub-Dimension"];
    const dbId = question.dbId || question.columndata?.["DB ID"];
    const dimention = dimentionTreeArray.find(
      (value) => value.name === dimentionName
    );
    if (dimention) {
      const subDimention = dimention.children.find(
        (value) => value.name === subDimentionName
      );
      if (subDimention) {
        const subLever = subDimention.children.find(
          (value) => value.dbId === dbId
        );
        if (!subLever) {
          subDimention.children.push(
            createNewQuestionObj(
              question,
              dimentionName,
              subDimentionName,
              dbId
            )
          );
          subDimention.reportData.totalResponse += parseFloat(
            question.response
          );
          subDimention.reportData.totalTarget += parseFloat(
            question.responseTarget || question.responsetarget
          );
          subDimention.reportData.totalBenchmark += parseFloat(
            question.benchmarkValue
          );
          subDimention.completed =
            question.response ||
            question.responseTarget ||
            question.responsetarget
              ? (subDimention.completed || 0) + 1
              : subDimention.completed || 0;
        } else {
          console.log("data already exist", { question, existing: subLever });
        }
      } else {
        dimention.children.push({
          name: subDimentionName,
          children: [
            createNewQuestionObj(
              question,
              dimentionName,
              subDimentionName,
              dbId
            ),
          ],
          reportData: {
            totalResponse: parseFloat(question.response || 0),
            totalTarget: parseFloat(
              question.responseTarget || question.responsetarget || 0
            ),
            totalBenchmark: parseFloat(question.benchmarkValue || 0),
          },
          completed:
            question.response ||
            question.responseTarget ||
            question.responsetarget
              ? 1
              : 0,
        });
      }
    } else {
      dimentionTreeArray.push({
        name: dimentionName,
        children: [
          {
            name: subDimentionName,
            children: [
              createNewQuestionObj(
                question,
                dimentionName,
                subDimentionName,
                dbId
              ),
            ],
            reportData: {
              totalResponse: parseFloat(question.response || 0),
              totalTarget: parseFloat(
                question.responseTarget || question.responsetarget || 0
              ),
              totalBenchmark: parseFloat(question.benchmarkValue || 0),
            },
            completed:
              question.response ||
              question.responseTarget ||
              question.responsetarget
                ? 1
                : 0,
          },
        ],
      });
    }
  });
  dimentionTreeArray.forEach((dimention) => {
    const dimensionReport = dimention.children.reduce(
      (prev, curr) => {
        if (curr.completed === curr.children.length) {
          prev.completed += 1;
        }
        prev.totalResponse += curr.reportData.totalResponse || 0;
        prev.totalTarget += curr.reportData.totalTarget || 0;
        prev.totalBenchmark += curr.reportData.totalBenchmark || 0;
        return prev;
      },
      { completed: 0, totalResponse: 0, totalTarget: 0, totalBenchmark: 0 }
    );
    dimention.completed = dimensionReport.completed;
    dimention.reportData = {
      totalResponse: dimensionReport.totalResponse,
      totalTarget: dimensionReport.totalTarget,
      totalBenchmark: dimensionReport.totalBenchmark,
    };
  });
  return dimentionTreeArray;
};

export const convertStringToJson = (string) => {
  let jsonArr = [];
  try {
    jsonArr = JSON.parse(string);
  } catch (err) {
    console.log(err);
    throw new Error("Unable to parse");
  }
  return jsonArr;
};

export const converQuestionsTreeToJSONMap = (questionTree) => {
  const questionsMap = [];
  questionTree?.forEach((dimension) => {
    dimension?.children?.forEach((subDimention) => {
      questionsMap.push(
        ...subDimention?.children?.map((question) => ({
          ...question,
          optionsList: undefined,
          columndata: undefined,
        }))
      );
    });
  });
  return questionsMap;
};

export const createDigitalValueReportData = (
  templateDetails,
  assessmentDetails,
  questionTree,
  digitalLeverData
) => {
  const reportData = [];
  const subDimentionsArray = [];
  questionTree.forEach((dim) => {
    dim.children.forEach((subDim) => {
      subDimentionsArray.push({
        name: subDim.name,
        dimension: dim.name,
        reportData: dim.reportData,
        totalChildren: subDim.children.length,
      });
    });
  });
  const opportunityStructure = JSON.parse(
    templateDetails.opportunityStructure || null
  );
  if (!opportunityStructure?.length) {
    return reportData;
  }
  const formulaNameMap = [];
  const plStructure = JSON.parse(
    assessmentDetails.plStructure || templateDetails.plStructure || null
  );
  Object.keys(plStructure || {}).forEach((value) => {
    if (Array.isArray(plStructure[value])) {
      plStructure[value]?.forEach((val) => {
        formulaNameMap.push({
          name: val.name,
          value: val.value1,
        });
      });
    }
  });
  const assumptionStructure = JSON.parse(
    assessmentDetails.assumptionStructure ||
      templateDetails.assumptionStructure ||
      null
  );
  assumptionStructure?.forEach((val) => {
    formulaNameMap.push({
      name: val.kpi,
      value: val.value,
    });
  });

  let maxRealisticCase = 0;
  opportunityStructure?.forEach((opportunity) => {
    if (!opportunity?.opportunityformula) return;
    const subDim = subDimentionsArray.find(
      (subDim) => subDim.name === opportunity.subdimension
    );
    if (!subDim) {
      return;
    }
    let numericFormula = opportunity.opportunityformula;
    opportunity.opportunityformula.split(/[()*/%+-]+/g).forEach((val) => {
      if (val === "Impact value") return;
      const plAssumVal = formulaNameMap.find(
        (formulaName) => formulaName.name === val
      );
      numericFormula = numericFormula.replace(
        new RegExp(`${val}`, "g"),
        `${plAssumVal.value}`
      );
    });
    const caseValues = {
      bestcase: 0,
      realisticcase: 0,
      minimumcase: 0,
    };
    const opportunityLever = digitalLeverData?.find(
      (lever) => lever.lever === opportunity.lever
    );
    Object.keys(caseValues).forEach((caseVal) => {
      try {
        if (numericFormula.includes("Impact value")) {
          numericFormula = numericFormula.replace(
            /Impact value/g,
            `${(opportunityLever?.[caseVal] || 0) / 100}`
          );
        }
        if (/^[()/\d.*+%-]+$/.test(numericFormula)) {
          // eslint-disable-next-line no-eval
          caseValues[caseVal] =
            Math.round(
              eval(numericFormula) *
                (1 -
                  subDim.reportData.totalResponse /
                    subDim.reportData.totalBenchmark) *
                10
            ) / 10;
        } else {
          console.log(
            "Cannot evaluate a formula that has text",
            numericFormula
          );
        }
      } catch (err) {
        console.log(`unable to parse the formula ${numericFormula}`, err);
      }
    });
    if (maxRealisticCase < caseValues.realisticcase)
      maxRealisticCase = caseValues.realisticcase;
    if (caseValues.realisticcase > 0)
      reportData.push({
        valuedriver: subDim.dimension,
        valuelever: subDim.name,
        digitallever: opportunity.lever,
        description: opportunityLever?.description,
        minimumcase: caseValues.minimumcase,
        realisticcase: caseValues.realisticcase,
        bestcase: caseValues.realisticcase,
        strategicrelevance: opportunityLever.strategicrelevance,
        onetimecost:
          Math.round(
            caseValues.realisticcase *
              opportunityLever.paybackperiodonetime *
              10
          ) / 10,
        totalimplementationcost:
          Math.round(
            caseValues.realisticcase * opportunityLever.paybackperiodtotal * 10
          ) / 10,
        implementationcomplexity: opportunityLever.implementationcomplexity,
        opportunityformula: `${opportunity.opportunityformula}*(1-Maturity)`,
        implementationtime: opportunityLever.implementationtime,
      });
  });

  return reportData
    .sort((a, b) => b.realisticcasevalue - a.realisticcasevalue)
    .splice(0, 10);
};

export const isNumber = (value) => {
  return /^\d*(\.\d*)?$/.test(`${value}`);
};

export const updateProgressInQuestionsTree = ({
  questionsTree,
  dimensionIndex,
  subDimensionIndex,
  questionIndex,
  setResponseTarget,
}) => {
  const indexes = [dimensionIndex, subDimensionIndex, questionIndex];
  if (!indexes.some((value) => !/^\d+$/.test(`${value}`))) {
    const path = `${dimensionIndex}.children[${subDimensionIndex}].children[${questionIndex}]`;
    if (setResponseTarget) {
      const quest = _.get(questionsTree, path);
      const { selected, total } = getquestionOptions(quest, true);
      if (quest.questionCategory === "MC") {
        quest.response = selected.toFixed(1);
        quest.responseTarget = selected.toFixed(1);
      } else if (quest.questionCategory === "MS") {
        quest.response = ((selected / total) * quest.benchmarkValue).toFixed(1);
        quest.responseTarget = (
          (selected / total) *
          quest.benchmarkValue
        ).toFixed(1);
      }
    }
    const subDim = _.get(
      questionsTree,
      `${dimensionIndex}.children[${subDimensionIndex}]`
    );
    const subDimReport = subDim?.children?.reduce(
      (prev, curr) => {
        if (curr.response || curr.responseTarget || curr.responsetarget) {
          prev.completed += 1;
        }
        prev.totalResponse += parseFloat(curr.response || 0);
        prev.totalTarget += parseFloat(
          curr.responseTarget || curr.responsetarget || 0
        );
        prev.totalBenchmark += parseFloat(curr.benchmarkValue || 0);
        return prev;
      },
      { completed: 0, totalResponse: 0, totalTarget: 0, totalBenchmark: 0 }
    );
    subDim.completed = subDimReport.completed;
    subDim.reportData.totalResponse = subDimReport.totalResponse;
    subDim.reportData.totalTarget = subDimReport.totalTarget;
    subDim.reportData.totalBenchmark = subDimReport.totalBenchmark;
    const dim = questionsTree?.[dimensionIndex];
    const dimensionReport = dim.children.reduce(
      (prev, curr) => {
        if (curr.completed === curr.children.length) {
          prev.completed += 1;
        }
        prev.totalResponse += curr.totalResponse || 0;
        prev.totalTarget += curr.totalTarget || 0;
        prev.totalBenchmark += curr.totalBenchmark || 0;
        return prev;
      },
      { completed: 0, totalResponse: 0, totalTarget: 0, totalBenchmark: 0 }
    );
    dim.completed = dimensionReport.completed;
    dim.reportData.totalResponse = dimensionReport.totalResponse;
    dim.reportData.totalTarget = dimensionReport.totalTarget;
    dim.reportData.totalBenchmark = dimensionReport.totalBenchmark;
    return questionsTree;
  }
};
