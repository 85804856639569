// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dtms-preference-modal .preference-section-title {
  background-color: var(--color-background-hover);
  padding: var(--spacing-3) var(--spacing-2);
  font-size: calc(var(--spacing-1) + var(--spacing-2) + var(--spacing-3));
  border-radius: var(--border-radius-2);
  margin: 0 0 var(--spacing-4);
}
.dtms-preference-modal .preference-list-inline {
  display: flex;
  margin-bottom: var(--spacing-5);
}
.dtms-preference-modal .preference-list-inline .theme-color-circle {
  color: var(--color-text-secondary);
  height: calc(var(--spacing-5) * 2);
  border-radius: calc(var(--spacing-5) * 2);
  width: calc(var(--spacing-5) * 2);
  padding-top: calc(var(--spacing-5) * 0.3);
  padding-left: calc(var(--spacing-5) * 0.2);
  font-size: calc(var(--spacing-1) + var(--spacing-2) + var(--spacing-3));
  margin-left: var(--spacing-3);
}`, "",{"version":3,"sources":["webpack://./src/components/new-preference-modal/PreferenceModal.scss"],"names":[],"mappings":"AACE;EACE,+CAAA;EACA,0CAAA;EACA,uEAAA;EACA,qCAAA;EACA,4BAAA;AAAJ;AAEE;EACE,aAAA;EACA,+BAAA;AAAJ;AACI;EACE,kCAAA;EACA,kCAAA;EACA,yCAAA;EACA,iCAAA;EACA,yCAAA;EACA,0CAAA;EACA,uEAAA;EACA,6BAAA;AACN","sourcesContent":[".dtms-preference-modal {\n  .preference-section-title {\n    background-color: var(--color-background-hover);\n    padding: var(--spacing-3) var(--spacing-2);\n    font-size: calc(var(--spacing-1) + var(--spacing-2) + var(--spacing-3));\n    border-radius: var(--border-radius-2);\n    margin: 0 0 var(--spacing-4);\n  }\n  .preference-list-inline {\n    display: flex;\n    margin-bottom: var(--spacing-5);\n    .theme-color-circle {\n      color: var(--color-text-secondary);\n      height: calc(var(--spacing-5) * 2);\n      border-radius: calc(var(--spacing-5) * 2);\n      width: calc(var(--spacing-5) * 2);\n      padding-top: calc(var(--spacing-5) * 0.3);\n      padding-left: calc(var(--spacing-5) * 0.2);\n      font-size: calc(var(--spacing-1) + var(--spacing-2) + var(--spacing-3));\n      margin-left: var(--spacing-3);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
