import { useState, useEffect, useMemo } from "react";
import "./SglProject.scss";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import TprlaProjectFiles from "./project-files/SglProjectFiles";
import { useSelector } from "react-redux";
import { _getLoggedInUserDetails } from "../../../envUrl";
import _ from "lodash";
import {
  useGenerateSglReportMutation,
  useGetSglReportStatusMutation,
} from "../../../store/services/sgl/report";
import { useDispatch } from "react-redux";
import {
  resetSglReportStatus,
  setSglReportStatus,
} from "../../../store/slices/sgl/reportSlice";
import {
  mbzReportStatus,
  mbzReportStatusMapping,
} from "../../../utils/constants";
import { confirmAlert } from "react-confirm-alert";
import { useGetProjectDetailsMutation } from "../../../store/services/sgl/project";
import { NewBreadcrumb } from "../../../components/new-breadcrumb/NewBreadcrumb";
import { Button, ButtonGroup } from "@appkit4/react-components";
import { setModuleNotification } from "../../../store/slices/dtms/notification";
import { setLoading } from "../../../store/slices/dtms/loading";

const SglProject = ({ userRole, getAllUsers, notifyHostRef }) => {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const isSPAuth = query.get("isSPAuth");
  const dispatch = useDispatch();

  const [projectName, setProjectName] = useState("");
  const [projectDetails, setProjectDetails] = useState({});
  const { userRoleTPA } = useSelector((state) => state.user);
  const sglReportStatus = useSelector(
    (state) => state.sglReport.sglReportStatus
  );

  useEffect(() => {
    dispatch(resetSglReportStatus());
    return () => {
      dispatch(
        setModuleNotification({
          show: false,
        })
      );
    };
  }, []);

  useEffect(() => {
    if (sglReportStatus !== null) {
      dispatch(
        setModuleNotification({
          show: true,
          message: sglReportStatus?.message?.message,
          status: mbzReportStatusMapping[sglReportStatus?.status],
        })
      );
    } else {
      dispatch(
        setModuleNotification({
          show: false,
        })
      );
    }
  }, [sglReportStatus]);

  const [getReportStatus, getReportStatusResult] =
    useGetSglReportStatusMutation();
  const [generateReport, generateReportResult] = useGenerateSglReportMutation();
  const [getProjectDetails, getProjectDetailsResult] =
    useGetProjectDetailsMutation();

  useEffect(() => {
    getAllUsers(params.projectId);
    getReportStatus(params.projectId);
    if (!isSPAuth) getProjectDetails(params.projectId);
  }, [params.projectId]);

  useEffect(() => {
    const { isLoading, isError, isSuccess, data, error } =
      getReportStatusResult;
    dispatch(setLoading(isLoading));
    if (isSuccess) {
      dispatch(setSglReportStatus(data.result));
    } else if (isError) {
      console.log("Error: ", error);
    }
  }, [getReportStatusResult]);

  useEffect(() => {
    const { isLoading, isError, isSuccess, data, error } = generateReportResult;
    dispatch(setLoading(isLoading));
    if (isSuccess) {
      confirmAlert({
        title: "Status",
        message:
          "Request for report generation has been submitted successfully.",
        buttons: [
          {
            label: "Ok",
            onClick: () => {
              getReportStatus(params.projectId);
            },
          },
        ],
      });
    } else if (isError) {
      console.log("Error: ", error);
      confirmAlert({
        title: "Error",
        message: "Unable to submit for report generation. Please try again.",
        buttons: [
          {
            label: "Ok",
          },
        ],
      });
    }
  }, [generateReportResult]);

  useEffect(() => {
    const { isLoading, isError, isSuccess, data, error } =
      getProjectDetailsResult;
    dispatch(setLoading(isLoading));
    if (isSuccess) {
      setProjectDetails(data.result);
    } else if (isError) {
      console.log("Error: ", error);
      confirmAlert({
        title: "Error",
        message: "Unable to fetch project details",
        buttons: [
          {
            label: "Ok",
          },
        ],
      });
    }
  }, [getProjectDetailsResult]);

  const getProjectName = (pname) => {
    setProjectName(pname);
  };

  const renderSglBtn = () => {
    switch (sglReportStatus?.status) {
      case mbzReportStatus.success:
      case mbzReportStatus.partialSuccess:
        return (
          <ButtonGroup direction="horizontal" alignment="ltr" disabled={false}>
            <Button
              className="icon-prefix-button"
              onClick={() => {
                generateReport(params.projectId);
              }}
            >
              <span className="Appkit4-icon icon-refresh-outline" />
              Re-Generate report
            </Button>
            <Button
              onClick={() => {
                navigate(`/stage-gate-letter/${params.projectId}/report`);
              }}
            >
              Go to report
            </Button>
          </ButtonGroup>
        );

      case mbzReportStatus.inProgress:
        return (
          <Button
            onClick={() => {
              getReportStatus(params.projectId);
            }}
            className="icon-prefix-button"
          >
            <span className="Appkit4-icon icon-refresh-outline"></span>
            Refresh
          </Button>
        );
      default:
        return (
          <Button
            onClick={() => {
              generateReport(params.projectId);
            }}
          >
            <span className="Appkit4-icon icon-refresh-outline"></span>
            Generate report
          </Button>
        );
    }
  };

  const breadcrumbs = useMemo(
    () => [
      {
        name: "",
        prefix: "home",
        path: "..",
      },
      {
        name: projectName,
      },
    ],
    [projectName]
  );

  return (
    <div className="workspace-details">
      <div
        className={`status-action-flex${
          sglReportStatus === null ? "-between" : ""
        }`}
      >
        <NewBreadcrumb breadcrumbs={breadcrumbs} />
        {renderSglBtn()}
      </div>
      <TprlaProjectFiles
        userRole={userRole}
        getProjectName={getProjectName}
        userType={userRoleTPA}
        projectDetails={projectDetails}
        getProjectDetails={getProjectDetails}
      />
    </div>
  );
};

export default SglProject;
