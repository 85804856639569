import React, { useState, useEffect, Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Route, Routes, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import _ from "lodash";
import { confirmAlert } from "react-confirm-alert";
import fallbackRender from "../../components/error/Error";
import { _getLoggedInUserDetails } from "../../envUrl";
import { useGetSglRolesMutation } from "../../store/services/sgl/masterData";
import { moduleNames } from "../../utils/constants";
import useDidMountEffect from "../../components/shared/CustomHooks/didMountEffect";
import { setSglProjectRoles } from "../../store/slices/sgl/masterSlice";
import { setSglUsers } from "../../store/slices/sgl/userSlice";
import { useGetAllSglUsersMutation } from "../../store/services/sgl/users";
import SglProject from "./sgl-project/SglProject";
import SglReport from "./sgl-project/SglReport";
import { NewSglDashboard } from "./new-sgl-dashboard/NewSglDashboard";
import "./SglApp.scss";

const SglApp = ({ isSuperAdmin }) => {
  const dispatch = useDispatch();
  const params = useParams();

  const [userRole, setUserRole] = useState(null);
  const [isModuleAdmin, setIsModuleAdmin] = useState(isSuperAdmin);

  const [getMbzRoles, getMbzRolesResult] = useGetSglRolesMutation();
  const [getAllUsers, getAllUsersResult] = useGetAllSglUsersMutation();

  useEffect(() => {
    const user = _getLoggedInUserDetails();
    getAllUsers();
    const moduleDetails = user.modules.find(
      (module) => module.name === "Stage Gate Letter"
    );
    const isMbzAdmin = isSuperAdmin || moduleDetails?.role?.name === "ADMIN";

    setIsModuleAdmin(isMbzAdmin);

    getMbzRoles();

    const userRoleName = isMbzAdmin ? "ADMIN" : moduleDetails?.role?.name;

    setUserRole(userRoleName);
  }, []);

  useEffect(() => {
    const { isLoading, isError, isSuccess, data, error } = getAllUsersResult;
    if (isSuccess) {
      const clonedRes = _.cloneDeep(data.result);
      clonedRes.forEach((d) => (d.role = d.role?.name));
      dispatch(setSglUsers(clonedRes));
    } else if (isError) {
      confirmAlert({
        title: "Unable to fetch",
        message: "Unable to fetch users",
        buttons: [
          {
            label: "Ok",
          },
        ],
      });
    }
  }, [getAllUsersResult]);

  useDidMountEffect(() => {
    const { isLoading, isError, isSuccess, data, error } = getMbzRolesResult;
    if (isSuccess) {
      const clonedRes = _.cloneDeep(data.result);
      const moduleDetails = clonedRes.find(
        (module) => (module.name = moduleNames.sgl)
      );
      dispatch(setSglProjectRoles(moduleDetails.roles));
    } else if (isError) {
      const errorMessage =
        error.data && error.data.error
          ? error.data.error.message
            ? error.data.error.message
            : "Unable to fetch roles"
          : "Unable to fetch roles";
      confirmAlert({
        title: "Error",
        message: errorMessage,
        buttons: [
          {
            label: "Ok",
          },
        ],
      });
    }
  }, [getMbzRolesResult]);
  return (
    <div className="sgl-app">
      {userRole && (
        <Routes>
          <Route
            path=":projectId"
            element={
              <ErrorBoundary fallbackRender={fallbackRender}>
                <Suspense>
                  <SglProject
                    userRole={userRole}
                    isAdmin={isModuleAdmin}
                    getAllUsers={getAllUsers}
                  />
                </Suspense>
              </ErrorBoundary>
            }
          />
          <Route
            path=":projectId/report"
            element={
              <ErrorBoundary fallbackRender={fallbackRender}>
                <SglReport userRole={userRole} isAdmin={isModuleAdmin} />
              </ErrorBoundary>
            }
          />
          <Route
            path="*"
            element={
              <ErrorBoundary fallbackRender={fallbackRender}>
                <NewSglDashboard isAdmin={isModuleAdmin} />
              </ErrorBoundary>
            }
          />
        </Routes>
      )}
    </div>
  );
};

export default SglApp;
