import axios from "axios";
import { getRandomString } from "./utils/utilFns";
import { jwtDecode } from "jwt-decode";

let BASE_URL = "";
let REDIRECT_URI = "";
let AUTH_SERVICE_URL = "";
let DTMS_SERVICE_URL = "";
let IAM_HOST = "";
let DTMS_API_URL = "";
let CLIENT_ID = "";
let SIGNOUT_URL = "";
let CLIENT_SECRET = process.env.REACT_APP_client_app_id;
let ENCODER_KEY = "";
let ESRA_URL = "";
let DMT_URL = "";
let SP_CLIENT_ID = process.env.REACT_APP_SP_CLIENT_NON_PROD;

if (process.env.REACT_APP_STAGE === "stg") {
  BASE_URL = process.env.REACT_APP_DTMS_APP_URL_STAGE;
  REDIRECT_URI = process.env.REACT_APP_REDIRECT_URI_STAGE;
  AUTH_SERVICE_URL = process.env.REACT_APP_AUTH_API_URL_STAGE;
  DTMS_SERVICE_URL = process.env.REACT_APP_DTMS_API_URL_STAGE;
  IAM_HOST = process.env.REACT_APP_IAM_HOST_NP;
  DTMS_API_URL = process.env.REACT_APP_DTMS_PROXY_URL_STAGE;
  CLIENT_ID = process.env.REACT_APP_CLIENT_ID_STAGE;
  SIGNOUT_URL = process.env.REACT_APP_SIGNOUT_URL_STAGE;
  ENCODER_KEY = process.env.REACT_APP_encoder_key_stage;
  ESRA_URL = process.env.REACT_APP_ESRA_APP_URL_STAGE;
  DMT_URL = process.env.REACT_APP_DMT_APP_URL_STAGE;
} else if (process.env.REACT_APP_STAGE === "qa") {
  BASE_URL = process.env.REACT_APP_DTMS_APP_URL_QA;
  REDIRECT_URI = process.env.REACT_APP_REDIRECT_URI_QA;
  AUTH_SERVICE_URL = process.env.REACT_APP_AUTH_API_URL_QA;
  DTMS_SERVICE_URL = process.env.REACT_APP_DTMS_API_URL_QA;
  IAM_HOST = process.env.REACT_APP_IAM_HOST_NP;
  DTMS_API_URL = process.env.REACT_APP_DTMS_PROXY_URL_QA;
  CLIENT_ID = process.env.REACT_APP_CLIENT_ID_QA;
  SIGNOUT_URL = process.env.REACT_APP_SIGNOUT_URL_QA;
  ENCODER_KEY = process.env.REACT_APP_encoder_key_qa;
  ESRA_URL = process.env.REACT_APP_ESRA_APP_URL_STAGE;
  DMT_URL = process.env.REACT_APP_DMT_APP_URL_QA;
} else if (process.env.REACT_APP_STAGE === "prod") {
  BASE_URL = process.env.REACT_APP_DTMS_APP_URL_PROD;
  REDIRECT_URI = process.env.REACT_APP_REDIRECT_URI_PROD;
  AUTH_SERVICE_URL = process.env.REACT_APP_AUTH_API_URL_PROD;
  DTMS_SERVICE_URL = process.env.REACT_APP_DTMS_API_URL_PROD;
  IAM_HOST = process.env.REACT_APP_IAM_HOST;
  DTMS_API_URL = process.env.REACT_APP_DTMS_PROXY_URL_PROD;
  CLIENT_ID = process.env.REACT_APP_CLIENT_ID_PROD;
  SIGNOUT_URL = process.env.REACT_APP_SIGNOUT_URL_PROD;
  ENCODER_KEY = process.env.REACT_APP_encoder_key_prod;
  ESRA_URL = process.env.REACT_APP_ESRA_APP_URL_PROD;
  DMT_URL = process.env.REACT_APP_DMT_APP_URL_PROD;
  SP_CLIENT_ID = process.env.REACT_APP_SP_CLIENT_PROD;
} else if (
  process.env.REACT_APP_STAGE === "dev_r2" ||
  process.env.REACT_APP_STAGE === "local"
) {
  BASE_URL = process.env.REACT_APP_DTMS_APP_URL_DEV_R2;
  REDIRECT_URI = process.env.REACT_APP_REDIRECT_URI_DEV_R2;
  AUTH_SERVICE_URL = process.env.REACT_APP_AUTH_API_URL_DEV_R2;
  DTMS_SERVICE_URL = process.env.REACT_APP_DTMS_API_URL_DEV_R2;
  IAM_HOST = process.env.REACT_APP_IAM_HOST_NP;
  DTMS_API_URL = process.env.REACT_APP_DTMS_PROXY_URL_DEV_R2;
  CLIENT_ID = process.env.REACT_APP_CLIENT_ID_DEV;
  SIGNOUT_URL = process.env.REACT_APP_SIGNOUT_URL_DEV_R2;
  ENCODER_KEY = process.env.REACT_APP_encoder_key_dev_r2;
  ESRA_URL = process.env.REACT_APP_ESRA_APP_URL_DEV_R2;
  DMT_URL = process.env.REACT_APP_DMT_APP_URL_DEV_R2;
}

export function _getBaseUrl() {
  return BASE_URL;
}

export function _getRedirectUri() {
  if (process.env.REACT_APP_STAGE === "local") {
    return "http://localhost:4000/";
  } else {
    return REDIRECT_URI;
  }
}

export function _getAuthServiceUrl() {
  return AUTH_SERVICE_URL;
}

export function _getDtmsServiceUrl() {
  return DTMS_SERVICE_URL;
}

export function _getIAMHost() {
  return IAM_HOST;
}

export function _getDTMSProxy() {
  return DTMS_API_URL;
}

export function _getDMTProxy() {
  return DMT_URL;
}

export function _getClientID() {
  return CLIENT_ID;
}

export function _getSignOutUrl() {
  if (process.env.REACT_APP_STAGE === "local") {
    return "http://localhost:4000/";
  } else {
    return SIGNOUT_URL;
  }
}

export function _getLoginUrl() {
  const iamHost = _getIAMHost();
  const redirectUri = _getRedirectUri();
  const clientid = _getClientID();
  const randomString = getRandomString(10);

  return `https://${iamHost}/openam/oauth2/realms/venturessso/authorize?response_type=code&client_id=${clientid}&redirect_uri=${redirectUri}&scope=ventures%20openid&state=${randomString}`;
}

export function _getLoggedInUserDetails() {
  return JSON.parse(sessionStorage.getItem("UserDts"));
}

export function _getLogoutUrl() {
  const iamHost = _getIAMHost();
  const signout = _getSignOutUrl();
  return `https://${iamHost}/openam/XUI/?realm=%2Fventuressso#logout/&goto=${signout}`;
}

export function _getAuthToken() {
  let openAm_authorization_token = sessionStorage.getItem(
    "pIAM_authorization_token"
  );
  return openAm_authorization_token ? openAm_authorization_token : "";
}

export function _getIdToken() {
  let openAm_id_token = sessionStorage.getItem("pIAM_id_token");
  return openAm_id_token ? openAm_id_token : "";
}

export function _getAuthHeaders() {
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: _getAuthToken(),
    param: _getIdToken(),
  };
  return headers;
}

export function _getClientSecret() {
  return CLIENT_SECRET;
}

export function _getEncoderKey() {
  return ENCODER_KEY;
}

export function _getESRALink() {
  return (
    ESRA_URL || "https://portal-esra-dev-r2.np.dealstechmanagedservices.pwc.de/"
  );
}

export function _getDMTLink() {
  return (
    DMT_URL || "https://ssp-dmt-app-dev-r2.np.dealstechnology.pwcinternal.com/"
  );
}

export function _getAutToken() {
  let openAm_authorization_token = sessionStorage.getItem(
    "openAm_authorization_token"
  );
  return openAm_authorization_token ? openAm_authorization_token : "";
}

export function _get(url, data, additionalHeader) {
  return axios
    .get(url + _getQueryString(data), _getHeaders(additionalHeader))
    .then(_parse, errorHandle);
}

export function _post(url, data, additionalHeader) {
  return axios
    .post(url, data, _getHeaders(additionalHeader))
    .then(_parse, errorHandle);
}

export function _postWithParams(url, body, additionalHeader, params) {
  return axios
    .post(url, body, _generateAxiosPostConfig(params, additionalHeader))
    .then(_parse, errorHandle);
}

export function _put(url, data, additionalHeader) {
  return axios
    .put(url, data, _getHeaders(additionalHeader))
    .then(_parse, errorHandle);
}

export function _putWithParams(url, data, additionalHeader, params) {
  return axios
    .put(url, data, _generateAxiosPostConfig(params, additionalHeader))
    .then(_parse, errorHandle);
}

export function _delete(url, data, additionalHeader) {
  /*return axios
    .delete(url, _getHeaders(additionalHeader))
    .then(_parse, errorHandle);*/
  return axios
    .post(url, data, _getHeaders(additionalHeader))
    .then(_parse, errorHandle);
}

export function _deleteAx(url, data, additionalHeader) {
  return axios
    .delete(url, _getHeaders(additionalHeader))
    .then(_parse, errorHandle);
}

function errorHandle(err) {
  if (err && err.response.status === 401) {
    window.location.href = "/logout";
  }
}

function _generateAxiosPostConfig(customParams, additionalHeader = {}) {
  const allHeaders = _getHeaders(additionalHeader);
  return {
    params: customParams,
    headers: allHeaders.headers,
  };
}

function _getHeaders(additionalHeader = {}) {
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  Object.keys(additionalHeader).forEach(
    (key) => (headers[key] = additionalHeader[key])
  );
  return { headers };
}

function _getQueryString(data = {}) {
  const rand = Math.random();
  if (data) {
    const query =
      "?" +
      Object.keys(data)
        .map((key) => _mapKeyToValue(key, data[key]))
        .join("&") +
      `&a=${rand}`;
    return query;
  }
  return "";
}

function _mapKeyToValue(key, value) {
  return `${key}=${value}`;
}

const _parse = (response) => {
  return new Promise((resolve, reject) => {
    if (response.status === 403) {
      reject(new Error("403 Forbidden: Server refuses to authorize it."));
    } else if (response.status === 401) {
      reject();
      // new Error(
      //   "401 Unauthorized: Request has not been applied because it lacks valid authentication."
      // )
    } else {
      resolve(response.data);
    }
  });
};

export function _getSPHost() {
  return "login.microsoftonline.com";
}

export function _getSPClientID() {
  return SP_CLIENT_ID || "256fe16d-2731-432a-ac9c-8dcead4c03bb";
}

export function _getSPAuthUrl(state) {
  const host = _getSPHost();
  const redirectUri = _getRedirectUri() + "stage-gate-letter/";
  const clientid = _getSPClientID();

  return `https://${host}/513294a0-3e20-41b2-a970-6d30bf1546fa/oauth2/v2.0/authorize?client_id=${clientid}&response_type=code&redirect_uri=${redirectUri}&response_mode=query&scope=Files.Read.All%20Files.ReadWrite.All%20Sites.Read.All%20Sites.ReadWrite.All%20User.Read%20profile%20openid%20email%20offline_access&state=${state}`;
}

export function _getDMATokenDetails(key = null) {
  const token = sessionStorage.getItem("dma_access_token");
  const decodedToken = jwtDecode(token);

  return key !== null ? decodedToken[key] : decodedToken;
}
