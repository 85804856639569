import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  _getAuthHeaders,
  _getAuthToken,
  _getClientID,
  _getClientSecret,
  _getDMTProxy,
  _getIdToken,
  _getLoggedInUserDetails,
  _getRedirectUri,
} from "../../../envUrl";
import api from "../../api";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    baseUrl: _getDMTProxy(),
  }),
  endpoints: (builder) => ({
    postToken: builder.mutation({
      query: () => {
        const loggedInUserDetails = _getLoggedInUserDetails();
        const email = loggedInUserDetails.email;

        const formData = new FormData();
        formData.append("grant_type", "password");
        formData.append(
          "scope",
          "openid email phone profile offline_access roles quickapp_api"
        );
        formData.append("username", email);
        formData.append("password", "tempP@ss123");
        formData.append("client_id", "quickapp_spa");

        return {
          url: api.dmtPostToken,
          method: "POST",
          body: formData,
        };
      },
    }),
  }),
});

export const { usePostTokenMutation } = authApi;
