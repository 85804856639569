// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dtms-notify.ap-notifications-static-topHeader.success div {
  background-color: var(--color-background-success);
}
.dtms-notify.ap-notifications-static-topHeader.error div {
  background-color: var(--color-background-danger);
}
.dtms-notify.ap-notifications-static-topHeader.warning div {
  background-color: var(--color-background-warning);
}
.dtms-notify.ap-notifications-static-topHeader.warning div .ap-notification-content .ap-notification-description {
  color: #474747;
}
.dtms-notify.ap-notifications-static-topHeader.warning div .height {
  font-weight: 400;
  color: #474747;
}

.module-note {
  width: 100%;
  margin: 0;
  border-radius: 0;
}
.module-note .ap-notification-content {
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/components/shared/Notification/Notification.scss"],"names":[],"mappings":"AAGE;EACE,iDAAA;AAFJ;AAKE;EACE,gDAAA;AAHJ;AAME;EACE,iDAAA;AAJJ;AAKI;EACE,cAAA;AAHN;AAKI;EACE,gBAAA;EACA,cAAA;AAHN;;AAQA;EACE,WAAA;EACA,SAAA;EACA,gBAAA;AALF;AAME;EACE,uBAAA;AAJJ","sourcesContent":["@import \"../../../variables.scss\";\n\n.dtms-notify.ap-notifications-static-topHeader {\n  &.success div {\n    background-color: var(--color-background-success);\n  }\n\n  &.error div {\n    background-color: var(--color-background-danger);\n  }\n\n  &.warning div {\n    background-color: var(--color-background-warning);\n    .ap-notification-content .ap-notification-description {\n      color: #474747;\n    }\n    .height {\n      font-weight: 400;\n      color: #474747;\n    }\n  }\n}\n\n.module-note {\n  width: 100%;\n  margin: 0;\n  border-radius: 0;\n  .ap-notification-content {\n    justify-content: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
