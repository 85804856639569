export const mbzModuleRoles = {
  admin: "ADMIN",
  nonAdmin: "USER",
};

export const formatUserListForUpdate = (original, modified, mName) => {
  const difference = (arr1, arr2, action) => {
    return arr1
      .filter((obj1) => !arr2.some((obj2) => obj1.email === obj2.email))
      .map((obj) => ({ ...obj, action }));
  };

  const commonElements = () => {
    return modified
      .filter((modifiedItem) =>
        original.some(
          (origItem) =>
            modifiedItem.email === origItem.email &&
            modifiedItem.modules.find((i) => i.name == mName)?.role?.name !==
              origItem.modules.find((i) => i.name == mName)?.role?.name
        )
      )
      .map((obj) => ({ ...obj, action: "update" }));
  };
  const commonUserWithRoleChange = commonElements(original, modified);
  // Find added elements
  const added = difference(modified, original, "add");

  // Find deleted elements
  const deleted = difference(original, modified, "delete");

  // Combine added and deleted elements
  return [...added, ...deleted, ...commonUserWithRoleChange];
};

export const mbzDeleteConfirmMsg = {
  reportUnshared: `Are you sure you want to delete {fileName} file?`,
  reportSharedWithClient: `If you delete {fileName} file, all client users will lose access and will no longer be able to view it. Do you confirm you still want to delete it?`,
  reportSharedWithTP: `If you delete {fileName} file, all third party users will lose access and will no longer be able to view it. Do you confirm you still want to delete it?`,
  reportSharedWithBoth: `If you delete {fileName} file, all client and third party users will lose access and will no longer be able to view it. Do you confirm you still want to delete it?`,
  releaseUnshared: `Are you sure you want to delete {fileName} file?`,
  releaseShared: `{fileName} file is already shared with third party users. If you delete it, all third party users will lose access. Do you still want to delete it?`,
  releaseSigned: `{fileName} file is already shared with and signed by third party users. If you delete it, all third party users will lose access and all the signed copies will also be deleted. Do you confirm you still want to delete it?`,
};
