// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dma-ca-section .dma-ca-search-container {
  max-width: 20rem;
  margin: 0 0 1rem auto;
}
.dma-ca-section .dma-ca-search-container .ap-search-container {
  padding: 0;
}
.dma-ca-section .dma-ca-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  column-gap: 2%;
  row-gap: 1rem;
}
.dma-ca-section .dma-ca-content .dma-ca-noresults {
  margin: 10vh auto;
}`, "",{"version":3,"sources":["webpack://./src/modules/dma/completed-assessment/CompletedAssessment.scss"],"names":[],"mappings":"AACE;EACE,gBAAA;EACA,qBAAA;AAAJ;AACI;EACE,UAAA;AACN;AAEE;EACE,aAAA;EACA,eAAA;EACA,2BAAA;EACA,cAAA;EACA,aAAA;AAAJ;AACI;EACE,iBAAA;AACN","sourcesContent":[".dma-ca-section {\n  .dma-ca-search-container {\n    max-width: 20rem;\n    margin: 0 0 1rem auto;\n    .ap-search-container {\n      padding: 0;\n    }\n  }\n  .dma-ca-content {\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: flex-start;\n    column-gap: 2%;\n    row-gap: 1rem;\n    .dma-ca-noresults {\n      margin: 10vh auto;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
