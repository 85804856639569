import React from "react";
import "./Footer.scss";
const footerLinks = [
  {
    name: "Privacy policy",
    href: "https://www.pwc.de/en/privacy-policy.html",
    target: "_blank",
  },
  {
    name: "Terms of use",
    href: "https://www.pwc.de/en/terms-of-use.html",
    target: "_blank",
  },
  {
    name: "Disclaimer",
    href: "https://www.pwc.de/en/disclaimer.html",
    target: "_blank",
  },
];

export const NewFooter = () => {
  return (
    <div className="footer-container">
      <div className="footer-text">
        © 2017 - 2024 PwC. All rights reserved. PwC refers to the PwC network
        and/or one or more of its member firms, each of which is a separate
        legal entity.
      </div>
      <div className="footer-links-container">
        {footerLinks.map((link, ind) => (
          <span key={ind}>
            {ind !== 0 && <span className="link-seperator"> | </span>}
            <a className="footer-link" href={link.href} target={link.target}>
              {link.name}
            </a>
          </span>
        ))}
      </div>
    </div>
  );
};
