import { forwardRef, useEffect, useState } from "react";
import _ from "lodash";
import {
  _getLoggedInUserDetails,
  _getRedirectUri,
  _getSPAuthUrl,
} from "../../../../envUrl";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../../store/slices/dtms/loading";
import { confirmAlert } from "react-confirm-alert";
import "../SglProject.scss";
import Table from "../../../../components/shared/Table/Table";
import moment from "moment";
import {
  useGetAllSglFilesMutation,
  useSaveSpAuthCodeMutation,
} from "../../../../store/services/sgl/files";
import { useGetProjectDetailsMutation } from "../../../../store/services/sgl/project";
import { setModuleNotification } from "../../../../store/slices/dtms/notification";
import { Loading } from "@appkit4/react-components";

const InlineLoader = () => (
  <div className="sgl-loading-container">
    <Loading loadingType="circular" indeterminate={true} compact={false} />
  </div>
);

const SglProjectFiles = ({
  getProjectName,
  projectDetails,
  getProjectDetails,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const params = useParams();

  const [table, setTable] = useState();
  const [tableData, setTableData] = useState([]);
  const [isLoad, setIsLoad] = useState(false);
  const [getAllFiles, getAllFilesResult] = useGetAllSglFilesMutation();
  const [saveSpCode, saveSpCodeResult] = useSaveSpAuthCodeMutation();

  useEffect(() => {
    dispatch(setLoading(true));
    const spAuthCode = sessionStorage.getItem("spCode");
    if (spAuthCode) {
      const redirectUri = _getRedirectUri() + "stage-gate-letter/";
      saveSpCode({ spAuthCode, redirectUri });
      sessionStorage.removeItem("spCode");
    }

    setTable({
      title: "Sharepoint files list",
      columns: [
        {
          title: "Files",
          field: "sharepointItemName",
          render: (row) => (
            <span
              style={
                row.parentItemId && row.parentItemId !== null
                  ? { paddingLeft: "16px" }
                  : {}
              }
            >
              {row.sharepointItemName}
            </span>
          ),
        },
        {
          title: "Created date",
          field: "sharepointItemCreatedDate",
          render: (row) => (
            <span>
              {moment(row.sharepointItemCreatedDate).format("DD-MM-YYYY")}
            </span>
          ),
        },
      ],
      actions: [
        (rowData) => ({
          icon: forwardRef((props, ref) => (
            <span
              className="Appkit4-icon icon-view-fill ap-font-20"
              {...props}
              ref={ref}
            />
          )),
          tooltip: "View",
          onClick: (event, data) => {
            window.open(data.webUrl, "_blank");
          },
          cellStyle: {
            textAlign: "center",
            justifyContent: "center",
          },
        }),
      ],
      parentChildData: (row, rows) =>
        rows.find((a) => a.sharepointItemId === row.parentItemId),
    });
  }, []);

  useEffect(() => {
    const { isLoading, isError, isSuccess, data, error } = saveSpCodeResult;
    dispatch(setLoading(isLoading));
    if (isSuccess) {
      console.log("Data: ", data.result);
      getProjectDetails(params.projectId);
    } else if (isError) {
      console.log("Error: ", error);
      confirmAlert({
        title: "Error",
        message: "Unable to save Sharepoint auth token",
        buttons: [
          {
            label: "Ok",
          },
        ],
      });
    }
  }, [saveSpCodeResult]);

  useEffect(() => {
    if (projectDetails && projectDetails.projectName) {
      getProjectName(projectDetails.projectName);
      getAllFiles({
        sharePointHomeUrl: projectDetails.sharepointHomeUrl,
        relativePath: projectDetails.folderPath,
      });
    }
  }, [projectDetails]);

  useEffect(() => {
    const { isLoading, isError, isSuccess, data, error } = getAllFilesResult;
    dispatch(setLoading(isLoading));
    if (isSuccess) {
      const files = _.cloneDeep(data.result);
      setTableData(files);
    } else if (isError) {
      if (error.status === 401) {
        setIsLoad(true);
        dispatch(
          setModuleNotification({
            show: true,
            message:
              "You are getting redirected for sharepoint authentication.",
            status: "icon-circle-warning-outline",
          })
        );
        const url = _getSPAuthUrl(params.projectId);
        window.location.href = url;
      } else if (error?.data?.error?.message) {
        confirmAlert({
          title: "Error",
          message: error?.data?.error?.message,
          buttons: [
            {
              label: "Ok",
              onClick: () => {
                navigate(`/stage-gate-letter`);
              },
            },
          ],
        });
      }
    }
  }, [getAllFilesResult]);

  return (
    <div className="mbz-dashboard-table">
      {isLoad && InlineLoader()}
      {!isLoad && (
        <Table
          {...table}
          data={tableData}
          options={{
            filtering: false,
            paging: false,
            sorting: false,
            treeData: true,
          }}
          parentChildData={(row, rows) =>
            rows.find((a) => a.sharepointItemId === row.parentItemId)
          }
        />
      )}
    </div>
  );
};

export default SglProjectFiles;
