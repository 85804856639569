import { createSlice } from "@reduxjs/toolkit";
import { _getLoggedInUserDetails } from "../../../envUrl";
import _ from "lodash";

const dmaTemplateSlice = createSlice({
  name: "dmaTemplate",
  initialState: { templateDetails: {} },
  reducers: {
    setDmaTemplateDetails(state, action) {
      state.templateDetails = action.payload;
    },
  },
});

export const { setDmaTemplateDetails } = dmaTemplateSlice.actions;
export default dmaTemplateSlice.reducer;
