// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dma-app-container {
  display: flex;
}
.dma-app-container .dma-content-container {
  height: calc(100vh - 4rem);
  overflow: auto;
  flex-grow: 1;
}
.dma-app-container .dma-content-container .dma-route-container {
  padding: 1rem;
}
.dma-app-container .dma-content-container .dma-route-container .under-construction {
  text-align: center;
  margin-top: 40vh;
}
.dma-app-container .dma-content-container .dma-route-container .dma-unauth-container {
  margin-top: 35vh;
  text-align: center;
  display: flex;
  font-weight: var(--font-weight-2);
  justify-content: space-evenly;
}

@media screen and (min-width: 1000px) {
  .dma-app-container .dma-content-container .dma-route-container {
    min-height: calc(100vh - 8rem);
  }
}
@media screen and (max-width: 1000px) {
  .dma-app-container .dma-content-container .dma-route-container {
    min-height: calc(100vh - 9rem);
  }
}
@media screen and (max-width: 600px) {
  .dma-app-container .dma-content-container .dma-route-container {
    min-height: calc(100vh - 10rem);
  }
}
@media screen and (max-width: 420px) {
  .dma-app-container .dma-content-container .dma-route-container {
    min-height: calc(100vh - 12rem);
  }
}`, "",{"version":3,"sources":["webpack://./src/modules/dma/DmaApp.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;AAAE;EACE,0BAAA;EACA,cAAA;EACA,YAAA;AAEJ;AADI;EACE,aAAA;AAGN;AAFM;EACE,kBAAA;EACA,gBAAA;AAIR;AAFM;EACE,gBAAA;EACJ,kBAAA;EACA,aAAA;EACA,iCAAA;EACI,6BAAA;AAIR;;AAEA;EAGM;IACE,8BAAA;EADN;AACF;AAMA;EAGM;IACE,8BAAA;EANN;AACF;AAWA;EAGM;IACE,+BAAA;EAXN;AACF;AAgBA;EAGM;IACE,+BAAA;EAhBN;AACF","sourcesContent":[".dma-app-container {\n  display: flex;\n  .dma-content-container {\n    height: calc(100vh - 4rem);\n    overflow: auto;\n    flex-grow: 1;\n    .dma-route-container {\n      padding: 1rem;\n      .under-construction {\n        text-align: center;\n        margin-top: 40vh;\n      }\n      .dma-unauth-container {\n        margin-top: 35vh;\n    text-align: center;\n    display: flex;\n    font-weight: var(--font-weight-2);\n        justify-content: space-evenly;\n      }\n    }\n  }\n}\n\n@media screen and (min-width: 1000px) {\n  .dma-app-container {\n    .dma-content-container {\n      .dma-route-container {\n        min-height: calc(100vh - 8rem);\n      }\n    }\n  }\n}\n\n@media screen and (max-width: 1000px) {\n  .dma-app-container {\n    .dma-content-container {\n      .dma-route-container {\n        min-height: calc(100vh - 9rem);\n      }\n    }\n  }\n}\n\n@media screen and (max-width: 600px) {\n  .dma-app-container {\n    .dma-content-container {\n      .dma-route-container {\n        min-height: calc(100vh - 10rem);\n      }\n    }\n  }\n}\n\n@media screen and (max-width: 420px) {\n  .dma-app-container {\n    .dma-content-container {\n      .dma-route-container {\n        min-height: calc(100vh - 12rem);\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
