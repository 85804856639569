import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Panel } from "@appkit4/react-components";
import _ from "lodash";
import "./ProfileInfo.scss";
import { _getDMATokenDetails } from "../../../envUrl";
import { useGetClientsMutation } from "../../../store/services/dmt/clients";
import { setDmaTemplateDetails } from "../../../store/slices/dmt/template";
import { setLoading } from "../../../store/slices/dtms/loading";
import { confirmAlert } from "react-confirm-alert";
import { useGetDmaTemplatesMutation } from "../../../store/services/dmt/template";
import { setDmaClients } from "../../../store/slices/dmt/clients";

export const ProfileInfo = () => {
  const dispatch = useDispatch();
  const [getClients, getClientsResult] = useGetClientsMutation();
  const [getDMATemplate, getDMATemplateResult] = useGetDmaTemplatesMutation();

  const templateDetails = useSelector(
    (state) => state.dmaTemplate.templateDetails
  );

  const clients = useSelector((state) => state.dmaClients.clients);

  useEffect(() => {
    getClients();
    getDMATemplate();
  }, []);

  useEffect(() => {
    const { data, isSuccess, error, isError, isLoading } = getDMATemplateResult;
    dispatch(setLoading(isLoading));
    if (isSuccess) {
      dispatch(setDmaTemplateDetails(data));
    }
    if (isError) {
      console.log("Oops! ", error);
      dispatch(setLoading(isLoading));
      confirmAlert({
        title: "Error",
        message: "Unable to template details",
        buttons: [
          {
            label: "Ok",
          },
        ],
      });
    }
  }, [dispatch, getDMATemplateResult]);

  useEffect(() => {
    const { data, isSuccess, error, isError, isLoading, isUninitialized } =
      getClientsResult;
    dispatch(setLoading(isLoading));
    if (isSuccess) {
      dispatch(setDmaClients(data));
    }
    if (isError) {
      console.log("Oops! ", error);
      dispatch(setLoading(isLoading));
      confirmAlert({
        title: "Error",
        message: "Unable to fetch clients",
        buttons: [
          {
            label: "Ok",
          },
        ],
      });
    }
  }, [dispatch, getClientsResult]);

  const {
    jobtitle: portfolioCompany,
    email,
    fullname,
    role,
    orgid,
    templid,
  } = useMemo(() => _getDMATokenDetails(), []);

  return (
    <div className="dma-profile-info-container">
      <div className="dma-profile-info-title ap-panel-head">
        <div className="ap-panel-head-title">Profile Information</div>
      </div>
      <Panel className="dma-profile-info-body">
        <div className="dma-profile-info">
          <div className="dma-profile-info-field">Client Organisation</div>
          <div className="dma-profile-info-value">
            {clients.find((c) => c.id == orgid)?.name}
          </div>
        </div>
        <div className="dma-profile-info">
          <div className="dma-profile-info-field">Portfolio Company</div>
          <div className="dma-profile-info-value">{portfolioCompany}</div>
        </div>
        <div className="dma-profile-info">
          <div className="dma-profile-info-field">Industry Sector</div>
          <div className="dma-profile-info-value">
            {templateDetails.industrySector}
          </div>
        </div>
        <div className="dma-profile-info">
          <div className="dma-profile-info-field">Email</div>
          <div className="dma-profile-info-value">{email}</div>
        </div>
        <div className="dma-profile-info">
          <div className="dma-profile-info-field">Role</div>
          <div className="dma-profile-info-value">{role.map((val, ind) => `${ind !== 0 ? ' ': ''}${val}`)}</div>
        </div>
        <div className="dma-profile-info">
          <div className="dma-profile-info-field">Full Name</div>
          <div className="dma-profile-info-value">{fullname}</div>
        </div>
      </Panel>
    </div>
  );
};
