import React, { useEffect, useMemo, useState } from "react";
import { Card, Grid, Zoom } from "@mui/material";
import * as XLSX from "xlsx";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import PDFViewerWHyperlink from "../../../components/shared/CustomPDFViewer/PDFViewerWithCustomHyperlinks/PDFViewerWHyperlink";
import "./SglReport.scss";
import PreviewPDFViewer from "../../../components/shared/CustomPDFViewer/PreviewPDF/PreviewPDFViewer";
import PreviewExcel from "../../../components/shared/CustomExcelViewer/PreviewExcel";
import { useGetProjectDetailsMutation } from "../../../store/services/sgl/project";
import { useGetSglReportStatusMutation } from "../../../store/services/sgl/report";
import _ from "lodash";
import { setLoading } from "../../../store/slices/dtms/loading";
import {
  useGetAllSglFilesMutation,
  useGetFileDownloadMutation,
  useGetXlsxDownloadMutation,
} from "../../../store/services/sgl/files";
import { confirmAlert } from "react-confirm-alert";
import { downloadSglReport } from "../../../store/slices/sgl/reportSlice";
import { NewBreadcrumb } from "../../../components/new-breadcrumb/NewBreadcrumb";
import { Button, ButtonGroup } from "@appkit4/react-components";
import { setModuleNotification } from "../../../store/slices/dtms/notification";

function getLeastYCoordinate(boxCoordinatesString) {
  const coordinates = boxCoordinatesString.replace(/[\(\) ]/g, "").split(",");
  let smallestY = parseFloat(coordinates[1]);

  for (let i = 3; i < coordinates.length; i += 2) {
    const currentY = parseFloat(coordinates[i]);
    if (currentY < smallestY) {
      smallestY = currentY;
    }
  }

  return smallestY;
}

function SglReport() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [isPriview, setIsPreview] = useState(false);
  const [isExcelPreview, setIsExcelPreview] = useState(false);
  const [reportStatus, setReportStatus] = useState({});
  const [projectDetails, setProjectDetails] = useState({});
  const [filesList, setFilesList] = useState([]);
  const [highlightArea, setHighlightArea] = useState([]);
  const [highlightCell, setHighlightCell] = useState({});
  const [previewFile, setPreviewFile] = useState({ name: "", id: "", url: "" });
  const [previewReportUrl, setPreviewReportUrl] = useState("");
  const [excelData, setExcelData] = useState([]);
  const [getProjectDetails, getProjectDetailsResult] =
    useGetProjectDetailsMutation();
  const [getAllFiles, getAllFilesResult] = useGetAllSglFilesMutation();
  const [getReportStatus, getReportStatusResult] =
    useGetSglReportStatusMutation();
  const [getReportFile, getReportFileResult] = useGetFileDownloadMutation();
  const [getPreviewFile, getPreviewFileResult] = useGetFileDownloadMutation();
  const [getXlsxFile, getXlsxFileResult] = useGetXlsxDownloadMutation();

  useEffect(() => {
    dispatch(setLoading(true));
    getProjectDetails(params.projectId);
    getReportStatus(params.projectId);
    dispatch(
      setModuleNotification({
        show: true,
        message:
          "Click on the hyperlinks in the report file for source reference.",
        status: "icon-circle-warning-outline",
      })
    );
  }, []);

  useEffect(() => {
    if (
      projectDetails.sharepointHomeUrl &&
      projectDetails.folderPath &&
      reportStatus.reportWithHyperlinkItemId
    ) {
      getReportFile({
        sharePointHomeUrl: projectDetails.sharepointHomeUrl,
        relativePath: projectDetails.folderPath,
        itemId: reportStatus.reportWithHyperlinkItemId,
        formats: ["PDF"],
      });
    }
  }, [reportStatus, projectDetails]);

  useEffect(() => {
    const { isLoading, isError, isSuccess, data, error } = getReportFileResult;
    dispatch(setLoading(isLoading));
    if (isSuccess) {
      const url = data.result?.[reportStatus.reportWithHyperlinkItemId];
      if (url?.pdfContent) setPreviewReportUrl(url?.pdfContent || "");
      else
        confirmAlert({
          title: "Error",
          message: "Unable to fetch report preview file.",
          buttons: [
            {
              label: "Ok",
            },
          ],
        });
    } else if (isError) {
      confirmAlert({
        title: "Error",
        message: "Unable to fetch report preview file.",
        buttons: [
          {
            label: "Ok",
          },
        ],
      });
    }
  }, [getReportFileResult]);

  useEffect(() => {
    const { isLoading, isError, isSuccess, data, error } = getPreviewFileResult;
    dispatch(setLoading(isLoading));
    if (isSuccess) {
      if (previewFile.id) {
        const url = data.result?.[previewFile.id];
        if (url?.pdfContent) {
          setPreviewFile({ ...previewFile, url: url.pdfContent });
        } else {
          confirmAlert({
            title: "Error",
            message: "Unable to fetch preview file. Please Try Again.",
            buttons: [
              {
                label: "Ok",
              },
            ],
          });
        }
      }
    } else if (isError) {
      onPreviewClose();
      confirmAlert({
        title: "Error",
        message: "Unable to fetch preview file. Please Try Again.",
        buttons: [
          {
            label: "Ok",
          },
        ],
      });
    }
  }, [getPreviewFileResult]);

  useEffect(() => {
    const { isLoading, isError, isSuccess, data, error } = getXlsxFileResult;
    dispatch(setLoading(isLoading));
    if (isSuccess) {
      if (previewFile.id) {
        const url = data.result?.[previewFile.id];
        if (url?.fileContent) {
          getExcelData(url.fileContent);
        } else {
          confirmAlert({
            title: "Error",
            message: "Unable to fetch preview file. Please Try Again.",
            buttons: [
              {
                label: "Ok",
              },
            ],
          });
        }
      }
    } else if (isError) {
      onPreviewClose();
      confirmAlert({
        title: "Error",
        message: "Unable to fetch preview file. Please Try Again.",
        buttons: [
          {
            label: "Ok",
          },
        ],
      });
    }
  }, [getXlsxFileResult]);

  useEffect(() => {
    const { isLoading, isError, isSuccess, data, error } =
      getProjectDetailsResult;
    dispatch(setLoading(isLoading));
    if (isSuccess) {
      setProjectDetails(data.result);
      getAllFiles({
        sharePointHomeUrl: data.result.sharepointHomeUrl,
        relativePath: data.result.folderPath,
      });
    } else if (isError) {
      confirmAlert({
        title: "Error",
        message: "Unable to fetch project details.",
        buttons: [
          {
            label: "Ok",
          },
        ],
      });
    }
  }, [getProjectDetailsResult]);

  useEffect(() => {
    const { isLoading, isError, isSuccess, data, error } = getAllFilesResult;
    dispatch(setLoading(isLoading));
    if (isSuccess) {
      const files = _.cloneDeep(data.result);
      setFilesList(files);
    } else if (isError) {
      onPreviewClose();
      confirmAlert({
        title: "Error",
        message: "Unable to fetch preview file. Please Try Again.",
        buttons: [
          {
            label: "Ok",
          },
        ],
      });
    }
  }, [getAllFilesResult]);

  useEffect(() => {
    const { isLoading, isError, isSuccess, data, error } =
      getReportStatusResult;
    dispatch(setLoading(isLoading));
    if (isSuccess) {
      setReportStatus(data.result);
    } else if (isError) {
      console.log("Error: ", error);
      confirmAlert({
        title: "Error",
        message: "Unable to fetch report file.",
        buttons: [
          {
            label: "Ok",
          },
        ],
      });
    }
  }, [getReportStatusResult]);

  const getExcelData = (base64String) => {
    const wb = XLSX.read(base64String, { type: "base64" });
    const wsname = wb.SheetNames[0];
    const ws = wb.Sheets[wsname];
    const data = XLSX.utils.sheet_to_json(ws, { raw: false, header: 1 });
    setExcelData(data);
  };

  const fetchPreviewFile = (fileName, fileId) => {
    if (fileName && fileId) {
      setPreviewFile({ name: fileName, id: fileId, url: "" });
      if (!fileName.includes(".xls")) {
        getPreviewFile({
          sharePointHomeUrl: projectDetails.sharepointHomeUrl,
          relativePath: projectDetails.folderPath,
          itemId: fileId,
          formats: ["PDF"],
        });
      } else {
        getXlsxFile({
          sharePointHomeUrl: projectDetails.sharepointHomeUrl,
          relativePath: projectDetails.folderPath,
          itemId: fileId,
          formats: ["ORIGINAL"],
        });
      }
    } else {
      onPreviewClose();
      confirmAlert({
        title: "Error",
        message: "Unable to fetch preview file. Please Try Again",
        buttons: [
          {
            label: "Ok",
          },
        ],
      });
      setPreviewFile({ name: "", id: "", url: "" });
      setIsPreview(false);
    }
  };

  const onClickHyperlink = (href) => {
    const url = new URL(href);
    const queryParams = new URLSearchParams(url.search);
    const fileName = queryParams.get("filename");
    const fileId = queryParams.get("itemId");
    setPreviewFile({ name: "", id: "", url: "" });
    setExcelData([]);
    fetchPreviewFile(fileName, fileId);

    if (fileName.includes(".xls")) {
      setIsExcelPreview(true);
      const rowNum = queryParams.get("rowNumber");
      const colNum = queryParams.get("columnNumber");

      setHighlightCell({
        row: parseInt(rowNum) + 1,
        col: parseInt(colNum),
      });
    } else {
      setIsExcelPreview(false);
      const pageNumber = queryParams.get("pageNumber");
      const boundingBox = queryParams.get("boundingBox");
      const pageWidth = queryParams.get("pageWidth");
      const pageHeight = queryParams.get("pageHeight");

      const area = {
        pageIndex: pageNumber - 1,
        top: (getLeastYCoordinate(boundingBox) / pageHeight) * 100 - 0.6,
        left: 0,
        width: 100,
        height: 4,
      };
      setHighlightArea([area]);
    }
    setIsPreview(true);
  };

  const onPreviewClose = () => {
    setIsPreview(false);
    setIsExcelPreview(false);
    setHighlightCell({});
    setHighlightArea([]);
    setPreviewFile({ name: "", id: "", url: "" });
    setExcelData([]);
  };
  const breadcrumbs = useMemo(() => {
    const links = [
      {
        name: "",
        prefix: "home",
        path: "-2",
      },
    ];
    if (projectDetails.projectName) {
      links.push(
        {
          name: projectDetails.projectName,
          path: "-1",
        },
        {
          name: "Report",
        }
      );
    }
    return links;
  }, [projectDetails.projectName]);

  return (
    <div className="mbz-report-container">
      <div
        style={{
          display: "flex",
          marginBottom: "10px",
          justifyContent: "space-between",
        }}
      >
        <NewBreadcrumb breadcrumbs={breadcrumbs} />
        {/* <Alert className="status-alert" size="small" severity="info">
          Click on the hyperlink in the report file for source reference
        </Alert> */}
        <ButtonGroup direction="horizontal" alignment="ltr" disabled={false}>
          <Button
            className="icon-prefix-button"
            onClick={() => {
              let name = "Stage_Gate_Letter- Report.pdf";
              if (reportStatus?.reportName) {
                name = reportStatus?.reportName.split(".")[0] + ".pdf";
              }
              dispatch(
                downloadSglReport({
                  spUrl: projectDetails.sharepointHomeUrl,
                  path: projectDetails.folderPath,
                  fileId: reportStatus.reportItemId,
                  name,
                  formats: ["PDF"],
                })
              );
            }}
          >
            <span className="Appkit4-icon icon-download-outline" />
            PDF
          </Button>
          <Button
            className="icon-prefix-button"
            onClick={() => {
              let name = "Stage_Gate_Letter- Report.docx";
              if (reportStatus?.reportName) {
                name = reportStatus?.reportName;
              }
              dispatch(
                downloadSglReport({
                  spUrl: projectDetails.sharepointHomeUrl,
                  path: projectDetails.folderPath,
                  fileId: reportStatus.reportItemId,
                  name,
                  formats: ["ORIGINAL"],
                })
              );
            }}
          >
            <span className="Appkit4-icon icon-download-outline" />
            DOCX
          </Button>
        </ButtonGroup>
      </div>
      <Grid
        container
        spacing={2}
        justifyContent={isPriview ? "space-between" : "center"}
        alignItems={"flex-start"}
      >
        <Grid item md={isPriview ? 6 : 10}>
          <Card className={"preview-card customScrollbar"}>
            <PDFViewerWHyperlink
              fileUrl={previewReportUrl || ""}
              onClickHyperlink={onClickHyperlink}
              zoomToScale={"PageWidth"}
              changeZoom={isPriview}
              fileName={reportStatus?.reportName || "Report Preview"}
            />
          </Card>
        </Grid>
        {isPriview && (
          <Grid item md={6}>
            <Zoom in={isPriview} timeout={400}>
              {!isExcelPreview ? (
                <Card className="preview-card" id={"preview-pdf-viewer"}>
                  {previewFile.url && (
                    <PreviewPDFViewer
                      fileUrl={previewFile?.url || ""}
                      onCloseClick={onPreviewClose}
                      changeZoom={isPriview}
                      highlightArea={highlightArea}
                      fileName={previewFile?.name || ""}
                    />
                  )}
                </Card>
              ) : (
                <Card className="preview-card-excel">
                  {excelData.length > 0 && (
                    <PreviewExcel
                      highlightCell={highlightCell}
                      fileName={previewFile?.name || ""}
                      data={excelData}
                      onCloseClick={onPreviewClose}
                    />
                  )}
                </Card>
              )}
            </Zoom>
          </Grid>
        )}
      </Grid>
    </div>
  );
}

export default SglReport;
