import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { usePromiseTracker } from "react-promise-tracker";
import { Loading } from "@appkit4/react-components";
import Backdrop from "@mui/material/Backdrop";
import "./Loader.scss";

const Loader = () => {
  const { promiseInProgress } = usePromiseTracker();
  const isLoading = useSelector((state) => state.loading.isLoading);

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);
  return (
    (loading || promiseInProgress) && (
      <Backdrop
        sx={{
          zIndex: 2999,
          backgroundColor: "rgba(0, 0, 0, 0.3)",
        }}
        open={true}
      >
        <div className="sgl-loading-container">
          <Loading
            loadingType="circular"
            indeterminate={true}
            compact={false}
            className="sgl-loader"
          />
        </div>
      </Backdrop>
    )
  );
};

export default Loader;
