import { Slider } from "@appkit4/react-components";
import { useMemo } from "react";
import "./CustomSlider.scss";

const SliderMessageBox = ({ rangeValue, label }) => {
  return (
    <div
      className="dma-custom-slider-message-box"
      style={{
        left: `calc(${rangeValue * 2}% - ${2 + (rangeValue * 2) / 100}rem)`,
      }}
    >
      {label}
      <div className="message-box-bottom-square"></div>
    </div>
  );
};

export const CustomSlider = ({ options, value, onValueChange, isDisabled }) => {
  const [currentInterval, targetInterval] = useMemo(
    () => [(value?.current || 0) * 10, (value?.target || 0) * 10],
    [value]
  );

  return (
    <div className="dma-custom-slider-container">
      <SliderMessageBox rangeValue={currentInterval} label="Current" />
      <SliderMessageBox rangeValue={targetInterval} label="Target" />
      {value && (
        <>
          <Slider
            sliderId="targetIntervalsSlider"
            className="dma-custom-slider dma-slider-target-slider"
            disabled={isDisabled}
            value={targetInterval}
            min={0}
            max={50}
            step={1}
            onValueChange={(i) =>
              onValueChange({ target: i / 10, current: value?.current || 0 })
            }
          ></Slider>
          <Slider
            sliderId="currentIntervalsSlider"
            className="dma-custom-slider dma-slider-current-slider"
            disabled={isDisabled}
            value={currentInterval}
            min={0}
            max={50}
            step={1}
            onValueChange={(i) =>
              onValueChange({ current: i / 10, target: value?.target || 0 })
            }
          ></Slider>
        </>
      )}
      <div className="dma-customslider-divider-container">
        <div className="dma-customslider-divider"></div>
        {options?.map((option, ind) => (
          <div className="dma-customslider-divider" key={ind}></div>
        ))}
      </div>
      <div className="custom-slider-options-text-container">
        {options?.map((option) => (
          <div className="custom-slider-options-text" key={option.label}>{option.label}</div>
        ))}
      </div>
    </div>
  );
};
