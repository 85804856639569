import { Panel, TextArea } from "@appkit4/react-components";
import "./Question.scss";
import { QuestionOptions } from "../question-options/QuestionOptions";

export const Questions = ({ questions, onSelectOption, disableEdit, subDimensionIndex }) => {
  return (
    <Panel className="questions-list-section-questions-container">
      {questions?.map((question, ind) => (
        <div className="sub-lever-question" key={question.questiontext}>
          <div className="sub-lever-question-text">
            <div>{ind + 1}.</div>
            <div>{question.questiontext}</div>
          </div>
          <div className="sub-lever-question-options">
            <QuestionOptions
              question={question}
              options={question.optionsList}
              type={question.questionCategory}
              disableEdit={disableEdit}
              currentVal={question.responseValue}
              onSelect={(value) => onSelectOption({ value, type: question.questionCategory, subDimensionIndex, questionIndex: ind })}
            />
          </div>
          <div className="sub-lever-question-textarea">
            <TextArea
              title={"Rationale of the answer"}
              hideTitleOnInput
              maxLength={420}
              // disabled={disableEdit}
              value={question.other}
              onChange={(value) => {!disableEdit && onSelectOption({
                value,
                subDimensionIndex, questionIndex: ind ,
                type: 'textarea'
              })}}
            ></TextArea>
          </div>
        </div>
      ))}
    </Panel>
  );
};
