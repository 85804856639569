import { useState, useEffect } from "react";
import { Typography, Button, Box } from "@mui/material";

import "./Auth.scss";
import { emailPattern } from "../../utils/constants";
import _ from "lodash";
import { _getLoginUrl } from "../../envUrl";
import { useSelfInviteUserMutation } from "../../store/services/dtms/user";
import { confirmAlert } from "react-confirm-alert";
import { useGetPublicModulesMutation } from "../../store/services/dtms/module";
import { useDispatch } from "react-redux";
import { setLoading } from "../../store/slices/dtms/loading";
import useDidMountEffect from "../shared/CustomHooks/didMountEffect";
import { Link, useParams } from "react-router-dom";

const LandingPage = ({ userLogin = () => {} }) => {
  const dispatch = useDispatch();
  const params = useParams();

  const [getPublicModules, getPublicModulesResult] =
    useGetPublicModulesMutation();
  const [selfInviteUser, selfInviteUserResult] = useSelfInviteUserMutation();

  const [selectedModules, setSelectedModules] = useState([]);
  const [userEmail, setUserEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [moduleError, setModuleError] = useState("");
  const [modules, setModules] = useState([]);

  console.log(params);

  useEffect(() => {
    dispatch(setLoading(true));
    getPublicModules();
  }, []);

  useDidMountEffect(() => {
    const { isLoading, isError, isSuccess, data, error } =
      getPublicModulesResult;
    if (isSuccess) {
      console.log("Yay! ", data);
      if (data.success) {
        setModules(data.result);
      }
    }
    if (isError) {
      console.log("Oops! ", error);
      confirmAlert({
        title: "Error",
        message: "Unable to fetch non-restricted modules",
        buttons: [
          {
            label: "Ok",
          },
        ],
      });
    }
    dispatch(setLoading(isLoading));
  }, [getPublicModulesResult]);

  useDidMountEffect(() => {
    const { isLoading, isError, isSuccess, data, error } = selfInviteUserResult;
    if (isSuccess) {
      console.log("Yay! ", data);
      confirmAlert({
        title: "Success",
        message: "Invitation sent successfully!",
        buttons: [
          {
            label: "Ok",
          },
        ],
      });
    }

    if (isError) {
      console.log("Oops! ", error);
      const errorMessage =
        error.data && error.data.error
          ? error.data.error.message
            ? error.data.error.message
            : "Access request failed"
          : "Access request failed";
      confirmAlert({
        title: "Error",
        message: errorMessage,
        buttons: [
          {
            label: "Ok",
          },
        ],
      });
    }
    dispatch(setLoading(isLoading));
  }, [selfInviteUserResult]);

  const handleModuleChange = (event) => {
    const {
      target: { value },
    } = event;
    const selectedModulesValue =
      typeof value === "string" ? value.split(",") : value;

    if (!_.isEmpty(selectedModulesValue) && selectedModulesValue.length > 0) {
      setModuleError("");
    }
    setSelectedModules(selectedModulesValue);
  };

  const handleEmailChange = (event) => {
    const emailValue = event.target.value;
    if (emailValue.trim() !== "" && emailPattern.test(emailValue)) {
      setEmailError("");
    }
    setUserEmail(emailValue);
  };

  const handleGetAccess = () => {
    var isEmailValid = false;
    var isModuleValid = false;
    if (userEmail.trim() === "") {
      setEmailError("Please enter your email id");
    } else if (!emailPattern.test(userEmail)) {
      setEmailError("Please enter a valid email id");
    } else {
      isEmailValid = true;
      setEmailError("");
    }
    if (_.isEmpty(selectedModules) || selectedModules.length === 0) {
      setModuleError("Please select atleast one module");
    } else {
      isModuleValid = true;
      setModuleError("");
    }
    if (isEmailValid && isModuleValid) {
      let modules = [];
      selectedModules.forEach((module) =>
        modules.push({
          name: module,
          role: {
            id: 0,
            name: "",
            isActive: "Y",
          },
        })
      );
      selfInviteUser({
        email: userEmail,
        modules: modules,
      });
      setUserEmail("");
      setSelectedModules([]);
    }
  };

  const handleLogin = () => {
    userLogin();
  };

  return (
    <div className="landing-page">
      <div className="pwc-logo">
        <div className="a-pwc-full-logo-grid a-md" />
      </div>
      <Box className="login-container">
        <div className="dtms-description">
          <Typography variant="h5">
            <strong>Deals Technology Managed Services</strong> is a one stop
            shop for all your clients' needs.
          </Typography>
        </div>
        <Typography variant="h6" className="having-back">
          It's nice to have you back!
        </Typography>
        <Button
          variant="contained"
          className="get-access-btn"
          onClick={handleLogin}
          fullWidth
        >
          Login
        </Button>
        <p className="request-access">
          Request access{" "}
          <a
            href="https://forms.office.com/r/fRqtGjTVBm"
            target="_blank"
            className="click-here" rel="noreferrer"
          >
            <strong>here</strong>
          </a>
        </p>
      </Box>
    </div>
  );
};

export default LandingPage;
