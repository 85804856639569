import React, { useEffect, useState } from "react";
import { Modal, Radio, RadioGroup } from "@appkit4/react-components";
import "./PreferenceModal.scss";

const themeList = [
  {
    value: "blue",
    color: "#415385",
  },
  {
    value: "orange",
    color: "#D04A02",
  },
  {
    value: "teal",
    color: "#26776D",
  },
  {
    value: "pink",
    color: "#D93954",
  },
  {
    value: "red",
    color: "#E0301E",
  },
  {
    value: "black",
    color: "#2D2D2D",
    darkModeColor: "#ffff",
    spanColor: "#2D2D2D",
  },
];
const modeList = [
  {
    label: "Light",
    value: "light",
  },
  {
    label: "Dark",
    value: "dark",
  },
];

export const PreferenceModal = ({ onClose, isVisible }) => {
  const [currentTheme, setCurrentTheme] = useState(
    localStorage.getItem("currenttheme") || "blue"
  );
  const [currentMode, setCurrentMode] = useState(
    localStorage.getItem("currentmode") || "light"
  );
  useEffect(() => {
    localStorage.setItem("currenttheme", currentTheme);
    document.body.setAttribute("data-theme", currentTheme);
  }, [currentTheme]);
  useEffect(() => {
    localStorage.setItem("currentmode", currentMode);
    document.body.setAttribute("data-mode", currentMode);
  }, [currentMode]);
  return (
    <Modal
      title="Preference"
      visible={isVisible}
      modalStyle={{ width: "33.75rem", minHeight: "20rem" }}
      className="dtms-preference-modal"
      blockScroll
      closeOnPressEscape
      maskBodyPadding={false}
      onCancel={() => {
        console.log("cancle clicked");
        onClose(false);
      }}
    >
      <div className="preference-section-title">Theme</div>
      <div className="preference-list-inline">
        {themeList.map((theme, ind) => (
          <div
            key={ind}
            className="theme-color-circle"
            role="button"
            tabIndex={0}
            style={{
              backgroundColor:
                currentMode === "light"
                  ? theme.color
                  : theme.darkModeColor || theme.color,
            }}
            onKeyDown={(event) => {
              event.key === "Enter" && setCurrentTheme(theme.value);
            }}
            onClick={() => setCurrentTheme(theme.value)}
          >
            {currentTheme === theme.value && (
              <span
                className="Appkit4-icon icon-check-mark-outline"
                style={{
                  color:
                    currentMode === "light"
                      ? "inherit"
                      : theme.spanColor || "inherit",
                }}
              ></span>
            )}
          </div>
        ))}
      </div>
      <div className="preference-section-title">Mode</div>
      <RadioGroup
        defaultValue={currentMode}
        name="preferenceMode"
        onChange={setCurrentMode}
      >
        {modeList.map((mode) => (
          <Radio key={mode.value} value={mode.value}>
            {mode.label}
          </Radio>
        ))}
      </RadioGroup>
    </Modal>
  );
};
