import { useSelector } from "react-redux";
import _ from "lodash";
import { AssessmentInfo } from "./components/assessment-info/AssessmentInfo";
import "./Assessment.scss";
import { PrivacyModal } from "./components/privacy-modal/PrivacyModal";
import { useCallback, useEffect, useState } from "react";
import { QuestionsList } from "./components/questions-list/QuestionsList";
import { EditQuantitative } from "./components/edit-qunatitative/EditQuantitative";
import { convertStringToJson } from "../../../utils/utilFns";
import { useSaveAssessmentMutation } from "../../../store/services/dmt/assessment";
import { setLoading } from "../../../store/slices/dtms/loading";
import {
  setDmaAssessmentDetails,
  setIsAssessmentActive,
} from "../../../store/slices/dmt/assessment";
import { useDispatch } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import { _getDMATokenDetails } from "../../../envUrl";

export const Assessment = () => {
  const dispatch = useDispatch();
  const [showPrivacyModal, setShowPrivacyModal] = useState(false);
  const [isNewAssessment, setIsNewAssessment] = useState(true);
  const [showEditQuantitative, setShowEditQuantitative] = useState(false);

  // const [isAssessmentActive, setIsAssessmentActive] = useState(false);
  const [saveAssessment, saveAssessmentResult] = useSaveAssessmentMutation();
  // const [saveEditedQuantitative, saveEditedQuantitativeResult] =
  //   useSaveEditedQuantitativeMutation();
  const assessmentDetails = useSelector(
    (state) => state.dmaAssessment.assessmentDetails
  );
  const isAssessmentActive = useSelector(
    (state) => state.dmaAssessment.isAssessmentActive
  );
  const templateDetails = useSelector(
    (state) => state.dmaTemplate.templateDetails
  );

  useEffect(() => {}, []);

  useEffect(() => {
    if (assessmentDetails.hasOwnProperty("id")) {
      setIsNewAssessment(!Boolean(assessmentDetails.id));
    }
  }, [assessmentDetails]);

  const onProceed = useCallback(() => {
    if (!assessmentDetails?.isAcceptedPrivacy) setShowPrivacyModal(true);
    else dispatch(setIsAssessmentActive(true));
  }, [assessmentDetails?.isAcceptedPrivacy, dispatch]);

  const createNewAssessment = useCallback(
    (additionalDetails, setDefault = false) => {
      const qStructure = convertStringToJson(
        templateDetails.questionStructure || ""
      );
      const orgid = _getDMATokenDetails("orgid");
      const templid = _getDMATokenDetails("templid");
      const plStructure = templateDetails.plStructure;
      const assumptionStructure = templateDetails.assumptionStructure;
      let userResponses = [];
      if (Array.isArray(qStructure)) {
        userResponses = qStructure
          .filter((val) => val.isChecked)
          .map((val) => {
            if (setDefault)
              return {
                ...val,
                response:
                  (val.benchmarkValue *
                    (additionalDetails?.defaultScore ||
                      assessmentDetails?.defaultScore ||
                      templateDetails.defaultScore)) /
                  100,
              };
            return val;
          });
      }
      const payload = {
        ...assessmentDetails,
        userResponses: JSON.stringify(userResponses),
        plStructure,
        assumptionStructure,
        isAcceptedPrivacy: true,
        orgId: orgid,
        defaultScore: templateDetails.defaultScore,
        templId: templid,
        sector: templateDetails.industrySector,
        ...(additionalDetails || {}),
      };
      return saveAssessment(payload);
    },
    [
      assessmentDetails,
      saveAssessment,
      templateDetails.assumptionStructure,
      templateDetails.defaultScore,
      templateDetails.industrySector,
      templateDetails.plStructure,
      templateDetails.questionStructure,
    ]
  );

  const onAcceptPrivacy = useCallback(() => {
    if (isNewAssessment) {
      createNewAssessment();
    } else {
      saveAssessment({ ...assessmentDetails, isAcceptedPrivacy: true });
    }
    setShowPrivacyModal(false);
    dispatch(setIsAssessmentActive(true));
    setIsNewAssessment(false);
  }, [
    assessmentDetails,
    createNewAssessment,
    dispatch,
    isNewAssessment,
    saveAssessment,
  ]);

  useEffect(() => {
    const { data, isSuccess, error, isError, isLoading } = saveAssessmentResult;
    dispatch(setLoading(isLoading));
    if (isSuccess) {
      dispatch(setDmaAssessmentDetails(data));
    }
    if (isError) {
      console.log("Oops! ", error);
      dispatch(setLoading(isLoading));
      confirmAlert({
        title: "Error",
        message: "Unable to save assessment details",
        buttons: [
          {
            label: "Ok",
          },
        ],
      });
    }
  }, [dispatch, saveAssessmentResult]);

  // useEffect(() => {
  //   const { data, isSuccess, error, isError, isLoading } =
  //     saveEditedQuantitativeResult;
  //   dispatch(setLoading(isLoading));
  //   if (isSuccess) {
  //     dispatch(setDmaAssessmentDetails(data));
  //   }
  //   if (isError) {
  //     console.log("Oops! ", error);
  //     confirmAlert({
  //       title: "Error",
  //       message: "Unable to save assessment details",
  //       buttons: [
  //         {
  //           label: "Ok",
  //         },
  //       ],
  //     });
  //   }
  // }, [dispatch, saveEditedQuantitativeResult]);

  const onEditQuantitativeClick = useCallback(() => {
    setShowEditQuantitative(true);
  }, []);

  const onEditQuantitativeClose = useCallback(() => {
    setShowEditQuantitative(false);
  }, []);

  const onSaveIA = useCallback(
    (asmt) => {
      if (isNewAssessment)
        createNewAssessment({
          assumptionStructure: asmt.assumptionStructure,
          isAcceptedPrivacy: false,
        });
      else createNewAssessment(asmt);
      onEditQuantitativeClose();
      onProceed();
    },
    [createNewAssessment, isNewAssessment, onEditQuantitativeClose, onProceed]
  );
  const onCancelPrivacy = useCallback(() => {
    setShowPrivacyModal(false);
  }, []);

  const onSavePL = useCallback(
    (asmt) => {
      if (isNewAssessment)
        createNewAssessment({
          plStructure: asmt.plStructure,
          isAcceptedPrivacy: false,
        });
      else createNewAssessment(asmt);
    },
    [isNewAssessment, createNewAssessment]
  );

  const onSetDefaultClick = useCallback(() => {
    if (isNewAssessment) {
      createNewAssessment(
        {
          isAcceptedPrivacy: false,
        },
        true
      ).then(() => {
        onProceed();
        setQuestionListView("report");
      });
    } else {
      const userResponses = JSON.parse(
        assessmentDetails.userResponses || templateDetails.questionStructure
      );
      saveAssessment({
        ...assessmentDetails,
        userResponses: JSON.stringify(
          userResponses?.map((val) => ({
            ...val,
            response:
              (val.benchmarkValue *
                (assessmentDetails.defaultScore ||
                  templateDetails.defaultScore)) /
              100,
          }))
        ),
      }).then(() => {
        setQuestionListView("report");
        onProceed();
      });
    }
  }, [
    isNewAssessment,
    onProceed,
    createNewAssessment,
    assessmentDetails,
    templateDetails.questionStructure,
    templateDetails.defaultScore,
    saveAssessment,
  ]);
  const [questionListView, setQuestionListView] = useState("question");
  return (
    <div className="dma-assessment-container">
      {!assessmentDetails.isAcceptedPrivacy && showPrivacyModal && (
        <PrivacyModal
          onAcceptPrivacy={onAcceptPrivacy}
          onCancelPrivacy={onCancelPrivacy}
        />
      )}
      {showEditQuantitative && (
        <EditQuantitative
          onClose={onEditQuantitativeClose}
          onSavePL={onSavePL}
          onSaveIA={onSaveIA}
          isNewAssessment={isNewAssessment}
        />
      )}
      {isAssessmentActive ? (
        <QuestionsList questionListView={questionListView} />
      ) : (
        <AssessmentInfo
          onProceed={onProceed}
          isNewAssessment={isNewAssessment}
          onEditQuantitativeClick={onEditQuantitativeClick}
          onSetDefaultClick={onSetDefaultClick}
        />
      )}
    </div>
  );
};
