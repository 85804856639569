import React from "react";
import "./SglDashboard.scss";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import { Button } from "@appkit4/react-components/button";
import { AvatarGroup, Avatar } from "@appkit4/react-components/avatar";
import _ from "lodash";
import { ListItem, Tooltip } from "@appkit4/react-components";

const maxUserLength = 4;

const ProjectCard = ({
  projectDetails,
  isAdmin,
  onDelete,
  onEdit,
  className,
}) => {
  const navigate = useNavigate();

  const openDeleteModal = (e) => {
    e.stopPropagation();
    onDelete({
      projectId: projectDetails.projectId,
      projectName: projectDetails.projectName,
    });
  };

  const openEditModal = (e) => {
    e.stopPropagation();
    onEdit(projectDetails);
  };

  const cardClickHandler = () => {
    navigate(`${projectDetails.projectId}`);
  };

  const creatorName = projectDetails?.inviteUserRequestList?.find(
    (user) => user.isCreator
  )?.firstName;

  const renderUsers = () => {
    const clonedProjDts = _.cloneDeep(projectDetails);
    const otherUsers = [];
    const firstSixUsers = clonedProjDts.inviteUserRequestList
      .filter((user, i) => {
        const other =
          user.firstName && user.lastName
            ? `${user.firstName} ${user.lastName}`
            : user.email;
        if (i > maxUserLength - 1) {
          otherUsers.push(other);
        }
        return i < maxUserLength;
      })
      .map((user, i) => {
        const name =
          user.firstName && user.lastName
            ? `${user.firstName} ${user.lastName}`
            : user.email;
        let initials = "";
        if (user.firstName && user.lastName) {
          initials = `${user.firstName[0]}${user.lastName[0]}`;
        } else {
          const [localPart] = user?.email?.split("@");
          initials =
            localPart.length > 1
              ? localPart.slice(0, 2)
              : localPart.slice(0, 1);
        }

        return (
          <Tooltip
            trigger="hover"
            position="top"
            distance={4}
            id="tooltipDesc"
            appendAfterTarget={true}
            mouseEnterDelay={500}
            content={name}
          >
            <Avatar
              label={initials}
              className={i === 0 ? "" : "mask"}
              style={{ marginLeft: "-4px" }}
            />
          </Tooltip>
        );
      });

    if (clonedProjDts.inviteUserRequestList.length < maxUserLength + 1) {
      return [...firstSixUsers];
    } else {
      const extraUsers = (
        <Tooltip
          trigger="hover"
          position="top"
          distance={4}
          id="tooltipDesc"
          appendAfterTarget={true}
          mouseEnterDelay={500}
          content={otherUsers.join(", ")}
        >
          <Avatar
            label={`+${
              clonedProjDts.inviteUserRequestList.length - maxUserLength
            }`}
            style={{ color: "rgb(71, 71, 71)", marginLeft: "-4px" }}
            bgColor="rgb(243, 243, 243)"
            className="mask"
          />
        </Tooltip>
      );
      return [...firstSixUsers, extraUsers];
    }
  };

  const onSharepointIconClick = (event) => {
    event.stopPropagation();
    window.open(
      projectDetails?.sharepointHomeUrl,
      "_blank",
      "noopener, noreferrer"
    );
  };

  const onFolderPathClick = (event) => {
    event.stopPropagation();
    window.open(
      `${projectDetails?.sharepointHomeUrl}/Shared ${projectDetails.folderPath}`,
      "_blank",
      "noopener, noreferrer"
    );
  };

  return (
    <div
      className={classNames("ap-pattern-card", className)}
      role="link"
      tabIndex={0}
      onClick={cardClickHandler}
    >
      <div className="ap-pattern-card-description">
        <div className="ap-pattern-card-head">
          <Tooltip
            trigger="hover"
            position="top"
            distance={4}
            id="tooltipDesc"
            mouseEnterDelay={500}
            appendAfterTarget={true}
            content={projectDetails?.projectName}
          >
            <span className="ap-pattern-card-name">
              {projectDetails?.projectName}
            </span>
          </Tooltip>
          <div className="icon-flex">
            <Tooltip
              trigger="hover"
              position="top"
              distance={4}
              id="tooltipDesc"
              appendAfterTarget={true}
              mouseEnterDelay={500}
              content="Edit project"
            >
              <ListItem
                kind="text"
                className="ap-notification-icon-close"
                onClick={openEditModal}
              >
                <span className="Appkit4-icon icon-edit-outline ap-font-16 " />
              </ListItem>
            </Tooltip>
            <Tooltip
              trigger="hover"
              position="top"
              distance={4}
              id="tooltipDesc"
              mouseEnterDelay={500}
              appendAfterTarget={true}
              content="Close project"
            >
              <ListItem
                kind="text"
                className="ap-notification-icon-close"
                onClick={openDeleteModal}
              >
                <span className="Appkit4-icon icon-delete-outline ap-font-16" />
              </ListItem>
            </Tooltip>
          </div>
        </div>
        <div className="desc-flex">
          <Tooltip
            trigger="hover"
            position="top"
            distance={4}
            mouseEnterDelay={500}
            id="tooltipDesc"
            appendAfterTarget={true}
            content={projectDetails?.sharepointHomeUrl}
          >
            <span
              className="a-pwc-logo-grid a-md"
              onClick={onSharepointIconClick}
            />
          </Tooltip>
          <Tooltip
            trigger="hover"
            position="top"
            distance={4}
            mouseEnterDelay={500}
            id="tooltipDesc"
            appendAfterTarget={true}
            content={projectDetails?.folderPath}
          >
            <p className="ap-pattern-card-desc" onClick={onFolderPathClick}>
              {projectDetails?.folderPath?.split("/").at(-1)}
            </p>
          </Tooltip>
        </div>
      </div>
      <div className="divider" />
      <div className="card-pattern-footer">
        <span className="component-footer-date">
          <AvatarGroup withMask>{renderUsers()}</AvatarGroup>
        </span>
      </div>
    </div>
  );
};

export default ProjectCard;
