import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import {
  _getAuthHeaders,
  _getClientID,
  _getDTMSProxy,
  _getEncoderKey,
  _getLoggedInUserDetails,
} from "../../../envUrl";
import api from "../../api";
import _ from "lodash";

export const sglProjectsApi = createApi({
  reducerPath: "sglProjectsApi",
  baseQuery: fetchBaseQuery({ baseUrl: _getDTMSProxy() }),
  endpoints: (builder) => ({
    getAllSglProjects: builder.mutation({
      query: (status) => {
        const authHeaders = _getAuthHeaders();
        const loggedInUserDetails = _getLoggedInUserDetails();
        return {
          url: api.getAllSglProjects,
          method: "GET",
          params: { email: loggedInUserDetails.email, status },
          headers: authHeaders,
        };
      },
    }),
    getProjectDetails: builder.mutation({
      query: (projectId) => {
        const authHeaders = _getAuthHeaders();
        const loggedInUserDetails = _getLoggedInUserDetails();
        return {
          url: api.getSglProjectDetails,
          method: "GET",
          params: { email: loggedInUserDetails.email, projectId },
          headers: authHeaders,
        };
      },
    }),
    addProject: builder.mutation({
      query: (payload) => {
        const authHeaders = _getAuthHeaders();
        const loggedInUserDetails = _getLoggedInUserDetails();
        return {
          url: api.manageSglProject,
          method: "POST",
          params: { email: loggedInUserDetails.email, action: "add" },
          body: payload,
          headers: authHeaders,
        };
      },
    }),
    updateProject: builder.mutation({
      query: (payload) => {
        const authHeaders = _getAuthHeaders();
        const loggedInUserDetails = _getLoggedInUserDetails();
        return {
          url: api.manageSglProject,
          method: "POST",
          params: { email: loggedInUserDetails.email, action: "update" },
          body: payload,
          headers: authHeaders,
        };
      },
    }),
    deleteProject: builder.mutation({
      query: (payload) => {
        const authHeaders = _getAuthHeaders();
        const loggedInUserDetails = _getLoggedInUserDetails();
        return {
          url: api.manageSglProject,
          method: "POST",
          params: { email: loggedInUserDetails.email, action: "delete" },
          body: payload,
          headers: authHeaders,
        };
      },
    }),
    getSglReportStatus: builder.mutation({
      query: (id) => {
        const authHeaders = _getAuthHeaders();
        const loggedInUserDetails = _getLoggedInUserDetails();
        return {
          url: api.getSglReportStatus,
          method: "GET",
          params: { email: loggedInUserDetails.email, projectId: id },
          headers: authHeaders,
        };
      },
    }),
  }),
});

export const {
  useGetAllSglProjectsMutation,
  useGetProjectDetailsMutation,
  useAddProjectMutation,
  useUpdateProjectMutation,
  useDeleteProjectMutation,
} = sglProjectsApi;
