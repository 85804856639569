// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dtms-breadcrumb.ap-breadcrumb {
  margin-right: var(--spacing-5);
}
.dtms-breadcrumb.ap-breadcrumb .ap-breadcrumb-item.dtms-breadcrumb-item .Appkit4-icon {
  cursor: pointer;
}
.dtms-breadcrumb.ap-breadcrumb .ap-breadcrumb-item.dtms-breadcrumb-item a.dtms-breadcrumb-link:hover {
  text-decoration: none;
}
.dtms-breadcrumb.ap-breadcrumb .ap-breadcrumb-item.dtms-breadcrumb-item .ap-breadcrumb-separator {
  cursor: default;
}
.dtms-breadcrumb.ap-breadcrumb .ap-breadcrumb-item.dtms-breadcrumb-item .ap-breadcrumb-separator .icon-right-chevron-outline.Appkit4-icon {
  cursor: default;
}`, "",{"version":3,"sources":["webpack://./src/components/new-breadcrumb/NewBreadcrumb.scss"],"names":[],"mappings":"AAAA;EACE,8BAAA;AACF;AAEI;EACE,eAAA;AAAN;AAEI;EACE,qBAAA;AAAN;AAEI;EACE,eAAA;AAAN;AACM;EACE,eAAA;AACR","sourcesContent":[".dtms-breadcrumb.ap-breadcrumb {\n  margin-right: var(--spacing-5);\n\n  .ap-breadcrumb-item.dtms-breadcrumb-item {\n    .Appkit4-icon {\n      cursor: pointer;\n    }\n    a.dtms-breadcrumb-link:hover {\n      text-decoration: none;\n    }\n    .ap-breadcrumb-separator {\n      cursor: default;\n      .icon-right-chevron-outline.Appkit4-icon {\n        cursor: default;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
