import { useEffect, useState } from "react";
import ModalDialog from "../../../components/shared/Modal/Modal";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import "./SglDashboard.scss";
import _ from "lodash";
import { useDateField } from "@mui/x-date-pickers/DateField/useDateField";
import { emailPattern } from "../../../utils/constants";
import { _getLoggedInUserDetails } from "../../../envUrl";
import { useSelector } from "react-redux";

const AddEditUserModal = ({
  open,
  users,
  closeModal,
  addNewUsers,
  userDetails,
  editUserRole,
}) => {
  const loggedInUserDetails = _getLoggedInUserDetails();
  const tprlaProjectRoles = useSelector(
    (state) => state.tprlaMaster.tprlaProjectRoles
  );

  const [modalOptions, setModalOptions] = useState({
    title: "Add user",
    option: {
      open: false,
      maxWidth: "sm",
      actionButtonName: "Save",
    },
  });
  const [selectedUser, setSelectedUser] = useState();
  const [selectedUsersList, setSelectedUsersList] = useState([]);
  const [selectedRole, setSelectedRole] = useState("");
  const [error, setError] = useState({ user: "", role: "" });
  const [isUnregistered, setIsUnregistered] = useState(false);

  useEffect(() => {
    if (userDetails !== null) {
      setSelectedUser({ email: userDetails.email, name: userDetails.name });
      setSelectedRole(userDetails.modules[0]?.role?.name);
    }
    setModalOptions({
      ...modalOptions,
      option: { ...modalOptions.option, open: open },
    });
  }, [open, userDetails]);

  const handleUserChange = (e, newValue) => {
    if (isUnregistered) {
      const value = e.target.value;
      setSelectedUser(value);
      if (value.trim() === "" || !emailPattern.test(value)) {
        setError({ ...error, user: "Please enter a valid email id" });
      } else {
        setError({ ...error, user: "" });
      }
    } else {
      setSelectedUser(newValue);
      addUserToList(newValue);
    }
  };

  const addUserToList = (newUser) => {
    var clonedSelectUserList = _.cloneDeep(selectedUsersList);
    clonedSelectUserList = clonedSelectUserList.map((user) => user.email);

    if (!newUser.email) {
      setError({ ...error, user: "Please enter a valid email id" });
    } else if (!clonedSelectUserList.includes(newUser.email)) {
      setError({ ...error, user: "" });
      setSelectedUsersList([
        ...selectedUsersList,
        {
          ...newUser,
          orgId: newUser.hasOwnProperty("orgId")
            ? newUser.orgId
            : loggedInUserDetails.orgId,
          modules: [{ name: "TPRLA", role: { name: "", isActive: "Y" } }],
          isCreator: false,
        },
      ]);
    } else {
      setError({ ...error, user: "User already selected" });
    }
  };

  useEffect(() => {
    if (userDetails === null) {
      setSelectedUser(isUnregistered ? "" : null);
    }
  }, [selectedUsersList, isUnregistered]);

  const handleRoleChange = (e) => {
    const role = e.target.value;
    setError({ ...error, user: "" });
    setSelectedRole(role);
  };

  const deselectUser = (i) => {
    var clonedSelectUserList = _.cloneDeep(selectedUsersList);
    clonedSelectUserList.splice(i, 1);
    setSelectedUsersList(clonedSelectUserList);
  };

  const onSaveUsers = () => {
    var err = { user: "", role: "" };
    if (userDetails === null) {
      if (!_.isEmpty(selectedUsersList) && selectedUsersList.length > 0) {
        err.user = "";
      } else {
        err.user = "Please select at least one user";
      }
      if (!_.isEmpty(selectedRole) && selectedRole !== "") {
        err.role = "";
      } else {
        err.role = "Please select a role";
      }

      if (
        _.isEmpty(err.user) &&
        _.isEmpty(err.role) &&
        err.user === "" &&
        err.role === ""
      ) {
        var userArr = [];
        selectedUsersList.forEach((user) =>
          userArr.push({
            ...user,
            modules: [
              { name: "TPRLA", role: { name: selectedRole, isActive: "Y" } },
            ],
          })
        );
        addNewUsers(userArr);
        handleCloseModal();
      } else {
        setError({ ...error, ...err });
      }
    } else {
      const clonedUserDts = _.cloneDeep(userDetails);
      clonedUserDts.modules[0].role.name = selectedRole;
      editUserRole(clonedUserDts);
      handleCloseModal();
    }
  };
  const handleCloseModal = () => {
    closeModal();
    setSelectedUsersList([]);
    setSelectedRole("");
    setIsUnregistered(false);
    setError({ ...error, user: "", role: "" });
  };

  return (
    <ModalDialog
      {...modalOptions}
      onSave={onSaveUsers}
      onClose={handleCloseModal}
    >
      <Box
        sx={{
          "& .MuiTextField-root": { mt: 1 },
          "& .MuiFormLabel-root:not(:first-of-type)": { mt: 1 },
          "&. MuiInputBase-root": { mt: 1 },
        }}
        className="create-project"
      >
        {userDetails === null && (
          <div className="unregistered-formField">
            <Checkbox
              style={{ marginRight: "8px" }}
              checked={isUnregistered}
              onChange={() => setIsUnregistered(!isUnregistered)}
            />
            <FormLabel>Add unregistered user</FormLabel>
          </div>
        )}
        <FormLabel className="add-project-form-label">User</FormLabel>
        {isUnregistered || userDetails !== null ? (
          <div className="unregistered-user-add">
            <TextField
              required
              value={userDetails !== null ? selectedUser?.email : selectedUser}
              sx={userDetails !== null ? { width: "100%" } : { width: "83%" }}
              disabled={userDetails !== null}
              onChange={handleUserChange}
            />
            {userDetails === null && (
              <Button
                size="small"
                variant="contained"
                onClick={() => addUserToList({ email: selectedUser })}
                disabled={error.user !== ""}
              >
                Add
              </Button>
            )}
          </div>
        ) : (
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            className="user-autocomplete"
            options={users}
            sx={{ width: "80%" }}
            value={selectedUser}
            readOnly={userDetails !== null}
            getOptionLabel={(option) => option.email}
            renderOption={(props, option, { selected }) => {
              return (
                <li {...props} key={Math.random()}>
                  <Box className="user-menu">
                    <Typography variant="body1" className="user-menu__name">
                      {option.name}
                    </Typography>
                    <Typography variant="body2" className="user-menu__email">
                      {option.email}
                    </Typography>
                  </Box>
                </li>
              );
            }}
            renderInput={(params) => (
              <TextField {...params} placeholder="Search by name" />
            )}
            onChange={handleUserChange}
          />
        )}
        <FormHelperText>{error.user}</FormHelperText>
        <div className="selected-user-chip">
          {selectedUsersList?.map((user, i) => (
            <Chip
              key={i}
              label={user?.email}
              size="small"
              className="user-chip"
              onClick={() => {}}
              onDelete={() => {
                deselectUser(i);
              }}
            />
          ))}
        </div>
        <FormLabel className="add-project-form-label">Role</FormLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedRole}
          sx={{ width: "80%" }}
          onChange={handleRoleChange}
        >
          {tprlaProjectRoles.map((role) => (
            <MenuItem value={role.name} key={role.id}>
              {role.name}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>{error.role}</FormHelperText>
      </Box>
    </ModalDialog>
  );
};

export default AddEditUserModal;
