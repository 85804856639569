import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  _getClientID,
  _getDTMSProxy,
  _getAuthHeaders,
  _getLoggedInUserDetails,
  _getEncoderKey,
} from "../../../envUrl";
import api from "../../api";

export const sglReportApi = createApi({
  reducerPath: "sglReportApi",
  baseQuery: fetchBaseQuery({
    baseUrl: _getDTMSProxy(),
  }),
  endpoints: (builder) => ({
    getSglReportStatus: builder.mutation({
      query: (id) => {
        const authHeaders = _getAuthHeaders();
        const loggedInUserDetails = _getLoggedInUserDetails();
        return {
          url: api.getSglReportStatus,
          method: "GET",
          params: { email: loggedInUserDetails.email, projectId: id },
          headers: authHeaders,
        };
      },
    }),
    generateSglReport: builder.mutation({
      query: (id) => {
        const authHeaders = _getAuthHeaders();
        const user = _getLoggedInUserDetails();
        return {
          url: api.generateSglReport,
          method: "POST",
          params: { projectId: id, requesterEmail: user.email },
          headers: { ...authHeaders, requesterEmail: user.email },
        };
      },
    }),
  }),
});

export const { useGetSglReportStatusMutation, useGenerateSglReportMutation } =
  sglReportApi;
