// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sgl-app {
  height: 100%;
  padding: calc(var(--spacing-5) * 2);
  padding-top: var(--spacing-5);
}`, "",{"version":3,"sources":["webpack://./src/modules/sgl/SglApp.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,mCAAA;EACA,6BAAA;AACF","sourcesContent":[".sgl-app {\n  height: 100%;\n  padding: calc(var(--spacing-5) * 2);\n  padding-top: var(--spacing-5);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
