// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sgl-loader .ap-loading-circular .inside-circle {
  background-color: #BEBEBE;
}`, "",{"version":3,"sources":["webpack://./src/components/shared/Loader/Loader.scss"],"names":[],"mappings":"AAEQ;EACI,yBAAA;AADZ","sourcesContent":[".sgl-loader {\n    .ap-loading-circular {\n        .inside-circle {\n            background-color: #BEBEBE;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
