import { Button, Panel, Select } from "@appkit4/react-components";
import "./AssessmentInfo.scss";
import { userInfo } from "../../../mockdata/mockdata";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { _getDMATokenDetails } from "../../../../../envUrl";

const assessmentArray = [
  {
    label: "Only Qualitative",
    value: "qualitative",
  },
  {
    label: "Qualitative & Quantitative",
    value: "qualitativeAndQuantitative",
  },
];

export const AssessmentInfo = ({
  onProceed,
  isNewAssessment,
  onEditQuantitativeClick,
  onSetDefaultClick,
}) => {
  const templateDetails = useSelector(
    (state) => state.dmaTemplate.templateDetails
  );

  const { jobtitle: portfolioCompany } = useMemo(
    () => _getDMATokenDetails(),
    []
  );

  const [assessmentType, setAssessmentType] = useState("");
  const [assessmentTypeArray, setAssessmentTypeArray] =
    useState(assessmentArray);

  useEffect(() => {
    if (templateDetails.hasOwnProperty("isQuantitative")) {
      const assessmentTypes = _.cloneDeep(assessmentArray);
      if (!templateDetails.isQuantitative) {
        setAssessmentTypeArray(
          assessmentTypes.filter(
            (i) => i.value !== "qualitativeAndQuantitative"
          )
        );
      }
    }
  }, [templateDetails]);

  return (
    <div className="dma-assessment-info-container">
      <div className="dma-assessment-info-title ap-panel-head">
        <div className="ap-panel-head-title">
          Digital products & services - Detailed view
        </div>
      </div>
      <Panel
        className="dma-assessment-info-body"
        footer={
          <div className="dma-assessment-info-footer">
            {assessmentType === "qualitativeAndQuantitative" && (
              <>
                <Button kind="secondary" onClick={onSetDefaultClick}>
                  Set Default Score
                </Button>
                <Button kind="secondary" onClick={onEditQuantitativeClick}>
                  Edit Quantitative
                </Button>
              </>
            )}
            <Button
              kind="primary"
              onClick={onProceed}
              disabled={!assessmentType}
            >
              {isNewAssessment ? "Start" : "Continue"} Assessment
            </Button>
          </div>
        }
      >
        <div className="dma-assessment-info">
          <div className="dma-assessment-info-field">Portfolio Company</div>
          <div className="dma-assessment-info-value">{portfolioCompany}</div>
        </div>
        <div className="dma-assessment-info">
          <div className="dma-assessment-info-field">Industry Sector</div>
          <div className="dma-assessment-info-value">
            {templateDetails.industrySector}
          </div>
        </div>
        <div className="dma-assessment-info">
          <div className="dma-assessment-info-field">Assessment Type</div>
          <div className="dma-assessment-info-value">
            <Select
              className="dma-assessment-type-dropdown"
              data={assessmentTypeArray}
              hideTitleOnInput
              placeholder="Select"
              onSelect={setAssessmentType}
              value={assessmentType}
            />
          </div>
        </div>
      </Panel>
    </div>
  );
};
