import {
  Accordion,
  AccordionItem,
  Button,
  Column,
  Input,
  Modal,
  Select,
  Tab,
  Table,
  Tabs,
} from "@appkit4/react-components";
import "./EditQuantitative.scss";
import { useCallback, useMemo, useState } from "react";
import _ from "lodash";
import { isNumber } from "../../../../../utils/utilFns";
import { useSelector } from "react-redux";
const roundOfToDigits = (decimalValue, noOfDigitsAfterDot = 2) =>
  Math.round(decimalValue * 10 * noOfDigitsAfterDot) /
  (10 * noOfDigitsAfterDot);

const tabList = [
  {
    label: "P&L",
    value: "pl",
  },
  {
    label: "Inputs & Assumptions",
    value: "ia",
  },
];

const getDefaultPLStructure = () => ({
  currentYear: new Date().getFullYear(),
  targetYear: new Date().getFullYear() + 4,
  growth: 15,
  children01: [
    {
      name: "Revenues from Non Digital products",
      description: "",
      value1: 0,
      value2: 0,
    },
    {
      name: "Revenues from Digital Products",
      description: "",
      value1: 0,
      value2: 0,
    },
  ],
  children02: [],
  children03: [
    {
      name: "Total Revenues",
      description: "",
      value1: 0,
      value2: 0,
    },
  ],
  children04: [],
  children05: [
    {
      name: "Contribution Margin",
      description: "",
      value1: 0,
      value2: 0,
    },
  ],
  children06: [],
  children07: [
    {
      name: "Gross Margin",
      description: "",
      value1: 0,
      value2: 0,
    },
  ],
  children08: [],
  children09: [],
  children10: [
    {
      name: "EBITDA",
      description: "",
      value1: 0,
      value2: 0,
    },
  ],
});

const pLCategoryList = [
  {
    label: "Revenue",
    includedKeys: ["children01", "children02"],
    nonEditable: ["children01"],
  },
  {
    type: "total",
    includedKey: "children03",
    dependentOn: ["children01", "children02"],
    formula: ({ children01, children02 }, valueKey = "value1") => {
      return roundOfToDigits(
        children01?.reduce((a, b) => a + b[valueKey], 0) +
          children02?.reduce((a, b) => a + b[valueKey], 0)
      );
    },
  },
  {
    label: "Variable Cost",
    includedKeys: ["children04"],
  },
  {
    type: "total",
    includedKey: "children05",
    dependentOn: ["children01", "children02", "children04"],
    formula: ({ children01, children02, children04 }, valueKey = "value1") =>
      roundOfToDigits(
        children01?.reduce((a, b) => a + b[valueKey], 0) +
          children02?.reduce((a, b) => a + b[valueKey], 0) -
          children04?.reduce((a, b) => a + b[valueKey], 0)
      ),
  },
  {
    label: "Fixed Cost",
    includedKeys: ["children06"],
  },
  {
    type: "total",
    includedKey: "children07",
    dependentOn: ["children01", "children02", "children04", "children06"],
    formula: (
      { children01, children02, children04, children06 },
      valueKey = "value1"
    ) =>
      roundOfToDigits(
        children01?.reduce((a, b) => a + b[valueKey], 0) +
          children02?.reduce((a, b) => a + b[valueKey], 0) -
          children04?.reduce((a, b) => a + b[valueKey], 0) -
          children06?.reduce((a, b) => a + b[valueKey], 0)
      ),
  },
  {
    label: "SG&A & OPEX",
    includedKeys: ["children08"],
  },
  {
    label: "Other Expenses",
    includedKeys: ["children09"],
  },
  {
    type: "total",
    dependentOn: [
      "children01",
      "children02",
      "children04",
      "children06",
      "children08",
      "children09",
    ],
    includedKey: "children10",
    formula: (
      {
        children01,
        children02,
        children04,
        children06,
        children08,
        children09,
      },
      valueKey = "value1"
    ) =>
      roundOfToDigits(
        children01?.reduce((a, b) => a + b[valueKey], 0) +
          children02?.reduce((a, b) => a + b[valueKey], 0) -
          children04?.reduce((a, b) => a + b[valueKey], 0) -
          children06?.reduce((a, b) => a + b[valueKey], 0) -
          children08?.reduce((a, b) => a + b[valueKey], 0) -
          children09?.reduce((a, b) => a + b[valueKey], 0)
      ),
  },
];

const calculateCompoundValueAfterNYears = ({ growth, noOfYears, startValue }) =>
  roundOfToDigits(startValue * Math.pow(1 + growth / 100, noOfYears), 2);

export const EditQuantitative = ({
  onClose,
  onSavePL,
  onSaveIA,
  isNewAssessment,
}) => {
  const assessmentDetails = useSelector(
    (state) => state.dmaAssessment.assessmentDetails
  );
  const templateDetails = useSelector(
    (state) => state.dmaTemplate.templateDetails
  );
  const [pLData, setPLData] = useState(
    assessmentDetails.plStructure || templateDetails.plStructure
      ? JSON.parse(assessmentDetails.plStructure || templateDetails.plStructure)
      : getDefaultPLStructure()
  );
  const [activeKeys, setActiveKeys] = useState([]);
  const [assumptionsData, setAssumptionsData] = useState(
    assessmentDetails.assumptionStructure || templateDetails.assumptionStructure
      ? JSON.parse(
          assessmentDetails.assumptionStructure ||
            templateDetails.assumptionStructure
        )
      : []
  );

  const onClickAccordion = (activeKeys) => {
    setActiveKeys(activeKeys);
  };

  const onPLContinueClick = useCallback(() => {
    const clonedPLData = _.cloneDeep(pLData);
    Object.keys(clonedPLData).forEach((key) => {
      if (Array.isArray(clonedPLData[key]) && !_.isEmpty(clonedPLData[key])) {
        const nonEmptyChildren = clonedPLData[key].filter(
          (d) => !_.isEmpty(d?.name) && d.name !== ""
        );
        clonedPLData[key] = nonEmptyChildren;
      }
    });
    const clonedAssessment = _.cloneDeep(assessmentDetails);
    clonedAssessment.plStructure = JSON.stringify(clonedPLData);
    onSavePL(clonedAssessment);
    setCurrentTab(1);
  }, [onSavePL, pLData, assessmentDetails]);

  const onIASaveClick = useCallback(() => {
    var clonedAssumptionsData = _.cloneDeep(assumptionsData);

    const nonEmptyIaData = clonedAssumptionsData.filter(
      (d) =>
        !_.isEmpty(d.input) &&
        d.input !== "" &&
        !_.isEmpty(d.assumption) &&
        d.assumption !== "" &&
        !_.isEmpty(d.kpi) &&
        d.kpi !== ""
    );
    clonedAssumptionsData = nonEmptyIaData;
    const clonedAssessment = _.cloneDeep(assessmentDetails);
    clonedAssessment.assumptionStructure = JSON.stringify(
      clonedAssumptionsData
    );
    onSaveIA(clonedAssessment);
  }, [onSaveIA, assumptionsData, assessmentDetails]);

  const handlePLChange = ({ value, path, type, childKey, index }) => {
    switch (type) {
      case "plCurrYear":
        if (!isNumber(value)) {
          console.log("not a valid number", value);
          break;
        }
        const currYear = parseInt(value || "0");
        setPLData((plValue) => {
          Object.keys(plValue).forEach((key) => {
            if (_.isArray(plValue[key])) {
              plValue[key]?.forEach((plEntry) => {
                if (plEntry.value1) {
                  plEntry.value2 = calculateCompoundValueAfterNYears({
                    startValue: plEntry.value1,
                    growth: plValue.growth,
                    noOfYears: plValue.targetYear - currYear,
                  });
                }
              });
            }
          });
          return {
            ...plValue,
            currentYear: currYear,
          };
        });
        break;
      case "plTargYear":
        if (!isNumber(value)) {
          console.log("not a valid number", value);
          break;
        }
        const targYear = parseInt(value || "0");
        setPLData((plValue) => {
          Object.entries(plValue, ([key, val]) => {
            if (_.isArray(val)) {
              val.forEach((plEntry) => {
                if (plEntry.value1) {
                  plEntry.value2 = calculateCompoundValueAfterNYears({
                    startValue: plEntry.value1,
                    growth: plValue.growth,
                    noOfYears: targYear - plValue.currentYear,
                  });
                }
              });
            }
          });
          return {
            ...plValue,
            targetYear: targYear,
          };
        });
        break;
      case "plCurrentVal":
        if (!isNumber(value)) {
          console.log("not a valid number", value);
          break;
        }
        const currVal = parseInt(value || "0");
        const futVal = calculateCompoundValueAfterNYears({
          startValue: currVal,
          growth: pLData.growth,
          noOfYears: pLData.targetYear - pLData.currentYear,
        });
        const updateTotalList = pLCategoryList.filter((val) =>
          val.dependentOn?.includes(childKey)
        );
        setPLData((plValue) => {
          _.set(plValue, `${path}.value1`, currVal);
          _.set(plValue, `${path}.value2`, futVal);
          updateTotalList.forEach((totalItem) => {
            const value1 = totalItem.formula(plValue);
            const value2 = totalItem.formula(plValue, "value2");
            _.set(plValue, `${totalItem.includedKey}[0].value1`, value1);
            _.set(plValue, `${totalItem.includedKey}[0].value2`, value2);
          });
          return {
            ...plValue,
          };
        });
        break;
      case "plName":
        setPLData((plValue) => {
          _.set(plValue, `${path}.name`, value);
          return {
            ...plValue,
          };
        });
        break;
      case "plDescription":
        setPLData((plValue) => {
          _.set(plValue, `${path}.description`, value);
          return {
            ...plValue,
          };
        });
        break;
      case "plAdd":
        setPLData((plValue) => {
          const obj = _.at(plValue, path)[0];
          obj?.push({
            name: "",
            description: "",
            value1: 0,
            value2: 0,
          });
          return {
            ...plValue,
          };
        });
        break;
      case "plDelete":
        setPLData((plValue) => {
          _.remove(_.get(plValue, path), (val, ind) => ind === index);
          return {
            ...plValue,
          };
        });
        break;
      default:
        console.log("no update can be done for this type", type);
    }
  };

  const assumptionsInput = useMemo(() => {
    return Object.keys(pLData).reduce((prev, key) => {
      if (_.isArray(pLData[key])) {
        return prev.concat(...pLData[key].filter((val) => val.name));
      }
      return prev;
    }, []);
  }, [pLData]);

  const assumptionTableData = useMemo(() => {
    return assumptionsData.map((val, ind) => ({
      ...val,
      del: "del",
      ind,
    }));
  }, [assumptionsData]);

  const [currentTab, setCurrentTab] = useState(0);

  const handleIAChange = ({ itemId, value, field }) => {
    switch (field) {
      case "kpi":
        setAssumptionsData((assumptions) => {
          assumptions[itemId][field] = value;
          return [...assumptions];
        });
        break;
      case "assumption":
        if (!isNumber(value)) {
          console.log("is not a valid number", value);
          break;
        }
        setAssumptionsData((assumptions) => {
          assumptions[itemId][field] = value;
          const val = assumptionsInput.find(
            (val) => val.name === assumptions[itemId].input
          )?.value1;
          assumptions[itemId].value = (val * value) / 100;
          return [...assumptions];
        });
        break;
      case "input":
        setAssumptionsData((assumptions) => {
          assumptions[itemId][field] = value;
          const val = assumptionsInput.find(
            (val) => val.name === value
          )?.value1;
          assumptions[itemId].value =
            (val * assumptions[itemId].assumption) / 100;
          return [...assumptions];
        });
        break;
      case "delete":
        setAssumptionsData((assumptions) =>
          assumptions.filter((val, ind) => ind !== itemId)
        );
        break;
      case "add":
        setAssumptionsData((assumptions) => [
          ...assumptions,
          {
            kpi: "",
            assumption: 0,
            input: "",
            value: 0,
          },
        ]);
        break;
      default:
        console.log("no handler for this field", field, value, itemId);
    }
  };

  const renderCell = (row, field) => {
    switch (field) {
      case "del":
        return (
          <Button
            kind="text"
            className="ia-edit-id-delete-container"
            onClick={() =>
              handleIAChange({
                itemId: row.ind,
                field: "delete",
              })
            }
          >
            <span className="Appkit4-icon icon-delete-fill ia-delete-button"></span>
          </Button>
        );
      case "ind":
        return <>{row.ind + 1}</>;
      case "kpi":
        return (
          <Input
            hideTitleOnInput
            title="KPI"
            value={row.kpi}
            onChange={(value) => {
              handleIAChange({
                itemId: row.ind,
                field,
                value,
              });
            }}
          />
        );
      case "assumption":
        return (
          <div className="ia-assumptions-td-container">
            <Input
              hideTitleOnInput
              title="Assumptions"
              value={row.assumption}
              className="ia-assumptions-input"
              onChange={(value) => {
                handleIAChange({
                  itemId: row.ind,
                  field,
                  value,
                });
              }}
            />
            % of
          </div>
        );
      case "input":
        return (
          <Select
            data={assumptionsInput}
            valueKey="name"
            labelKey="name"
            value={row.input}
            defaultValue={row.input}
            hideTitleOnInput
            onSelect={(value) => {
              handleIAChange({
                itemId: row.ind,
                field,
                value,
              });
            }}
            suffixTemplate={(item) => `${item.value1} (M€)`}
            className="ia-edit-input-dropdown"
            // dropdownMatchWidth={false}
            // itemTemplate={(label) =>
            //     //  <Tooltip
            //     // content={label}
            //     // mouseEnterDelay={750}
            //     // appendAfterTarget
            //     // position="top-left"
            //     // distance={10}>
            //     //     <div className="ia-edit-dropdown-item">
            //             // {label}
            //     //     </div>
            //     // </Tooltip>
            // }
          />
        );
      case "value":
        return <>{row.value}</>;
      default:
        console.log("not render for this field", field);
    }
  };
  return (
    <Modal
      className="dma-edit-quantitative-modal"
      visible
      onCancel={onClose}
      title="Edit Quantitative"
      footer={
        <>
          <Button kind="secondary" onClick={onClose}>
            Cancel
          </Button>
          {currentTab === 0 ? (
            <Button kind="primary" onClick={onPLContinueClick}>
              Save & Continue
            </Button>
          ) : (
            <Button kind="primary" onClick={onIASaveClick}>
              Save & {isNewAssessment ? "Start" : "Continue"} Assessment
            </Button>
          )}
        </>
      }
    >
      <div className="dma-edit-quantitative-body">
        <Tabs
          type="underline"
          activeIndex={currentTab}
          onTabChange={setCurrentTab}
        >
          {tabList.map((value) => (
            <Tab key={value.value} label={value.label} value={value.value} />
          ))}
        </Tabs>
        <div className="edit-quantitative-section-body">
          {currentTab === 0 && (
            <div className="edit-pl-section-body">
              <div className="edit-assessment-year-heading">
                <Input
                  className="year-input"
                  value={pLData.currentYear}
                  onChange={(val) =>
                    handlePLChange({
                      value: val,
                      type: "plCurrYear",
                    })
                  }
                  title="Current FY(M€)"
                />
                <Input
                  className="year-input"
                  value={pLData.targetYear}
                  onChange={(val) =>
                    handlePLChange({
                      value: val,
                      type: "plTargYear",
                    })
                  }
                  title="Future FY(M€)"
                />
              </div>
              <Accordion
                multiple={true}
                onClick={onClickAccordion}
                activeKeys={activeKeys}
                className="dma-edit-quantitative-accordion"
              >
                {pLCategoryList.map((category, ind) => {
                  const showHeading = category.includedKeys?.some(
                    (val) => pLData[val]?.length
                  );
                  return (
                    <AccordionItem
                      className={`dma-edit-quantitative-accordion-item ${
                        category.type === "total"
                          ? "quantitative-total-item"
                          : "quantitative-accord-item"
                      }`}
                      key={ind}
                      itemKey={`${ind}`}
                      title={
                        category.type === "total" && (
                          <>
                            {" "}
                            <div className="quantitative-accordion-total-heading">
                              {pLData?.[category.includedKey]?.[0]?.name}
                            </div>
                            <div className="pl-value-item">
                              <div className="current-year-total-pl-value">
                                {pLData[category.includedKey]?.[0]?.value1}
                              </div>
                              <div className="future-year-total-pl-value">
                                {pLData[category.includedKey]?.[0]?.value2}
                              </div>
                            </div>
                          </>
                        )
                      }
                      showExpandIcon={false}
                      excludeElementToToggle=".edit-quantitative-pl-add-accordion"
                      toggleFromHeaderIconOnly={category.type === "total"}
                      templateHeader={() => (
                        <>
                          <div className="quantitative-accordion-heading-container">
                            <span
                              className={`Appkit4-icon icon-right-chevron-outline ${
                                activeKeys.includes(`${ind}`) ? "rotate" : ""
                              }`}
                            ></span>{" "}
                            {category.label}
                          </div>

                          <Button
                            className="edit-quantitative-pl-add-accordion"
                            kind="text"
                            compact
                            onClick={() => {
                              handlePLChange({
                                path: category.includedKeys?.at(-1),
                                type: "plAdd",
                              });
                              !activeKeys?.includes(`${ind}`) &&
                                setActiveKeys((keys) => {
                                  keys.push(`${ind}`);
                                  return keys;
                                });
                            }}
                          >
                            Add
                          </Button>
                        </>
                      )}
                    >
                      {category.type !== "total" && (
                        <div className="pl-item-container">
                          {showHeading && (
                            <div className="pl-item-row-container pl-item-heading">
                              <div className="pl-item-name">Items</div>
                              <div className="pl-item-name">Description</div>
                            </div>
                          )}
                          {category.includedKeys.map((value, parInd) => {
                            return pLData?.[value]?.map((child, ind) => (
                              <div
                                className="pl-item-row-container"
                                key={`${value}.${ind}`}
                              >
                                {!category.nonEditable?.includes(value) && (
                                  <div
                                    className="delete-icon-container"
                                    onClick={() =>
                                      handlePLChange({
                                        path: `${value}`,
                                        index: ind,
                                        type: "plDelete",
                                      })
                                    }
                                  >
                                    <span className="Appkit4-icon icon-delete-fill"></span>
                                  </div>
                                )}
                                <div className="pl-item-text-container">
                                  <Input
                                    className="pl-item-name"
                                    value={child.name}
                                    hideTitleOnInput
                                    onChange={(keyVal) =>
                                      !category.nonEditable?.includes(value) &&
                                      handlePLChange({
                                        value: keyVal,
                                        path: `${value}[${ind}]`,
                                        type: "plName",
                                      })
                                    }
                                  />
                                  <Input
                                    className="pl-item-description"
                                    value={child.description}
                                    hideTitleOnInput
                                    onChange={(keyVal) =>
                                      handlePLChange({
                                        value: keyVal,
                                        path: `${value}[${ind}]`,
                                        type: "plDescription",
                                      })
                                    }
                                  />
                                </div>
                                <div className="pl-year-value-container">
                                  <Input
                                    className="current-year-pl-value"
                                    disabled={
                                      _.isEmpty(child.name) || child.name === ""
                                    }
                                    value={child.value1}
                                    hideTitleOnInput
                                    onChange={(keyVal) =>
                                      handlePLChange({
                                        value: keyVal,
                                        path: `${value}[${ind}]`,
                                        type: "plCurrentVal",
                                        childKey: value,
                                      })
                                    }
                                  />
                                  <div className="future-year-pl-value">
                                    {child.value2}{" "}
                                  </div>
                                </div>
                              </div>
                            ));
                          })}
                        </div>
                      )}
                    </AccordionItem>
                  );
                })}
              </Accordion>
            </div>
          )}
          {currentTab === 1 && (
            <div className="edit-ia-section-body">
              <Table
                originalData={assumptionTableData}
                hasTitle
                className="edit-ia-table"
              >
                <Column field="del" renderCell={renderCell}>
                  <Button
                    kind="text"
                    className="ia-edit-id-delete-container"
                    onClick={() =>
                      handleIAChange({
                        field: "add",
                      })
                    }
                  >
                    <span className="Appkit4-icon icon-circle-plus-fill ia-delete-button"></span>
                  </Button>
                </Column>
                <Column field="ind" renderCell={renderCell}>
                  Id
                </Column>
                <Column field="kpi" renderCell={renderCell}>
                  KPI
                </Column>
                <Column field="assumption" renderCell={renderCell}>
                  Assumption
                </Column>
                <Column field="input" renderCell={renderCell}>
                  Input
                </Column>
                <Column field="value" renderCell={renderCell}>
                  Value (M€)
                </Column>
              </Table>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};
