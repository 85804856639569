import { useState, useEffect } from "react";
import { debounce } from "lodash";

const useDebouncedResize = () => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = debounce(() => {
        setWidth(window.innerWidth);
    }, 100);
    window.addEventListener("resize", handleResize);
    return () => {
      handleResize.cancel();
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return width;
};

export default useDebouncedResize;