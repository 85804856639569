// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dma-report-container {
  display: block;
}
.dma-report-container iframe.dma-report-iframe {
  display: block;
  margin-top: var(--spacing-5);
  width: 100%;
  height: calc(100vh - 14rem);
}
.dma-report-container iframe.dma-report-iframe.dma-report-iframe-completed {
  height: calc(100vh - 14.3rem);
}`, "",{"version":3,"sources":["webpack://./src/modules/dma/report/Report.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;AACJ;AAAI;EAII,cAAA;EACA,4BAAA;EACA,WAAA;EACA,2BAAA;AADR;AALQ;EACA,6BAAA;AAOR","sourcesContent":[".dma-report-container {\n    display: block;\n    iframe.dma-report-iframe {\n        &.dma-report-iframe-completed {\n        height: calc(100vh - 14.3rem);\n        }\n        display: block;\n        margin-top: var(--spacing-5);\n        width: 100%;\n        height: calc(100vh - 14rem);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
