import React from "react";

import "./Footer.scss";

const Footer = () => {
  return (
    <footer className="tpra-footer text-center">
      <div className="tpra-footer-text">© 2024 PwC. All rights reserved. </div>
    </footer>
  );
};

export default Footer;
